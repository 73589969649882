import dayjs from "dayjs";
import { groupBy } from "lodash";
import { type AccessCode } from "../../../services/gen/managerApi";

const testTypeOrder = [
  "clt3",
  "clt4",
  "clt5",
  "clt6",
  "clt7",
  "clt8",
  "clt10",
  "clt",
] as const;

export const groupCodesByDateRange = (accessCodes: AccessCode[]): AccessCode[][] => {
  const
    grouped = groupBy(
      accessCodes,
      c => `${dayjs(c.activeStart).format("YYYY-MM-DD")}:${dayjs(c.activeEnd).format("YYYY-MM-DD")}`,
    );

    // NB. Sort result array by start date, and sort each subarray by test type according to order above.
    return Object.values(grouped)
      .sort((a, b) => dayjs(a[0].activeStart).startOf("day").unix() - dayjs(b[0].activeStart).startOf("day").unix())
      .map(rangeCodes => [...rangeCodes].sort((a, b) => testTypeOrder.indexOf(a.testType) - testTypeOrder.indexOf(b.testType)));
};

export const getDateRangeString = (accessCode: AccessCode): string => {
  const
    start = dayjs(accessCode.activeStart),
    end = dayjs(accessCode.activeEnd);

  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      if (start.date() === end.date()) {
        return start.format("MMMM D, YYYY");
      }
      return `${start.format("MMMM D")} - ${end.format("D, YYYY")}`;
    }
    return `${start.format("MMMM D")} - ${end.format("MMMM D, YYYY")}`;
  }
  return `${start.format("MMMM D, YYYY")} - ${end.format("MMMM D, YYYY")}`;
};
