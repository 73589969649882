import React from "react";

interface CheckboxProps {
  checked: boolean;
  disabled: boolean;
  onChange: (e: unknown) => void;
  id?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled, onChange, id }) => (
    <input
      id={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className="h-4 w-4 ml-8 rounded focus:ring-0 text-clt-collegiate-blue hover:cursor-pointer"
    />
  );

export default Checkbox;
