import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, RowSelectionState, SortingState, useReactTable } from "@tanstack/react-table";
import { groupBy, isEmpty, mapValues, uniq } from "lodash";
import { ChangeEvent, useEffect, useMemo, useReducer, useState } from "react";
import Checkbox from "../../../../components/Checkbox";
import { TestTypePill } from "../../../../components/TestTypePill";
import type { AnalyticsScore } from "../../../../services/gen/managerApi";
import { AdministrationLabel, getAdministrationInfo, getAdministrationLabel } from "../CohortGrowth";

interface CohortTakersPanelProps {
  cohortScores: AnalyticsScore[];
  setScores: (scores: AnalyticsScore[]) => void;
  reportFiltered: (filtered: boolean) => void;
}

interface CohortTakerRow {
  takerId: number;
  firstName: string;
  middleInitial: string;
  lastName: string;
  [K: AdministrationLabel]: boolean | undefined;
}

const
  getTakerRows = (scoreGroups: Record<string, AnalyticsScore[]>) => Object.entries(scoreGroups).map(([, scores]) => {
    const
      { takerId, firstName, middleInitial, lastName } = scores[0],
      adms = groupBy(scores, getAdministrationLabel);

    return {
      takerId,
      firstName,
      middleInitial,
      lastName,
      ...mapValues(adms, _ => true),
    };
  }),
  inferSelected = (a: CohortTakerRow[]) => a.reduce((acc, row, index) => {
    const { takerId: _t, firstName: _f, middleInitial: _m, lastName: _l, ...adms } = row;
    acc[index] = Object.values(adms).every(v => v); // Row is selected <=> every administration is selected.
    return acc;
  }, {} as RowSelectionState);

type AdministrationSelectionAction =
  | { type: "reset", newData: CohortTakerRow[] }
  | { type: "select", takerId: number | "all" }
  | { type: "deselect", takerId: number | "all" }
  | { type: "set", takerId: number, adm: AdministrationLabel, value: boolean };

function administrationSelectionReducer(
  state: CohortTakerRow[],
  action: AdministrationSelectionAction,
): CohortTakerRow[] {
  switch (action.type) {
    case "reset":
      return action.newData;

    case "set":
      return state.map(row => {
        if (row.takerId !== action.takerId) return row;
        return {
          ...row,
          [action.adm]: action.value,
        };
      });
  }

  // Either "select" or "deselect" at this point.
  const targetValue = action.type === "select";
  return state.map(row => {
    if (action.takerId !== "all" && row.takerId !== action.takerId) return row;
    const { takerId, firstName, middleInitial, lastName, ...adms } = row;
    return {
      takerId, firstName, middleInitial, lastName,
      ...mapValues(adms, _ => targetValue),
    };
  });
}

export default function CohortTakersPanel({ cohortScores, setScores, reportFiltered }: CohortTakersPanelProps) {
  const
    administrations = uniq(cohortScores.map(getAdministrationLabel))
      .sort((a, b) => getAdministrationInfo(a).timeKey - getAdministrationInfo(b).timeKey),

    takerScoreGroups = useMemo(() => groupBy(cohortScores, s => s.takerId), [cohortScores]),
    [data, dispatch] = useReducer(administrationSelectionReducer, getTakerRows(takerScoreGroups)),

    columns = useMemo<ColumnDef<CohortTakerRow>[]>(() => [
      {
        id: "select-col",
        header: ({ table }) => (
          <Checkbox
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            disabled={!table.getToggleAllRowsSelectedHandler()}
            onChange={(e) => {
              table.getToggleAllRowsSelectedHandler()(e);
              const { checked } = (e as ChangeEvent<HTMLInputElement>).target;
              dispatch({ type: checked ? "select" : "deselect", takerId: "all" });
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            id={`select-${row.original.takerId}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={(e) => {
              row.getToggleSelectedHandler()(e);
              const
                { checked } = (e as ChangeEvent<HTMLInputElement>).target,
                { takerId } = row.original;
              dispatch({ type: checked ? "select" : "deselect", takerId });
            }}
          />
        ),
      },
      {
        header: "First Name",
        accessorKey: "firstName",
        enableSorting: true,
      },
      {
        header: "Initial",
        accessorKey: "middleInitial",
        enableSorting: false,
      },
      {
        header: "Last Name",
        accessorKey: "lastName",
        enableSorting: true,
      },
      ...administrations.map<ColumnDef<CohortTakerRow>>(adm => ({
        id: adm,
        header() {
          const { semester, year, testType } = getAdministrationInfo(adm);
          return (
            <div className="flex gap-1.5 items-center">
              <span>{semester.charAt(0)}{year.slice(2)}</span>
              <TestTypePill testType={testType}/>
            </div>
          );
        },
        cell({ row }) {
          const { takerId, [adm]: checked } = row.original;
          return checked === undefined ? null : (
            <Checkbox
              id={`select-${takerId}-${adm.replaceAll(" ", "_")}`}
              checked={!!checked}
              disabled={false}
              onChange={() => dispatch({ type: "set", takerId, adm, value: !checked })}
            />
          );
        },
        accessorFn: v => v,
        // Custom sorting function to sort takers who took this test to the top/bottom.
        sortingFn(rowA, rowB, columnId) {
          const 
            a = rowA.getValue(columnId) as CohortTakerRow,
            b = rowB.getValue(columnId) as CohortTakerRow;

          if (a?.[adm] === undefined && b?.[adm] !== undefined) return -1;
          if (a?.[adm] !== undefined && b?.[adm] === undefined) return 1;
          return 0;
        },
      })),
    ], [administrations]),

    [isOpen, setIsOpen] = useState(false),
    [sorting, setSorting] = useState<SortingState>([]),
    [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    }),
    [rowSelection, setRowSelection] = useState<RowSelectionState>(inferSelected(data)),
    handleToggle = () => setIsOpen(!isOpen),
    studentCount = useMemo(() => data.filter(r => {
      const { takerId: _t, firstName: _f, middleInitial: _m, lastName: _l, ...adms } = r;
      return Object.values(adms).some(v => v);
    }).length, [data]),
    
    table = useReactTable({
      columns,
      data,
      state: {
        sorting,
        pagination,
        rowSelection,
      },
      enableRowSelection: true,
      onRowSelectionChange: sel => setRowSelection(sel),
      onSortingChange: sort => setSorting(sort),
      onPaginationChange: pg => setPagination(pg),
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      autoResetPageIndex: false, // Avoid resetting pagination when toggling taker inclusion.
      columnResizeMode: "onChange",
      defaultColumn: {
        size: 10,
        minSize: 10,
        maxSize: 50,
      }
    });

  useEffect(() => {
    if (isEmpty(takerScoreGroups)) return;
    const newData = getTakerRows(takerScoreGroups);
    dispatch({ type: "reset", newData });
    setPagination(p => ({ ...p, pageIndex: 0 })); // Manually reset pagination when groups change.
  }, [takerScoreGroups]);

  useEffect(() => {
    // Synchronize selection state and score data.
    const filteredCohortScores = cohortScores.filter(score => {
      const
        { takerId } = score,
        adm = getAdministrationLabel(score);
      return data.find(r => r.takerId === takerId)?.[adm];
    });

    setScores(filteredCohortScores);
    reportFiltered(filteredCohortScores.length < cohortScores.length);

    setRowSelection(inferSelected(data));
  }, [data]);

  return (
    <div className="relative whitespace-nowrap">
      <Transition
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={isOpen}
      >
        <div className="absolute z-10 bottom-[4.5rem] right-0 w-full">
          <div className="h-12 pl-8 border-t-2 border-b flex gap-1 justify-between items-center w-full bg-surface-primary rounded-t-3xl text-center">
            <p>Test Participation</p>
            <p className="flex-1 mr-32">Analytics above include averages from <b>{studentCount}</b> of <b>{data.length}</b> total students.</p>
          </div>
          <div className="overflow-x-scroll">
            <table className="w-full overflow-y-scroll text-bold">
              <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="h-12 text-text-secondary">
                  {headerGroup.headers.map((header, hgIndex) => (
                    <th key={header.id}
                        colSpan={header.colSpan}
                        className={`text-left font-sans bg-white ${hgIndex === 0 ? "" : ""} border-1 border-gray-200 no-wrap w-[${header.getSize()}px] ${header.column.getCanSort() ? "cursor-pointer" : "cursor-auto"}`}
                        onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}>
                      <div className="flex flex-nowrap items-center select-none">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort()
                        ? (
                          header.column.getIsSorted()
                            ? (
                              header.column.getIsSorted() === "desc"
                                ? <ChevronDownIcon className="h-4 w-4 inline-block"/>
                                : <ChevronUpIcon className="h-4 w-4 inline-block"/>
                              )
                            : <ChevronUpDownIcon className="h-4 w-4 inline-block"/>
                          )
                        : null
                      }
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody>
              {table.getRowModel().rows.map(((row, index) => (
                <tr key={row.id} className={`h-12 text-text-secondary font-medium ${index % 2 === 0 ? "bg-border-primary" : "bg-surface-primary"}`}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="border-1 border-gray-200">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )))}
              </tbody>
            </table>
          </div>
        </div>
      </Transition>

      <div className="relative p-4 z-10 flex justify-between items-center bg-white">
        <div className={isOpen ? "opacity-100" : "opacity-0"}>
          Page <b>{table.getState().pagination.pageIndex + 1}</b> of <b>{table.getPageCount()}</b>
        </div>
        <button
          className={`ml-8 p-2 ${isOpen ? "bg-surface-tertiary text-text-secondary" : "bg-surface-brand-2 text-text-inverted"} rounded`}
          onClick={handleToggle}
        >
          { isOpen
            ? <div className="min-w-[7rem]">Hide Students <ChevronDownIcon className="h-4 w-4 inline-block"/></div>
            : <div className="min-w-[7rem]">Show Students <ChevronUpIcon className="h-4 w-4 inline-block"/></div>
          }
        </button>
        <div className={isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className={`mr-4 rounded p-2 bg-surface-brand-2 text-text-inverted w-20 ${table.getCanPreviousPage() ? "cursor-pointer" : "disabled opacity-50 cursor-not-allowed"}`}
          >
            Previous
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className={`rounded p-2 bg-surface-brand-2 text-text-inverted w-20 ${table.getCanNextPage() ? "cursor-pointer" : "disabled opacity-50 cursor-not-allowed"}`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
