import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import Spinner from "../../components/Spinner";
import { useManagerApiGetAccessCodesQuery } from "../../services/gen/managerApi";
import RegistrationLineItem from "./Registrations/RegistrationLineItem";
import { getDateRangeString, groupCodesByDateRange } from "./Registrations/util";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function Registrations() {
  const
    { data: { accessCodes } = {}, isLoading } = useManagerApiGetAccessCodesQuery(),
    utcNow = dayjs.utc(),
    upcomingDateRanges = useMemo(() => accessCodes
      ? groupCodesByDateRange(accessCodes).filter(range => dayjs.utc(range[0].activeEnd).isAfter(utcNow))
      : [],
    [accessCodes],
  );

  return (
    <div className="w-full">
      <header className="top-0 sticky z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray">
        <h1 className="text-xl">Registrations</h1>
      </header>
      <main className="m-8 xl:m-16">
        <div className="flex space-x-1 inset-x-0">
          <div className="inset-0 flex-1 bg-clt-white rounded-extra-rounded shadow-lg">
            <div className="flex flex-row px-6 py-4 border-b border-clt-light-gray">
              <h2 className="text-lg">Upcoming Tests</h2>
            </div>
            <div className="p-6">
              {isLoading
                ? <Spinner />
                : !upcomingDateRanges.length
                  ? (
                    <div className="p-3 bg-clt-light-gray rounded-extra-rounded">
                      <div className="rounded-minimal border border-dashed border-border-secondary text-center py-16">
                        <h2 className="text-lg font-bold text-text-primary">No Registrations</h2>
                        <p className="text text-text-secondary">You have not scheduled any exams.</p>
                      </div>
                    </div>
                  )
                  : (
                    <div className="space-y-3">
                      {upcomingDateRanges.map((range, index) => {
                        const rangeIsActive = dayjs(range[0].activeStart).isBefore(utcNow);
                        return (
                          <Fragment key={getDateRangeString(range[0])}>
                            <div className="space-y-3">
                              <div className="flex gap-2 items-center">
                                <h2 className="text-lg font-bold text-text-primary">{getDateRangeString(range[0])}</h2>
                                {rangeIsActive && <span className="text-xs font-btn uppercase text-text-secondary bg-clt-light-gray py-0.5 px-1 rounded-minimal">now open</span>}
                              </div>
                              {range.map(codeInfo => <RegistrationLineItem key={codeInfo.accessCode} codeInfo={codeInfo} />)}
                            </div>
                            {index !== upcomingDateRanges.length - 1 && <hr className="h-[1px] border-none bg-clt-light-gray" />}
                          </Fragment>
                        );
                      })}
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
