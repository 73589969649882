import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../shared/utils";
import { TestType } from "../services/gen/authorize";

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps {
  value?: string | null;
  label?: string;
  placeHolder?: string;
  selectOptions: SelectOption[];
  onChange: (value: string) => void;
  handleSave?: (id: string, value: string) => void;
  testType?: TestType;
}

export default function Select({ label, placeHolder, value, selectOptions, onChange }: SelectProps) {
  const optionForCurrentValue = selectOptions.find(o => o.value === value),
    [selected, setSelected] = useState<SelectOption>(optionForCurrentValue ?? selectOptions[0]),
    handleChange = (val: SelectOption) => {
      setSelected(val);
      onChange(val.value);
    },
    baseOptionClasses = "relative cursor-default select-none py-2 pl-3 pr-9";

  return (
    <Listbox value={optionForCurrentValue} onChange={handleChange}>
      {({ open }) => (
        <>
          {
            label
              ? <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900"> Hi </Listbox.Label>
              : null
          }
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected ? selected.label : (placeHolder ?? "Please select an option") }</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm no-bullets">
                {selectOptions.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    className={({ active, selected }) =>
                      classNames(
                        active && !selected
                          ? `bg-clt-light-gray text-text-brand ${baseOptionClasses}`
                          : `text-text-secondary ${baseOptionClasses}`,
                        selected
                          ? "slow-background-change bg-surface-brand text-white border-black"
                          : "") }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(
                          active && !selected ? "bg-clt-light-gray text-text-brand" : "text-text-secondary",
                          selected ? "slow-background-change bg-surface-brand text-white font-semibold" : "font-normal",
                          "block truncate"
                        )}>
                          {item.label}
                        </span>

                        <span
                          className={classNames(
                            selected ? "slow-background-change text-white absolute inset-y-0 right-0 flex items-center pr-4 bg-surface-brand" : "",
                          )}
                        >
                            { selected ? <CheckIcon className="h-5 w-5" aria-hidden="true" /> : null }
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
