import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps, Label
} from "recharts";
import React from "react";


type DomainBar = { name: string, longName: string, percentCorrect: number, classification: "VR" | "QR" }

type BarGraphProps = {
  data: DomainBar[];
}
type CustomTooltipProps = TooltipProps<number, string>;

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-gray-300 rounded-md" >
        <p>{payload[0].payload.longName}</p>
        <p>{payload[0].value}% Correct</p>
      </div>
    );
  }

   return null;
},

  BarGraph: React.FC<BarGraphProps> = ({ data }: BarGraphProps) =>
    <div className="mt-12 mx-8">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis dataKey="name" tick={{ fontSize: 14, fontFamily: "Assistant", fontWeight: "bold", fill: "#484D61" }} />
          <YAxis domain={[0, 100]} tick={{ fontSize: 14, fontFamily: "Assistant", fill: "#484D61" }}>
            <Label value="Percentage Correct" offset={10} position="insideLeft" angle={-90} style={{ textAnchor: "middle" }} />
          </YAxis>
          <Tooltip
            content={<CustomTooltip />}
            labelFormatter={(v) => data.find(x => x.name === v)?.longName}
          />
          <Bar dataKey="percentCorrect" radius={[10, 10, 0, 0]} barSize={35} background={false}>
            {data.map((entry, index) => (
              entry.classification === "VR"
                  ? <Cell key={`cell-${index}`} fill="#0AC7E5" />
                  : <Cell key={`cell-${index}`} fill="#F89C1C" />
             ))}
          </Bar>
        </BarChart>
    </ResponsiveContainer>
</div>;


export default BarGraph;

