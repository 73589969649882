import React, { createContext, useContext, ReactNode, useCallback, useEffect } from "react";

interface KeyboardContextType {
  addListener: (key: string, action: () => void) => void;
  removeListener: (key: string, action: () => void) => void;
}

const KeyboardContext = createContext<KeyboardContextType | undefined>(undefined);

interface KeyboardProviderProps {
  children: ReactNode;
}

export const KeyboardProvider: React.FC<KeyboardProviderProps> = ({ children }) => {
  const handlers = new Map<string, Array<() => void>>(),

   addListener = useCallback((key: string, action: () => void) => {
    const actions = handlers.get(key) || [];
    handlers.set(key, [...actions, action]);
  }, [handlers]),

   removeListener = useCallback((key: string, action: () => void) => {
    const actions = handlers.get(key);
    if (actions) {
      handlers.set(key, actions.filter(a => a !== action));
    }
  }, [handlers]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {

      const target = event.target as HTMLElement,
       tagName = target.tagName.toLowerCase(),
       isTextInput = (tagName === "input" && ["text", "email", "password"].includes((target as HTMLInputElement).type)) || tagName === "textarea",
       isRadioButton = tagName === "input" && (target as HTMLInputElement).type === "radio";

      if (isTextInput && ["ArrowRight", "ArrowLeft"].includes(event.key)) {
        return;
      }

      if (isRadioButton && ["ArrowRight", "ArrowLeft"].includes(event.key)) {
        event.preventDefault();
      }

      const actions = handlers.get(event.key);
      actions?.forEach(action => action());
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handlers]);

  return (
    <KeyboardContext.Provider value={{ addListener, removeListener }}>
      {children}
    </KeyboardContext.Provider>
  );
};

export function useKeyboardEvent(keys: string[], action: () => void): void {
  const context = useContext(KeyboardContext);

  if (!context) {
    throw new Error("useKeyboardEvent must be used within a KeyboardProvider");
  }

  const { addListener, removeListener } = context;

  useEffect(() => {
    keys.forEach(k => addListener(k, action));
    return () => keys.forEach(k => removeListener(k, action));
  }, [keys, action, addListener, removeListener]);
}
