
type RadialProgressProps = {
  label?: string;
  value: number;
  displayValue?: string;
  rangeLower: number;
  rangeUpper: number;
  colorClass?: string;
}

export default function RadialProgress({ value, rangeLower, rangeUpper, label, colorClass = "clt-primary-orange", displayValue }: RadialProgressProps) {
  const
    percent = (value - rangeLower) / (rangeUpper - rangeLower) * 100,
    dashValue = percent <= 0 ? 1 : percent;

  return (
    <div className="relative">
      <div className="flex items-center justify-center">
        <svg className="w-28 h-28" viewBox="0 0 32 32">
          <circle className="text-gray-200" r="13.91549430918954" cx="16" cy="16" fill="transparent"
                  stroke="currentColor" strokeWidth="2"></circle>
          <circle className={`text-${colorClass}`} r="13.91549430918954" cx="16" cy="16" fill="transparent"
                  stroke="currentColor" strokeWidth="2" pathLength={100} strokeDasharray={`${dashValue} ${100 - dashValue}`}></circle>
        </svg>
        <div className="absolute flex items-center justify-center w-32 h-32">
          <div className="text-lg font-bold text-text-primary">{displayValue ? displayValue : value}</div>
        </div>
      </div>
      {label ? <div className="text-center mt-2 text-sm font-semibold text-gray-500 whitespace-nowrap">{label}</div> : null}
    </div>
  );
}
