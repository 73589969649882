import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { userReducer } from "../slices/userSlice";
import { cltApiTokenReducer } from "../slices/cltApiTokenSlice";
import { profileApi } from "../services/profileApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { emptyApi } from "../services/apiCodeGenRoot";

export const store = configureStore({
  reducer: {
    user: userReducer,
    token: cltApiTokenReducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [emptyApi.reducerPath]: emptyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(profileApi.middleware, emptyApi.middleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
