import { BookOpenIcon, ChartBarIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";


type DomainAccordionProps = {
  category: "VR" | "QR";
  name: string;
  percentCorrect: number;
  subdomains: { name: string, percentCorrect: number, pdfUrl?: string }[];
  className?: string;
}

export default function DomainAccordion({ category, name, percentCorrect, subdomains, className }: DomainAccordionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return <div className={`select-none ${className}`}>
    {/* mainrow */}
    <div className="bg-surface-secondary w-full flex justify-between items-center border-t border-gray-200 h-12">
      <div className="flex items-center ml-4">
        <div className="w-12">{category === "VR"
          ? <BookOpenIcon className="w-8 h-8 mr-2 bg-clt-primary-teal bg-opacity-20 rounded p-1 text-clt-primary-teal"/>
          : <ChartBarIcon className="w-8 h-8 mr-2 bg-clt-primary-orange bg-opacity-20 rounded p-1 text-clt-primary-orange"/>}</div>
          {name}
      </div>
      <div className="flex w-24 justify-between items-center mr-4" onClick={() => setIsOpen(!isOpen)} >
        <div className="flex-1">{percentCorrect}%</div>
        <div className="flex-1">
          {isOpen
            ? <MinusCircleIcon className="w-8 h-8 ml-4 hover:cursor-pointer" />
            : <PlusCircleIcon className="w-8 h-8 ml-4 hover:cursor-pointer" />
          }
        </div>
      </div>
    </div>

    {/*// TODO figure out animations*/}
    {/*<div className={`transition-max-height ease-out duration-300 ${isOpen ? "h-auto max-h-screen" : "max-h-0"}`}>*/}
      {isOpen && subdomains.map((subdomain, index) => (
        <div key={index} className="bg-white w-full flex justify-between items-center border-t border-gray-200 h-12 transition-all duration-300 ease-in-out transform origin-top">
          <div className="flex ml-4">
            <div className="w-12">&nbsp;</div>
            { subdomain.pdfUrl
                ? <a className="underline hover:no-underline" href={subdomain.pdfUrl} target="_blank" rel="noopener noreferrer">{subdomain.name}</a>
                : subdomain.name
            }
          </div>
          <div className="flex w-24 items-center mr-4">{subdomain.percentCorrect}%</div>
        </div>
      ))}
    {/*</div>*/}

  </div>;
}
