import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  type AnalyticsScore,
  type TestType,
  useManagerApiGetAnalyticsScoresQuery,
  useLazyManagerApiGetAnalyticsAggregatesQuery,
  AnalyticsNationalPercentileScoreInfo,
} from "../../../services/gen/managerApi";
import AnalyticsMenuSelect from "./AnalyticsMenuSelect";
import { groupBy, isEmpty, maxBy, minBy, range, uniq, zip } from "lodash";
import NoAnalytics from "./NoAnalytics";
import { toTitleCase } from "../../../shared/utils";
import LoadingOverlay from "../../../components/LoadingOverlay";
import AnalyticsCard from "./AnalyticsCard";
import SectionScoreBarGraph from "./CohortGrowth/SectionScoreBarGraph";
import MetametricsLineGraph from "./CohortGrowth/MetametricsLineGraph";
import CohortTakersPanel from "./CohortGrowth/CohortTakersPanel";

export type AdministrationLabel = `${"Spring" | "Fall"} ${number} ${Uppercase<TestType>}`;

interface AdministrationAverages {
  vr: number;
  lexile: number;
  qr: number;
  quantile: number;
}

/** Contains the growth data for a single administration. */
export interface AdministrationDataPoint {
  label: AdministrationLabel;
  timeKey: number;

  vr: number | null;
  lexile: number | null;
  national50thLexile: number;
  national90thLexile: number;

  qr: number | null;
  quantile: number | null;
  national50thQuantile: number;
  national90thQuantile: number;
}

export function getAdministrationLabel(score: AnalyticsScore) {
  return `${toTitleCase(score.semester)} ${score.year} ${score.testType.toUpperCase()}` as AdministrationLabel;
}

export function getAdministrationInfo(label: AdministrationLabel) {
  const 
    [semester, year, testType] = label.split(" "),
    gradeLevel = testType.slice(3),
    semKey = Number(semester.toUpperCase() === "SPRING") * 5, // 0 or 5 for Fall or Spring, respectively.
    timeKey = Number(`${gradeLevel}${semKey}`);

  return {
    semester: semester.toUpperCase() as "SPRING" | "FALL",
    year: year as `${number}`,
    testType: testType.toLowerCase() as TestType,
    gradeLevel,
    timeKey,
  };
}

export function getNumericMetametricsScore(reportable: string) {
  return Number(reportable
    .replace(/(EM|BR)/, "-")
    .replace(/[QL]/, "")
  );
}

export function getDisplayMetametricsScore(classification: "VR" | "QR", score: number) {
  const { prefix, suffix } = classification === "VR"
    ? { prefix: "BR", suffix: "L" }
    : { prefix: "EM", suffix: "Q" };
  return `${score}${suffix}`.replace("-", prefix);
}

function calculateAdministrationAverages(cohortScores: AnalyticsScore[]): Record<AdministrationLabel, AdministrationAverages> {
  const administrations = groupBy(cohortScores, getAdministrationLabel);

  return Object.fromEntries(Object.entries(administrations)
    .map(([label, admScores]) => {
      const
        // Guaranteed to be at least one administration score, or else the group wouldn't exist.
        count = admScores.length,
        totalsInit = { vr: 0, lexile: 0, qr: 0, quantile: 0 },
        totals = admScores.reduce(
          (acc, score) => ({
            vr: acc.vr + score.vr,
            lexile: acc.lexile + score.lexile,
            qr: acc.qr + score.qr,
            quantile: acc.quantile + score.quantile,
          }),
          totalsInit,
        );

      return [
        label,
        {
          vr: Math.round(totals.vr / count),
          lexile: Math.round(totals.lexile / count),
          qr: Math.round(totals.qr / count),
          quantile: Math.round(totals.quantile / count),
        } as AdministrationAverages,
      ];
    }));
}

function getLabelForTimeKey(timeKey: number, cohortYear: number): AdministrationLabel {
  const
    semesterOffset = 1 - ((timeKey % 10) / 5),
    gradeLevel = Math.floor(timeKey / 10),
    administrationYear = cohortYear - semesterOffset - (12 - gradeLevel),
    testType = `CLT${gradeLevel}` as Uppercase<TestType>;
    return `${semesterOffset ? "Fall" : "Spring"} ${administrationYear} ${testType}`;
}

function sortAndSaturateDataPoints(
  data: AdministrationDataPoint[],
  cohortYear: number,
  nationalPercentileScores: AnalyticsNationalPercentileScoreInfo,
): AdministrationDataPoint[] {
  // Fill in missing semesters with empty data.
  const
    earliest = minBy(data, d => d.timeKey)?.timeKey ?? 30,
    latest = maxBy(data, d => d.timeKey)?.timeKey ?? 85;

  return range(earliest / 5, latest / 5 + 1).map(i => {
    const
      timeKey = i * 5,
      point = data.find(d => d.timeKey === timeKey);

    if (point) {
      return point;
    }

    const
      label = getLabelForTimeKey(timeKey, cohortYear),
      { semester, gradeLevel } = getAdministrationInfo(label);
    
    return {
      label,
      timeKey,

      vr: null,
      lexile: null,
      national50thLexile: nationalPercentileScores.lexile[gradeLevel][semester]["50th"].raw,
      national90thLexile: nationalPercentileScores.lexile[gradeLevel][semester]["90th"].raw,

      qr: null,
      quantile: null,
      national50thQuantile: nationalPercentileScores.quantile[gradeLevel][semester]["50th"].raw,
      national90thQuantile: nationalPercentileScores.quantile[gradeLevel][semester]["90th"].raw,
    };
  });
}

export default function CohortGrowth() {
  const
    [searchParams] = useSearchParams(),
    overrideIdUser = searchParams.get("u") ?? "",
    { data: scoreData, isSuccess: isScoresSuccess, isLoading: isScoresLoading } = useManagerApiGetAnalyticsScoresQuery(overrideIdUser ? { u: overrideIdUser } : {}),
    [getAggregates, { data: aggregatesData, isLoading: isAggregatesLoading }] = useLazyManagerApiGetAnalyticsAggregatesQuery(),
    scores = scoreData?.scores ?? [],
    nationalPercentileScores = scoreData?.nationalPercentileScores ?? { lexile: {}, quantile: {} },

    [selectedCohortYear, setSelectedCohortYear] = useState("" as `${number}`),
    [selectedScores, setSelectedScores] = useState<AnalyticsScore[] | null>(null),
    [filtered, setFiltered] = useState(false),
    
    // State that depends on the scores.
    scoreDeps = useMemo(() => {
      if ((isScoresLoading && !isScoresSuccess) || scores.length === 0) return null;

      const
        cohortTestGroups = Object.values(groupBy(scores, s => `${s.cohortYear}-${s.semester}-${s.testType}`)),
        cohortYears = uniq(scores.map(s => `${s.cohortYear}` as `${number}`)).sort();

      return {
        defaultCohortYear: cohortYears[0] ?? "",

        // Include all years from earliest actual cohort to latest, but disable ones that don't have enough data.
        cohortYearSelectOptions: cohortYears.length
          ? range(+cohortYears[0], +cohortYears[cohortYears.length - 1] + 1).map(y => {
              const year: `${number}` = `${y}`;
              return {
                id: year,
                label: year,
                disabled: !(cohortTestGroups.filter(g => g[0].cohortYear === y).length > 1),
              };
            })
          : [],
      };
    }, [scores]);

  useEffect(() => {
    scoreDeps && setSelectedCohortYear(scoreDeps.defaultCohortYear);
  }, [scoreDeps]);

  const
    cohortScores = useMemo(() => {
      if (!selectedCohortYear) return null;
      const cohortScores = scores.filter(s => s.cohortYear === +selectedCohortYear);

      setSelectedScores(cohortScores);
      return cohortScores;
    }, [selectedCohortYear]),

    administrationAverages = useMemo(
      () => selectedScores && calculateAdministrationAverages(selectedScores),
      [selectedScores],
    ),
    
    displayDataPoints = useMemo(() => {
      if (
        !administrationAverages
        || !aggregatesData
        || Object.keys(administrationAverages).length !== aggregatesData.length
      ) return null;

      // Merge cohort's administration averages with the display aggregates from the API
      // and then sort chronologically, filling in missing semesters with blanks.
      return sortAndSaturateDataPoints(
        zip(Object.entries(administrationAverages), aggregatesData)
          .map(([[label, avg] = [], agg]) => {
            const { timeKey, semester, gradeLevel } = getAdministrationInfo(label as AdministrationLabel);
            return {
              label,
              timeKey,

              vr: avg?.vr ?? 0,
              lexile: getNumericMetametricsScore(agg?.lexileReportable ?? "0L"),
              national50thLexile: nationalPercentileScores.lexile[gradeLevel][semester]["50th"].raw,
              national90thLexile: nationalPercentileScores.lexile[gradeLevel][semester]["90th"].raw,

              qr: avg?.qr ?? 0,
              quantile: getNumericMetametricsScore(agg?.quantileReportable ?? "0Q"),
              national50thQuantile: nationalPercentileScores.quantile[gradeLevel][semester]["50th"].raw,
              national90thQuantile: nationalPercentileScores.quantile[gradeLevel][semester]["90th"].raw,
            } as AdministrationDataPoint;
          }),
        +selectedCohortYear,
        nationalPercentileScores,
      );
    }, [administrationAverages, aggregatesData]);

  useEffect(() => {
    administrationAverages && !isEmpty(administrationAverages) && getAggregates({
      body: Object.entries(administrationAverages).map(([label, avg]) => {
        const { testType, semester } = getAdministrationInfo(label as AdministrationLabel);
        return {
          testType,
          semester,
          meanLexile: avg.lexile,
          meanQuantile: avg.quantile,
        };
      }),
    });
  }, [administrationAverages]);

  if (
    (isScoresSuccess && scores.length === 0)
    || scoreDeps?.cohortYearSelectOptions?.length === 0 
    || scoreDeps?.cohortYearSelectOptions?.every(o => o.disabled)
  ) {
    return <NoAnalytics text="Cohort growth analytics will be available when you have a cohort with multiple released scores."/>;
  }

  if (!scoreDeps || !cohortScores || !selectedScores || !administrationAverages || !aggregatesData) {
    return <NoAnalytics />;
  }

  if (isScoresLoading || isAggregatesLoading) {
    return <LoadingOverlay />;
  }

  return (
    <div className="relative min-h-screen w-full flex flex-col flex-grow-0 min-w-fit bg-surface-secondary print:[zoom:75%]">
      <div>
        <header className="z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray print:pb-0 print:shadow-none">
          <h1 className="text-xl">Analytics</h1>
        </header>

        <main className="flex-grow p-4 overflow-auto min-h-screen">
          <div className="w-full flex justify-between h-18 border-b-2 border-gray-300 items-center">
            <div className="ml-1 bg-clt-light-gray flex justify-start space-x-2 pb-4">
              <Link
                to={`/manager/analytics/tests${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                className="font-btn uppercase text-sm text-text-secondary bg-clt-medium-gray py-2 px-3 rounded-rounded hover:shadow-md transition-shadow"
              >
                Tests
              </Link>
              <Link
                to={`/manager/analytics/cohort-growth${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                className="font-btn uppercase text-sm text-text-inverted shadow-md bg-clt-collegiate-blue py-2 px-3 rounded-rounded"
              >
                Cohort Growth
              </Link>
            </div>
            <div className="bg-clt-light-gray flex justify-end space-x-2 z-30 pb-4">
              <AnalyticsMenuSelect<`${number}`>
                options={scoreDeps.cohortYearSelectOptions}
                selectedValue={selectedCohortYear}
                setSelectedValue={setSelectedCohortYear}
                labelPrefix="Graduation Year:"
              />
            </div>
          </div>
          <div className="flex justify-between mx-16 my-8 whitespace-nowrap text-text-secondary">
            <div className="text-2xl font-serif">Cohort Growth</div>
            <div className="flex items-center gap-4 text-xl font-sans">
              <span>Cohort {selectedCohortYear}</span>
            </div>
          </div>

          <div className="flex flex-wrap justify-evenly items-center gap-4">
            <div className="flex flex-col items-center gap-4 flex-1">
              <AnalyticsCard title={`Verbal Reasoning Over Time - Cohort ${selectedCohortYear}${filtered ? "*" : ""}`}>
                <SectionScoreBarGraph classification="VR" source={displayDataPoints ?? []} />
              </AnalyticsCard>

              <AnalyticsCard title={`Lexile® measure - Cohort ${selectedCohortYear}${filtered ? "*" : ""}`}>
                <MetametricsLineGraph classification="VR" source={displayDataPoints ?? []} />
              </AnalyticsCard>
            </div>

            <div className="flex flex-col items-center gap-4 flex-1">
              <AnalyticsCard title={`Quantitative Reasoning Over Time - Cohort ${selectedCohortYear}${filtered ? "*" : ""}`}>
                <SectionScoreBarGraph classification="QR" source={displayDataPoints ?? []} />
              </AnalyticsCard>

              <AnalyticsCard title={`Quantile® measure - Cohort ${selectedCohortYear}${filtered ? "*" : ""}`}>
                <MetametricsLineGraph classification="QR" source={displayDataPoints ?? []} />
              </AnalyticsCard>
            </div>
          </div>
          {filtered && <div className="mt-2 text-text-secondary text-sm font-bold">* Data is filtered.</div>}
          <div className="h-128 print:hidden">&nbsp;</div>
        </main>
        <footer className="fixed ml-52 inset-x-0 bottom-0 bg-white border-t p-4 h-24">
          <CohortTakersPanel
            cohortScores={cohortScores}
            setScores={setSelectedScores}
            reportFiltered={setFiltered}
          />
        </footer>
      </div>
    </div>
  );
}
