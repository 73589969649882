import "./index.css";
import {
  Outlet
} from "react-router-dom";
import VerifyAuth from "./components/VerifyAuth";
import { StudentNav } from "./components/StudentNav";

type AppProps = {
  isTest?: boolean
}

function StudentApp(props: AppProps = { isTest: false }) {
  return (
        <VerifyAuth isTest={props.isTest} child={
          <div className="flex w-full h-full">
            <div className="flex-initial w-52"> <StudentNav/> </div>
            <div className="flex-1"> <Outlet /> </div>
          </div>
        }/>
  );
}

export default StudentApp;
