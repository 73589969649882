import { type AccessCode } from "../../../services/gen/managerApi";

const
  hiddenAccessCodeInstructions = (
    <div className="text-sm text-text-primary">
      Your access code isn&rsquo;t available yet! Access codes will be visible two weeks before the test opens. Check back later!
    </div>
  ),
  onlineAccessCodeInstructions = (
    <div className="text-sm text-text-primary">
      To begin the exam:
      <ul className="leading-snug mt-2">
        <li className="mb-0">Have your student(s) go to <a href="https://test.cltexam.com" target="_blank" rel="noreferrer" className="font-bold text-clt-light-collegiate-blue underline hover:no-underline">test.cltexam.com</a>.</li>
        <li className="mb-0">They will be prompted to type in the code listed above.</li>
        <li className="mb-0">Each test type has a unique code, so be sure to provide students with the correct one.</li>
      </ul>
    </div>
  ),
  paperAccessCodeInstructions = (
    <div className="text-sm text-text-primary">
      <ul className="leading-snug mt-2">
        <li className="mb-0">This code must be bubbled in on students{"'"} answer sheets.</li>
        <li className="mb-0">Each test type has a unique code, so be sure to provide students with the correct one.</li>
      </ul>
    </div>
  );

export const accessCodeInstructions: { [M in AccessCode["mode"] | "HIDDEN"]: JSX.Element } = {
  HIDDEN: hiddenAccessCodeInstructions,
  ONLINE: onlineAccessCodeInstructions,
  PAPER: paperAccessCodeInstructions,
};
