import { Fragment }  from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  XCircleIcon,
  QuestionMarkCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import colors from "../app/colors";
import { noop } from "../shared/utils";

export type ModalType = "ERROR" | "SUCCESS" | "INFO" | "WARN";
type ModalProps = {
    heading?: string,
    subheading?: string,
    okButtonText?: string,
    cancelButtonText?: string,
    showCancel?: boolean,
    modalType?: ModalType,
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>,
    onCancel?: () => void,
    onOK?: () => void,
    customFont?: string,
    cancelButtonClass?: string,
    okButtonClass?: string,
    children?: React.ReactNode
};

const renderIcon = (modalType: ModalType) => {
    let inner, outerClass;

    switch (modalType) {
        case "ERROR":
           inner = <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>;
           outerClass = "bg-red-100";
           break;
        case "SUCCESS":
           inner = <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>;
           outerClass = "bg-green-100";
           break;
        case "INFO":
           inner = <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true"/>;
           outerClass = "bg-gray-100";
           break;
        case "WARN":
           inner = <ExclamationTriangleIcon className="h-6 w-6" aria-hidden="true"/>;
           outerClass = "bg-yellow-600";
           break;
        default:
           inner = <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true"/>;
           outerClass = "bg-gray-100";
    }

    return <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${outerClass}`}>
        {inner}
    </div>;
};

export default function Modal(
  {
    heading = "",
    subheading = "",
    okButtonText = "OK",
    cancelButtonText = "Cancel",
    showCancel = false,
    modalType = "INFO",
    setShowModal,
    onCancel,
    onOK,
    customFont = "",
    cancelButtonClass = "",
    okButtonClass = "",
    children }: ModalProps
) {

    return (
      <Transition.Root show={true} as={Fragment}>
          <Dialog as="div" className={`relative z-10 ${customFont}`} onClose={setShowModal ?? noop}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                          <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                              <div>
                                  {renderIcon(modalType)}
                                  <div className="mt-3 text-center sm:mt-5">
                                      <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                                          {heading}
                                      </Dialog.Title>
                                      <div className="mt-2">
                                          <p className="text-lg text-gray-500">
                                              {subheading}
                                          </p>
                                      </div>
                                  </div>
                              </div>
                              <div className="flex mt-8 sm:mt-8">
                                  {showCancel &&
                                    <button
                                        type="button"
                                        className={`inline-flex justify-center w-full rounded-minimal border border-transparent shadow-sm mx-4 py-2 ${colors.secondary_bg} text-base font-medium ${colors.secondary_text} hover:${colors.secondary_hover} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:${colors.secondary_ring} sm:text-sm ${cancelButtonClass}`}
                                        onClick={() => { setShowModal && setShowModal(false); onCancel && onCancel(); }}
                                      >
                                          {cancelButtonText}
                                      </button>
                                  }

                                  <button
                                    autoFocus={true}
                                    type="button"
                                    className={`inline-flex justify-center w-full rounded-minimal border border-transparent shadow-sm mx-4 py-2 bg-cltblue text-base font-medium ${colors.secondary_text} hover:${colors.secondary_hover} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:${colors.secondary_ring} sm:text-sm ${okButtonClass}`}
                                    onClick={() => { setShowModal && setShowModal(false); onOK && onOK(); }}
                                  >
                                      {okButtonText}
                                  </button>
                              </div>
                              {children ?
                              <div className="mt-6 text-center">
                                  {children}
                              </div> : null
                              }
                          </Dialog.Panel>
                      </Transition.Child>
                  </div>
              </div>
          </Dialog>
      </Transition.Root>
    );
}
