import { useMemo } from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  Polygon,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { classNames, KeyOfType } from "../../../../shared/utils";
import { getDisplayMetametricsScore, type AdministrationDataPoint } from "../CohortGrowth";

interface MetametricsLineGraphProps {
  classification: "VR" | "QR";
  source: AdministrationDataPoint[];
}

type DataKey<T> = KeyOfType<AdministrationDataPoint, T>;

const
  pi_5 = Math.PI / 5,
  c1 = Math.cos(2 * pi_5),
  c2 = Math.cos(pi_5),
  s1 = Math.sin(2 * pi_5),
  s2 = Math.sin(pi_5),
  starVertices = (r: number) => {
    // Math behind this is based on https://mathworld.wolfram.com/RegularPentagon.html.
    const i = c1 / c2 * r;  // Inner radius of star.
    return [
      { x:  0. * r, y: -1. * r },
      { x: +s2 * i, y: -c2 * i },
      { x: +s1 * r, y: -c1 * r },
      { x: +s1 * i, y: +c1 * i },
      { x: +s2 * r, y: +c2 * r },
      { x:  0. * i, y: +1. * i },
      { x: -s2 * r, y: +c2 * r },
      { x: -s1 * i, y: +c1 * i },
      { x: -s1 * r, y: -c1 * r },
      { x: -s2 * i, y: -c2 * i },
    ];
  },
  StarDot: React.FC<{
    cx?: number;
    cy?: number;
    r?: number;
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
  }> = ({ cx, cy, r = 1, fill, stroke, strokeWidth }) => {
    if (!cx || !cy) return null;
    const points = useMemo(() => starVertices(r).map(({ x, y }) => ({ x: cx + x, y: cy + y })), [r, cx, cy]);
    return <Polygon {...{ fill, stroke, strokeWidth }} points={points} />;
  },

  diamondVertices = (r: number) => [
    { x:  0.0 * r, y: -1 * r },
    { x: +0.7 * r, y:  0 * r },
    { x:  0.0 * r, y: +1 * r },
    { x: -0.7 * r, y:  0 * r },
  ],
  DiamondDot: React.FC<{
    cx?: number;
    cy?: number;
    r?: number;
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
  }> = ({ cx, cy, r = 1, fill, stroke, strokeWidth }) => {
    if (!cx || !cy) return null;
    const points = useMemo(() => diamondVertices(r).map(({ x, y }) => ({ x: cx + x, y: cy + y })), [r, cx, cy]);
    return <Polygon {...{ fill, stroke, strokeWidth }} points={points} />;
  },

  CustomTooltip: React.FC<TooltipProps<number, string> & { classification: "VR" | "QR" }> = ({ active, payload, classification }) => {
    if (active && payload && payload.length) {
      const [nat50th, nat90th, ability] = payload;
      return (
        <div className="shadow-md bg-white text-text-primary border-2 border-clt-medium-gray rounded-rounded divide-y-2 divide-clt-medium-gray">
          <div className="p-2">
            <h3 className="text-lg">{nat50th.payload.label}</h3>
            {ability?.value
              ? <p className={`font-bold text-lg ${classification === "VR" ? "text-clt-primary-teal" : "text-clt-primary-orange"}`}>
                  {getDisplayMetametricsScore(classification, ability.value)}
                </p>
              : <p>Not taken.</p>
            }
          </div>
          <div className="flex flex-col items-center p-2 gap-y-1">
            <h3 className="text-sm">Reference Percentiles</h3>
            <div className="flex text-text-secondary text-sm divide-x-2 divide-clt-medium-gray">
              <div className="flex flex-col items-center px-4">
                <h4 className="font-bold">50th</h4>
                <span className="block">{getDisplayMetametricsScore(classification, nat50th.value ?? 0)}</span>
              </div>
              <div className="flex flex-col items-center px-4">
                <h4 className="font-bold">90th</h4>
                <span className="block">{getDisplayMetametricsScore(classification, nat90th.value ?? 0)}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  },
  CustomTick: React.FC<{ x?: number, y?: number, payload?: { value: string }, nulls: string[] }> = ({ x, y, payload, nulls }) => {
    if (!x || !y || !payload) return null;
    const { fontStyle, fill } = nulls.includes(payload.value) 
      ? { fontStyle: "italic", fill: "#B5C1CB" }
      : { fontStyle: "normal", fill: "#484D61" };

    return <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fontStyle={fontStyle} fill={fill} style={{ fontFamily: "Assistant" }}>
        {payload.value}
      </text>
    </g>;
  };

export default function MetametricsLineGraph({ classification, source }: MetametricsLineGraphProps) {
  const
    show = source.length > 1,
    dataKeys: {
      label: DataKey<string>;
      timeKey: DataKey<number>;
      ability: DataKey<number | null>;
      national50th: DataKey<number>;
      national90th: DataKey<number>;
    } = {
      label: "label",
      timeKey: "timeKey",
      ...(classification === "VR"
        ? {
          ability: "lexile",
          national50th: "national50thLexile",
          national90th: "national90thLexile",
        }
        : {
          ability: "quantile",
          national50th: "national50thQuantile",
          national90th: "national90thQuantile",
        }
      ),
    },
    { label, lineColor } = classification === "VR"
      ? { label: "Cohort Lexile® measure", lineColor: "#0AC7E5" }
      : { label: "Cohort Quantile® measure", lineColor: "#F89C1C" },
    nulls = useMemo(() => source.filter(d => d[dataKeys.ability] === null).map(d => d.label), [source]);

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={show ? source : []} syncId={classification} margin={{ top: 20, bottom: 10, left: 30, right: 60 }}>
          <XAxis
            dataKey={dataKeys.label}
            axisLine={false}
            tickSize={15}
            tick={<CustomTick nulls={nulls} />}
            strokeWidth={2}
            stroke="#F1F5F8"
          />
          <YAxis
            tick={{ fill: "#232020", fontFamily: "Assistant" }}
            domain={show
              ? [
                (min: number) => Math.round((min - 50) / 100) * 100,  // min - 50 rounded to nearest 100
                (max: number) => Math.round((max + 50) / 100) * 100,  // max + 50 rounded to nearest 100
              ]
              : [0, 1000]  // Fake axis for "empty" chart.
            }
            tickCount={3}
            axisLine={false}
            tickLine={false}
            tickFormatter={t => getDisplayMetametricsScore(classification, t)}
          >
            <Label
              value={label}
              offset={5}
              position="insideLeft"
              angle={-90}
              style={{
                fontFamily: "Assistant",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAnchor: "middle",
              }}
            />
          </YAxis>
          <CartesianGrid stroke="#F1F5F8" strokeWidth={2} />
          <Legend
            verticalAlign="top"
            formatter={value => <span className="text-sm text-text-secondary font-bold">{value}</span>}
            payload={[
              { id: dataKeys.national50th, value: "50th Percentile", type: "diamond", color: "#002F47" },
              { id: dataKeys.national90th, value: "90th Percentile", type: "star", color: "#116B99" },
              { id: dataKeys.ability, value: label, type: "circle", color: lineColor },
            ]}
          />
          <Tooltip content={<CustomTooltip classification={classification} />} cursor={{ stroke: "#F1F5F8", strokeWidth: 3 }} />
          <Line
            dataKey={dataKeys.national50th}
            stroke="#D5E1EB"
            strokeWidth={2}
            strokeDasharray="3 3"
            dot={<DiamondDot r={7} stroke="white" fill="#002F47" />}
            activeDot={<DiamondDot r={9} stroke="white" fill="#002F47" />}
            isAnimationActive={false}
          />
          <Line
            dataKey={dataKeys.national90th}
            stroke="#D5E1EB"
            strokeWidth={2}
            strokeDasharray="3 3"
            dot={<StarDot r={8} stroke="white" fill="#116B99" strokeWidth={1.5} />}
            activeDot={<StarDot r={10} stroke="white" fill="#116B99" strokeWidth={1.5} />}
            isAnimationActive={false}
          />
          <Line
            name={label}
            dataKey={dataKeys.ability}
            stroke={lineColor}
            strokeWidth={2}
            dot={{ r: 4, fill: lineColor, stroke: "white", strokeWidth: 2 }}
            activeDot={{ r: 6, fill: lineColor, stroke: "white", strokeWidth: 2 }}
            legendType="circle"
            isAnimationActive={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
      <div
        className={classNames(
          show ? "opacity-0" : "opacity-100",
          "absolute inset-0 pointer-events-none backdrop-blur-sm text-text-primary transition-opacity flex justify-center",
        )}
      >
        <div className="flex flex-col gap-2 my-auto text-center">
          <h3 className="text-xl font-bold">Not enough data selected.</h3>
          <p>Please include data from at least two tests.</p>
        </div>
      </div>
    </div>
  );
}
