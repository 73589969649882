import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CltApiTokenState {
    value: string | null;
}

const initialState: CltApiTokenState= {
    value: null
};

export const cltApiTokenSlice = createSlice({
    name: "cltApiToken",
    initialState,
    reducers: {
        setCltApiToken(state, action: PayloadAction<CltApiTokenState>) {
            state.value = action.payload.value;
        },
    }
});

export const { setCltApiToken } = cltApiTokenSlice.actions;
export const cltApiTokenReducer = cltApiTokenSlice.reducer;
