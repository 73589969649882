import Ajv, { JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";
import ajvErrors from "ajv-errors";
import { ListItem } from "../../shared/types";
import dayjs from "dayjs";

const ajv = new Ajv({ allErrors: true });
addFormats(ajv, { mode: "fast" });
ajvErrors(ajv);

export const getValidator = (schema: JSONSchemaType<unknown>) => ajv.compile(schema);

// Manual value-based validation on top of JSON Schema validation.
export const getManualValidator = () => (v: { [k: string]: string | number | boolean | null | ListItem[] }) => {
  if (v["dob"]) {
    const
      dob = dayjs(v["dob"] as string),
      min = dayjs().subtract(100, "years"),
      max = dayjs().subtract(13, "years");

    if (dob.isBefore(min) || dob.isAfter(max)) {
      return false;
    }
  }

  return true;
};
