import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HelpModal from "./HelpModal";

type AnalyticsCardProps = {
  children: React.ReactNode;
  className?: string;
  title?: string
  help?: { title: React.ReactNode; content: React.ReactNode; }
} & React.HTMLAttributes<HTMLDivElement>;

const AnalyticsCard: React.FC<AnalyticsCardProps> = ({ children, className, title, help= { title: "", content: <div/> }, ...rest }) => {
  const [showHelpModal, setShowHelpModal] = React.useState(false);

  return (
    <div className={`relative group w-full h-full aspect-w-1 aspect-h-1 p-4 bg-white rounded-3xl drop-shadow-md whitespace-nowrap ${className}`} {...rest}>
      { help.title
        ? <div className="z-10 absolute opacity-50 group-hover:opacity-100 transition-all duration-300 ease-in-out top-2 right-2 cursor-pointer print:opacity-0"
               onClick={() => setShowHelpModal(true)}>
            <InformationCircleIcon className="w-6 h-6 text-gray-500"/>
          </div>
        : null }
      {title
        ? <div className="w-full pl-6 text-text-secondary text-2xl font-bold print:text-xl">{title}</div>
        : null}
      {children}

      <HelpModal showHelpModal={showHelpModal} setShowHelpModal={setShowHelpModal} title={help.title} content={help.content}/>

    </div>
  );
};

export default AnalyticsCard;
