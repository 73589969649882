import type { PropsWithChildren } from "react";
import { classNames } from "../shared/utils";

interface Props {
  tooltipContent: string | JSX.Element;
  tooltipClassName?: string;
  xPos?: "LEFT" | "CENTER" | "RIGHT";
  yPos?: "TOP" | "CENTER" | "BOTTOM";
}

export default function Tooltipped({
  tooltipContent,
  tooltipClassName,
  xPos = "CENTER",
  yPos = "CENTER",
  children,
}: PropsWithChildren<Props>) {
  if (!children) return null;
  if (!tooltipContent) return <>{children}</>;

  const
    xPosClassNames = {
      LEFT: "right-full",
      CENTER: "left-1/2 transform -translate-x-1/2",
      RIGHT: "left-full",
    }[xPos],
    yPosClassNames = {
      TOP: "bottom-full",
      CENTER: "top-1/2 transform -translate-y-1/2",
      BOTTOM: "top-full",
    }[yPos];
    
  return (
    <div className="relative">
      <div className="peer">
        {children}
      </div>
      <div
        className={classNames(
          "absolute opacity-0 rounded-minimal bg-white shadow-lg transition-colors pointer-events-none",
          "px-2.5 py-1 text-sm z-10",
          xPosClassNames,
          yPosClassNames,
          "peer-hover:opacity-100",
          tooltipClassName,
        )}
      >
        {tooltipContent}
      </div>
    </div>
  );
}
