import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import StudentApp from "./StudentApp";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { Auth0Provider } from "@auth0/auth0-react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import StudentProfile from "./routes/StudentProfile";
import Logout from "./routes/Logout";
import Exam from "./Exam";
import { Welcome } from "./routes/Exam/Welcome";
import { Show } from "./routes/Exam/Show";
import { Begin } from "./routes/Exam/Begin";
import { KeyboardProvider } from "./KeyboardProvider";
import App from "./App";
import Dashboard from "./routes/Manager/Dashboard";
import Registrations from "./routes/Manager/Registrations";
import Tests from "./routes/Manager/Analytics/Tests";
import Resources from "./routes/Manager/Resources";
import CohortGrowth from "./routes/Manager/Analytics/CohortGrowth";

Bugsnag.start({
  apiKey: "4b2ee7ad862bb26bf1c6c45d7a7de434",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENVIRONMENT
});

const ErrorView = () =>
  <div>
    <p>There was a problem!  Please contact info@cltexam.com if the problem persists.</p>
  </div>,

  ErrorBoundary: BugsnagErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React) as BugsnagErrorBoundary,

  root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
),

  redirectToOldSite = (path: string): Promise<null> => {
  window.location.replace(`${process.env.REACT_APP_OLDPHP_BASE_URL}${path}`);
  return Promise.resolve(null);
},

 router = createBrowserRouter([
   {
     path: "/exam/begin",
     element: <Begin />,
     ErrorBoundary,
   },
   {
    path: "/exam",
    element: <Exam />,
    ErrorBoundary,
     children: [
       {
         path: "welcome",
         element: <Welcome />,
       },
       {
         path: "questions",
         element: <Show />,
       },
     ]
  },
  {
    path: "/manager",
    element: <App />,
    ErrorBoundary,
    children: [
      {
        path: "",
        loader() { window.location.replace("/manager/dashboard"); return Promise.resolve(null); }
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "registrations",
        element: <Registrations />,
      },
      {
        path: "analytics/tests",
        element: <Tests isSingleReport={false}/>,
      },
      {
        path: "analytics/cohort-growth",
        element: <CohortGrowth />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
    ],
  },
  {
    path: "/",
    element: <StudentApp />,
    ErrorBoundary,
    children: [
      {
        path: "",
        loader: () => redirectToOldSite("dashboard"),
      },
      {
        path: "dashboard",
        loader: () => redirectToOldSite("dashboard")
      },
      {
        path: "profile",
        element: <StudentProfile/>,
      },
      {
        path: "cart",
        loader: () => redirectToOldSite("cart")
      },
      {
        path: "practice-tests",
        loader: () => redirectToOldSite("practice-tests")
      },
      {
        path: "test-shares",
        loader: () => redirectToOldSite("test-shares")
      },
      {
        path: "test-day",
        loader: () => redirectToOldSite("test-day")
      },
      {
        path: "product-details/Student-Guide",
        loader: () => redirectToOldSite("product-details/Student-Guide")
      }
    ],
  },
  {
    path: "/analytics",
    element: <Tests isSingleReport={true} />,
  },
  {
    path: "/logout",
    element: <Logout />
  },
  { path: "*",
    element:
        <div>
          <nav>
            <Link to="/">Go Home</Link>
          </nav>
          <main style={{ padding: "1rem" }}>
            <p>There is nothing here!</p>
          </main>
        </div>
  }
]);

root.render(
  <ErrorBoundary FallbackComponent={ErrorView}>
    <React.StrictMode>
      <KeyboardProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN + ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID + ""}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        scope=""
        useRefreshTokens
      >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </Auth0Provider>
      </KeyboardProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
