import React from "react";

type MetametricsMeasureReportProps = {
  measure: "Lexile" | "Quantile";
  reportable: string;
};

const MetametricsMeasureReport: React.FC<MetametricsMeasureReportProps> = ({ measure, reportable }) => (
    <div className="flex flex-col items-center p-4">
      <div className="text-3xl text-surface-brand font-semibold">{reportable}</div>
      <div className="text-center mt-2 text-sm font-semibold text-gray-500">{measure}&#174; measure</div>
    </div>
  );

export default MetametricsMeasureReport;
