import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
    username: string | null;
    idUser: number | null;
}

const initialState: UserState = {
    username: null,
    idUser: null
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserState(state, action: PayloadAction<UserState>) {
            state.username = action.payload.username;
            state.idUser = action.payload.idUser;
        },
    }
});

export const { setUserState } = userSlice.actions;
export const userReducer = userSlice.reducer;
