import { type BaseQueryFn, createApi, type FetchArgs, fetchBaseQuery, type FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { StatusCodes } from "http-status-codes";

  const baseQuery =fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CLT2_BASE_URL,
  credentials: "include",
  prepareHeaders(headers, { getState }) {
    const token = (getState() as RootState).token.value;
    headers.set("Content-Type", "application/json");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  // TODO it would be better to test for presence of an auth0 token and redirect to /logout if it's not there
  if (result?.error?.status === StatusCodes.UNAUTHORIZED) {
    if (/^\/exam\//.test(window.location.pathname)) {
      window.location.href = `${window.location.origin}/exam/begin`;
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  }

  return result;
};

export const emptyApi = createApi({
  reducerPath: "codeGenApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});