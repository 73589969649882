import { memo, useState } from "react";

export type InputProps = {
  key: string,
  label: string
  id: string
  initialValue?: string
  placeholder?: string
  min?: number | string
  max?: number | string
  maxLength?: number
  step?: number
  handleChange?: (val: string) => void
  handleSave?: (id: string, val: string) => void
  selectOptions?: {label: string, value: string }[]
  type?: "text" | "date" | "checkbox" | "tel" | "number"
  isRequired?: boolean
  errorMessage?: string,
  showError: boolean,
  isDisabled?: boolean
  disabledHoverText?: string
  currentTabSeen: boolean
};

const Input = memo<InputProps>(function({ label, id, initialValue = "", placeholder, min, max, maxLength, step, handleChange, handleSave, selectOptions, type, isRequired, errorMessage, showError = false, isDisabled = false, disabledHoverText = "", currentTabSeen = false }: InputProps) {
   const disabledClasses = isDisabled ? "pointer-events-none select-none bg-stone-200" : "",
    baseClasses = "border-gray-400 text-md h-10 w-64 border pl-2 focus:ring-cltblue focus:border-0",
    errorClasses = showError ? "border-red-400" : "",
    [value, setValue] = useState<string>(initialValue),
    requiredTextClasses = `lowercase text-xs font-medium pl-2 ${currentTabSeen && !initialValue ? "text-red-500" : ""}`;

  if (selectOptions) {
    return <span key={`key-${id}`} className="flex flex-col justify-between max-h-full m-4" title={isDisabled ? disabledHoverText : ""}>
      <label className="uppercase text-md" htmlFor={id}>
        {label}
        {/*<span className="lowercase text-xs font-medium text-red-700">{errorMessage}</span>*/}
        {isRequired ? <span className={`${requiredTextClasses}`}>(required)</span> : null}
      </label>
      <select name={id}
              id={id}
              className={`${baseClasses} ${errorClasses}`}
              onChange={(e) => {
                setValue(e.target.value);
                handleChange && handleChange(e.target.value);  // set parent valueRef, if handler provided
                handleSave && handleSave(e.target.id, e.target.value);  // save to API
              }}
              value={value}
              disabled={isDisabled}
              tabIndex={isDisabled ? -1 : 0}
      >
        <option value="">Select</option>
        {selectOptions.map((o, i) => <option key={`select-${id}-${i}`} value={o.value}>{o.label}</option>)}
      </select>
    </span>;
  }

  return <div key={`key-${id}`} className="flex flex-col justify-between max-h-full min-w-fit m-4" title={isDisabled ? disabledHoverText : ""}>
    <label className="uppercase text-md whitespace-nowrap" htmlFor={id}>{label}
      {(isRequired  && !showError) ? <span className={`${requiredTextClasses}`}>(required)</span> : null}
    </label>
    {showError ? <span className="text-red-700 pl-2 text-xs normal-case">{errorMessage}</span> : null}
    <input
      onChange={(e) => {
         setValue(e.target.value);
         handleChange && handleChange(e.target.value);
      }}
      autoComplete="gobbledegook"
      className={`${baseClasses} leading-8 ${ disabledClasses} ${errorClasses}`}
      type={type ? type : "text"}
      step={step ? step : undefined}
      min={min ? min : undefined}
      max={max ? max : undefined}
      maxLength={maxLength ? maxLength : undefined}
      id={id}
      onBlur={(e) => {
        handleChange && handleChange(e.target.value);
        handleSave && handleSave(e.target.id, e.target.value);
      }}
      placeholder={placeholder ?? ""}
      value={value}
      disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
    />
  </div>;
});

Input.displayName = "Input";
export default Input;