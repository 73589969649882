import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import { Fragment, useMemo, useState } from "react";
import { TestTypePill } from "../../../components/TestTypePill";
import { type AccessCode } from "../../../services/gen/managerApi";
import OnlineTestIcon from "../shared/icon/OnlineTestIcon";
import PaperTestIcon from "../shared/icon/PaperTestIcon";
import { accessCodeInstructions } from "./content";
import { getDateRangeString } from "./util";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function RegistrationLineItem({ codeInfo }: { codeInfo: AccessCode }) {
  const
    [showDialog, setShowDialog] = useState(false),
    dateRangeString = useMemo(() => getDateRangeString(codeInfo), [codeInfo]),
    hideAccessCode = useMemo(() => dayjs.utc().isBefore(dayjs.utc(codeInfo.activeStart).subtract(2, "weeks")), [codeInfo]);

  return (
    <div className="rounded-extra-rounded bg-clt-light-gray">
      <div className="flex flex-row justify-between items-center p-6 whitespace-nowrap">

        <div className="flex flex-row items-center space-x-3">
          <TestTypePill testType={codeInfo.testType} />
          <p className="text-text-primary text-sm font-bold">Tests Ordered: {codeInfo.registeredCount}</p>
          <div className="md:pl-8 lg:pl-16 flex flex-row items-center space-x-2 font-bold text-sm text-text-secondary">
            {codeInfo.mode === "ONLINE"
              ? (
                <>
                  <OnlineTestIcon />
                  <span>Online Test</span>
                </>
              )
              : (
                <>
                  <PaperTestIcon />
                  <span>Paper Test</span>
                </>
              )}
          </div>
        </div>

        <button
          className="flex flex-row items-center space-x-1 text-sm text-text-secondary font-btn font-bold uppercase px-3 py-1.5 rounded-rounded select-none hover:bg-clt-medium-gray hover:cursor-pointer transition duration-100"
          onClick={() => setShowDialog(true)}
        >
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4389 3.68191C12.0223 1.4963 9.60383 0.167013 6.99939 0.142578C4.39497 0.167013 1.97647 1.4963 0.559892 3.68191C-0.186631 4.77747 -0.186631 6.21829 0.559892 7.31389C1.97567 9.50085 4.39431 10.8315 6.99941 10.8567C9.60383 10.8323 12.0223 9.50302 13.4389 7.31741C14.187 6.22102 14.187 4.77827 13.4389 3.68191ZM11.9894 6.32057C10.9079 8.036 9.02726 9.08259 6.99939 9.09763C4.97155 9.08262 3.09092 8.036 2.00937 6.32057C1.6722 5.82522 1.6722 5.17407 2.00937 4.67874C3.09089 2.96331 4.97152 1.91672 6.99939 1.90169C9.02723 1.91669 10.9079 2.96331 11.9894 4.67874C12.3266 5.17407 12.3266 5.82522 11.9894 6.32057Z" fill="currentColor"/>
            <path d="M6.99928 7.84524C8.29465 7.84524 9.34475 6.79513 9.34475 5.49977C9.34475 4.2044 8.29465 3.1543 6.99928 3.1543C5.70391 3.1543 4.65381 4.2044 4.65381 5.49977C4.65381 6.79513 5.70391 7.84524 6.99928 7.84524Z" fill="currentColor"/>
          </svg>

          <span>Access Code</span>
        </button>

      </div>

      <Transition show={showDialog} as={Fragment}>
        <Dialog
          className="relative z-[60]"
          onClose={() => setShowDialog(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Backdrop className="fixed z-[60] inset-0 bg-clt-medium-gray/80" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
              <Dialog.Panel className="p-8 w-116 rounded-extra-rounded bg-clt-white shadow-lg transform">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <TestTypePill testType={codeInfo.testType} />  
                    <span className="text-sm font-bold text-text-secondary">{dateRangeString}</span>
                  </div>
                  <button
                    className="p-3 rounded-full text-text-secondary bg-clt-light-gray hover:bg-clt-medium-gray hover:cursor-pointer"
                    onClick={() => setShowDialog(false)}
                  >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M3.58572 5.00042L1.11076 2.52546C0.898627 2.31333 0.898627 2.03049 1.11076 1.81835L1.81787 1.11125C2.03 0.899115 2.31284 0.899115 2.52497 1.11125L4.99993 3.58621L7.47474 1.1114C7.68688 0.899266 7.96972 0.899266 8.18185 1.1114L8.88896 1.8185C9.10109 2.03064 9.10109 2.31348 8.88896 2.52561L6.41415 5.00042L8.88893 7.47521C9.10107 7.68734 9.10107 7.97018 8.88893 8.18231L8.18183 8.88942C7.9697 9.10155 7.68685 9.10155 7.47472 8.88942L4.99993 6.41463L2.525 8.88957C2.31286 9.1017 2.03002 9.1017 1.81789 8.88957L1.11078 8.18247C0.89865 7.97033 0.89865 7.68749 1.11078 7.47536L3.58572 5.00042Z" fill="currentColor"/>
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col gap-y-2 my-3">
                  <Dialog.Title className="text-lg font-bold text-text-primary">
                    Access Code
                  </Dialog.Title>
                  {!hideAccessCode && (
                    <Dialog.Description
                      className="p-2 w-full bg-clt-light-gray text-text-secondary font-btn font-bold rounded-rounded border border-dashed border-clt-medium-gray text-center"
                    >
                      {codeInfo.accessCode}
                    </Dialog.Description>
                  )}
                </div>
                {accessCodeInstructions[hideAccessCode ? "HIDDEN" : codeInfo.mode]}
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
}
