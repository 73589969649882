import { AdditionalResourceInfo } from "./content";

export default function AdditionalResource({ title, linkUrl, resourceType }: AdditionalResourceInfo) {
  return (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className="flex items-center justify-between group m-2 p-3 rounded-extra-rounded transition hover:bg-clt-white hover:shadow-lg focus:bg-clt-white focus:shadow-lg outline-none focus:ring ring-offset-1"
    >
      <div className="flex items-center space-x-2">
        <div className="p-4 rounded-rounded bg-clt-medium-gray text-clt-collegiate-blue transition group-hover:bg-clt-light-collegiate-blue group-hover:text-clt-white group-focus-within:bg-clt-light-collegiate-blue group-focus-within:text-clt-white">
          {resourceType === "DOCUMENT"
            ? <svg width="16" height="16" viewBox="-1 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 8.33334C10.3335 8.59856 10.2281 8.85291 10.0406 9.04045C9.85306 9.22798 9.5987 9.33334 9.33349 9.33334H4.66683C4.40161 9.33334 4.14726 9.22798 3.95972 9.04045C3.77218 8.85291 3.66683 8.59856 3.66683 8.33334C3.66683 8.06812 3.77218 7.81377 3.95972 7.62623C4.14726 7.4387 4.40161 7.33334 4.66683 7.33334H9.33349C9.5987 7.33334 9.85306 7.4387 10.0406 7.62623C10.2281 7.81377 10.3335 8.06812 10.3335 8.33334ZM7.33349 10.6667H4.66683C4.40161 10.6667 4.14726 10.772 3.95972 10.9596C3.77218 11.1471 3.66683 11.4015 3.66683 11.6667C3.66683 11.9319 3.77218 12.1862 3.95972 12.3738C4.14726 12.5613 4.40161 12.6667 4.66683 12.6667H7.33349C7.59871 12.6667 7.85306 12.5613 8.0406 12.3738C8.22813 12.1862 8.33349 11.9319 8.33349 11.6667C8.33349 11.4015 8.22813 11.1471 8.0406 10.9596C7.85306 10.772 7.59871 10.6667 7.33349 10.6667ZM13.6668 5.43801V12.3333C13.6658 13.3055 13.2791 14.2375 12.5917 14.9249C11.9043 15.6123 10.9723 15.9989 10.0002 16H4.00016C3.02802 15.9989 2.09601 15.6123 1.40861 14.9249C0.721203 14.2375 0.334555 13.3055 0.333496 12.3333V3.66668C0.334555 2.69454 0.721203 1.76253 1.40861 1.07512C2.09601 0.387722 3.02802 0.00107359 4.00016 1.48073e-05H8.22882C8.71054 -0.00135889 9.18773 0.0928612 9.63277 0.277217C10.0778 0.461574 10.4819 0.732402 10.8215 1.07401L12.5928 2.84535C12.9344 3.18502 13.2052 3.58907 13.3895 4.0341C13.5739 4.47913 13.6681 4.95631 13.6668 5.43801ZM11.6668 12.3333V6.00001H9.00015C8.64653 6.00001 8.30739 5.85953 8.05735 5.60948C7.8073 5.35944 7.66682 5.0203 7.66682 4.66668V2.00001H4.00016C3.55813 2.00001 3.13421 2.17561 2.82165 2.48817C2.50909 2.80073 2.33349 3.22465 2.33349 3.66668V12.3333C2.33349 12.7754 2.50909 13.1993 2.82165 13.5118C3.13421 13.8244 3.55813 14 4.00016 14H10.0002C10.4422 14 10.8661 13.8244 11.1787 13.5118C11.4912 13.1993 11.6668 12.7754 11.6668 12.3333Z" fill="currentColor"/>
            </svg>
            : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.93955 13.0572C5.88085 14.155 4.15672 14.2563 2.97682 13.2899C2.27412 12.6815 1.91518 11.7667 2.01664 10.8428C2.1045 10.1616 2.42287 9.53098 2.91881 9.0558L4.8365 7.13678C5.22695 6.74621 5.22695 6.1131 4.8365 5.72252C4.44593 5.33208 3.81282 5.33208 3.42224 5.72252L1.54524 7.60021C0.689643 8.42527 0.150074 9.52395 0.020269 10.7054C-0.222182 13.3665 1.73846 15.7202 4.39952 15.9627C5.82856 16.0929 7.2417 15.582 8.25711 14.5681L10.2628 12.5631C10.6533 12.1725 10.6533 11.5394 10.2628 11.1488C9.87225 10.7584 9.23913 10.7584 8.84856 11.1488L6.93955 13.0572Z" fill="currentColor"/>
              <path d="M14.1075 0.977645C12.1163 -0.485313 9.35123 -0.255958 7.62828 1.51509L5.74059 3.40344C5.35014 3.79401 5.35014 4.42713 5.74059 4.8177C6.13116 5.20815 6.76428 5.20815 7.15485 4.8177L9.07255 2.902C10.1081 1.83096 11.7836 1.71031 12.9619 2.62195C14.1747 3.61645 14.3517 5.40581 13.3573 6.61863C13.2991 6.68961 13.2374 6.75772 13.1726 6.82273L11.1669 8.82844C10.7765 9.21902 10.7765 9.85213 11.1669 10.2427C11.5575 10.6332 12.1906 10.6332 12.5812 10.2427L14.5869 8.23699C16.4751 6.3427 16.4703 3.27635 14.576 1.3881C14.4288 1.24138 14.2723 1.10429 14.1075 0.977645Z" fill="currentColor"/>
              <path d="M5.74071 8.83025L8.84831 5.72264C9.23892 5.33204 9.87219 5.33204 10.2628 5.72264C10.6534 6.11325 10.6534 6.74652 10.2628 7.13712L7.15519 10.2447C6.76458 10.6353 6.13131 10.6353 5.74071 10.2447C5.3501 9.85413 5.3501 9.22086 5.74071 8.83025Z" fill="currentColor"/>
            </svg>
          }
        </div>
        <h3 className="text-sm font-bold">{title}</h3>
      </div>
      <div className="opacity-0 p-3 mr-1.5 rounded-rounded transition group-hover:opacity-100 group-focus-within:opacity-100 bg-clt-light-gray text-clt-collegiate-blue">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.0817 9.03472L11.0817 3.02165C11.0688 2.46987 10.8438 1.94426 10.4536 1.55398C10.0633 1.1637 9.53766 0.938745 8.98588 0.925837L2.9728 0.925836C2.73458 0.92578 2.50609 1.02036 2.33759 1.18877C2.1691 1.35719 2.07441 1.58563 2.07436 1.82386C2.0743 2.06209 2.16888 2.29058 2.33729 2.45907C2.50571 2.62756 2.73416 2.72225 2.97238 2.72231L8.02138 2.72273L0.567182 10.1964C0.398744 10.3648 0.304117 10.5933 0.304117 10.8315C0.304117 11.0697 0.398744 11.2982 0.567182 11.4666C0.73562 11.635 0.964072 11.7297 1.20228 11.7297C1.44049 11.7297 1.66894 11.635 1.83737 11.4666L9.28352 4.00097L9.28522 9.03515C9.28931 9.27061 9.38572 9.49505 9.55369 9.66013C9.72166 9.8252 9.94774 9.9177 10.1832 9.9177C10.4187 9.9177 10.6448 9.8252 10.8128 9.66013C10.9808 9.49506 11.0776 9.27019 11.0817 9.03472Z" fill="currentColor"/>
        </svg>
      </div>
    </a>
  );
}
