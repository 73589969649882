import {
  type AnswerType,
  enhancedApi,
  type Section,
} from "./gen/studentApi";

export const updateCachedStudentAnswer = (
  sectionNumber: number,
  questionId: number,
  answer: AnswerType,
) => enhancedApi.util.updateQueryData("studentApiGetSection", { sectionNumber }, (draft: Section) => {
  if (draft.questions) {
    const index = draft.questions.findIndex(item => item.id === questionId);
    if (index > -1) draft.questions[index].studentAnswer = answer;
  }
});
