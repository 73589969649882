import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import Select, { SelectOption } from "../../components/Select";
import {
  type AuthorizeApiPostAccessCodeApiResponse, enhancedApi,
  useAuthorizeApiAuthorizeTakerMutation, useAuthorizeApiPostAccessCodeMutation
} from "../../services/gen/authorize";
import dayjs from "dayjs";
import { classNames, getOrdinal } from "../../shared/utils";
import { useStudentApiPostRegisterMutation } from "../../services/gen/studentApi";
import { useNavigate } from "react-router-dom";
import { Header } from "./Header";
import Modal from "../../components/Modal";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useKeyboardEvent } from "../../KeyboardProvider";
import welcomeBackground from "../../assets/welcome_background.png";
import pretestBackground from "../../assets/pretest_background.png";
import LoadingOverlay from "../../components/LoadingOverlay";
import FontFaceObserver from "fontfaceobserver";

interface UserInputs {
  accessCode: string,
  firstName: string,
  middleInitial: string,
  lastName: string,
  birthMonthZeroBased: string,
  birthDay: string,
  age: string,
}

interface PreTestQuestion {
  index: number,
  heading: string,
  subheading?: string,
  placeholder?: string,
  key: keyof UserInputs,
  options?: SelectOption[],
  validator: (val: string) => boolean,
  apiCall?: () => Promise<unknown>,
  maxLength?: number,
  type?: string,
  min?: number,
  max?: number,
  bypassOptionText?: string,
  bypassed?: boolean,
  handleBypassedChange?: () => void,
}

const monthLengths: { [k: string]: number } = {
    "0": 31, "1": 29, "2": 31, "3": 30, "4": 31, "5": 30,
    "6": 31, "7": 31, "8": 30, "9": 31, "10": 30, "11": 31
  },
  generateDayOfMonthOptions = (upTo: number): SelectOption[] => Array.from({ length: upTo }, (_, i) => (i + 1).toString()).map(day => ({
    value: day,
    label: day
  }));

export function Begin() {
  const navigate = useNavigate(),
    [showErrorModal, setShowErrorModal] = useState(false),
    [headerInfo, setHeaderInfo] = useState<AuthorizeApiPostAccessCodeApiResponse>(),
    [postAccessCode, { isError: postAccessCodeError, error: postAccessCodeErrorObject }] = useAuthorizeApiPostAccessCodeMutation(),
    [authorizeTaker, { isError: authorizeTakerError }] = useAuthorizeApiAuthorizeTakerMutation(),
    [postRegister, { isError: postRegisterError }] = useStudentApiPostRegisterMutation(),
    [triggerAuthorizeLogout ] = enhancedApi.endpoints.authorizeApiLogout.useLazyQuery(),
    [fontsLoaded, setFontsLoaded] = useState(false),
    [backgroundLoaded, setBackgroundLoaded] = useState(false),
    [userInputs, setUserInputs] = useState<UserInputs>({
      accessCode: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      birthMonthZeroBased: "",
      birthDay: "",
      age: "",
    }),
    [bypassMiddleName, setBypassMiddleName] = useState(false),
    toggleBypassMiddleName = () => {
      setBypassMiddleName(oldVal => {
        const newVal = !oldVal;
        if (newVal) {
          updateUserInputValues("middleInitial", "");
        }
        return newVal;
      });
    },
    postAccessCodeAndStoreHeaderInfo = async () => {
        const result = await postAccessCode({ body: { accessCode: userInputs["accessCode"] } }).unwrap();
        if (result) {
          setHeaderInfo(result);
        }
    },
    postProfileInfoAndRegister = async () => {
      try {
        await authorizeTaker({
          body: {
            firstName: userInputs.firstName.trim(),
            middleInitial: userInputs.middleInitial,
            lastName: userInputs.lastName.trim(),
            birthMonthZeroBased: Number(userInputs.birthMonthZeroBased),
            birthDay: Number(userInputs.birthDay),
            age: Number(userInputs.age)
          }
        }).unwrap();
        await postRegister().unwrap();
        // TODO This should go in a useEffect
        navigate("/exam/welcome", { replace: true });
      } catch (e) {
        console.error("post profile / register error", e);
        setShowErrorModal(true);
      }
    },
    questionContent: PreTestQuestion[] = [
      {
        index: 0,
        heading: "Access Code",
        subheading: "Enter your access code to start or continue your test.",
        placeholder: "Type your access code here.",
        key: "accessCode",
        validator: (val) => val.length >= 4 && /^[A-Za-z0-9]+$/.test(val),
        apiCall: postAccessCodeAndStoreHeaderInfo
      },
      {
        index: 1,
        heading: "First Name",
        placeholder: "Type in your first name.",
        key: "firstName",
        validator: (val: string) => val.length >= 2 && /^[\p{L} ()'-]+$/u.test(val),
      },
      {
        index: 2,
        heading: "Middle Initial",
        placeholder: "Type in the first letter of your middle name.",
        maxLength: 1,
        key: "middleInitial",
        validator: (val) => (bypassMiddleName ? /^$/ : /^[A-Za-z]$/).test(val),
        bypassOptionText: "I do not have a middle name.",
        bypassed: bypassMiddleName,
        handleBypassedChange: toggleBypassMiddleName,
      },
      {
        index: 3,
        heading: "Last Name",
        placeholder: "Type in your last name.",
        key: "lastName",
        validator: (val: string) => val.length >= 2 && /^[\p{L} ()'-]+$/u.test(val),
      },
      {
        index: 4,
        heading: "In which month were you born?",
        key: "birthMonthZeroBased",
        options: [
          { value: "", label: "Please select your month of birth." },
          { value: "0", label: "January" },
          { value: "1", label: "February" },
          { value: "2", label: "March" },
          { value: "3", label: "April" },
          { value: "4", label: "May" },
          { value: "5", label: "June" },
          { value: "6", label: "July" },
          { value: "7", label: "August" },
          { value: "8", label: "September" },
          { value: "9", label: "October" },
          { value: "10", label: "November" },
          { value: "11", label: "December" },
        ],
        validator: (val) => Boolean(val),
      },
      {
        index: 5,
        heading: "On what day were you born?",
        placeholder: "Type in your birth day.",
        key: "birthDay",
        options: [
          { value: "", label: "Please select your day of birth." },
          ...generateDayOfMonthOptions(monthLengths[userInputs.birthMonthZeroBased ?? "7"])
        ],
        validator: (val) => Boolean(val),
      },
      {
        index: 6,
        type: "number",
        min: 3,
        max: 120,
        heading: "How old are you?",
        placeholder: "Type in your age.",
        key: "age",
        maxLength: 3,
        validator: (val: string) => 3 <= Number(val) && Number(val) < 100
      }
    ],
    [questionIndex, setQuestionIndex] = useState(0),
    currentQuestion: PreTestQuestion = questionContent[questionIndex],
    lastQuestionIndex = questionContent.length - 1,
    currentQuestionKey = questionContent[questionIndex].key,
    currentQuestionValue = userInputs[currentQuestionKey],
    [errorMessage, setErrorMessage] = useState<string>(""),
    isCurrentQuestionAnswerValid = currentQuestion.validator(currentQuestionValue),
    inputRefs = questionContent.map(() => useRef<HTMLInputElement>(null)),
    [showVerify, setShowVerify] = useState(false),
    cardBorderClasses = "border-t-8 border-clt",
    getArrowButton = (direction: "left" | "right", onClick?: () => void, isEnabled = true, buttonText = "") => {
      const ArrowIcon = direction === "left" ? ArrowLeftIcon : ArrowRightIcon,
        disabledClasses = isEnabled ? "" : "opacity-50 cursor-not-allowed";
      return <button
        className={`bg-surface-tertiary h-12 text-text-secondary mt-4 py-2 px-4 rounded-minimal text-sm font-medium group border-transparent hover:bg-surface-brand hover:text-text-inverted ${disabledClasses}`}
        onClick={() => isEnabled && onClick && onClick()}>
        {buttonText ? buttonText : <ArrowIcon className="h-7 w-7 group-hover:text-white"/>}
      </button>;
    },
    changeQuestion = async (direction: "left" | "right") => {
      const boundNumber = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max),
        shiftedIndex = direction === "left" ? questionIndex - 1 : questionIndex + 1,
        newIndex = boundNumber(shiftedIndex, 0, lastQuestionIndex);

      currentQuestion.apiCall && direction === "right" && await currentQuestion.apiCall();

      if (direction === "left" && showVerify) {
        setShowVerify(false);
      } else if (questionIndex !== newIndex) {
        setQuestionIndex(newIndex);
      } else if (questionIndex === lastQuestionIndex && !showVerify) {
          setShowVerify(true);
      }

    },

    updateUserInputValues = (key: keyof UserInputs, value: string) => {
      setUserInputs({
        ...userInputs,
        [key]: value
      });
    },
    pretestQuestionCardJsx = (
      <div className="h-1/2 w-3/4 max-w-3xl">
        <div className={`flex flex-col min-h-[17rem] bg-white p-8 shadow-lg w-full ${cardBorderClasses}`}>
          <div className="pb-3">
            <span className="text-xl text-text-secondary">{currentQuestion.heading}</span>
            <span className="ml-4 text-lg text-red-500">{errorMessage}</span>
            <div className="mt-2 text-lg text-gray-500">{currentQuestion.subheading}</div>
          </div>

          <div className="max-h-[2rem]">
          {
            currentQuestion.options
              ?
                <Select
                  key={`select-${currentQuestion.key}`}
                  selectOptions={currentQuestion.options}
                  onChange={(newVal: string) => updateUserInputValues(currentQuestionKey, newVal)}
                  value={userInputs[currentQuestionKey]}
                />
              : <input
                type={currentQuestion.type ?? "text"}
                autoFocus={true}
                disabled={currentQuestion.bypassed ?? false}
                ref={inputRefs[questionIndex]}
                placeholder={currentQuestion.placeholder}
                value={userInputs[currentQuestionKey] ?? ""}
                maxLength={currentQuestion.maxLength ?? undefined}
                onChange={(event) => updateUserInputValues(currentQuestion.key, event.target.value)}
                className={classNames(
                  "mt-1 block w-full p-3 bg-slate-100 border border-gray-600 placeholder-gray-400 shadow-sm focus:ring-0 focus:border-gray-900",
                  currentQuestion.bypassed ? "opacity-50 cursor-not-allowed" : ""
                )}
                min={currentQuestion.min ?? undefined}
                max={currentQuestion.max ?? undefined}
              />
          }
          </div>

          {currentQuestion.bypassOptionText && currentQuestion?.handleBypassedChange &&
            <label className="mt-8 flex items-center space-x-2 max-w-fit">
              <input type="checkbox" className="text-clt" checked={currentQuestion.bypassed} onChange={currentQuestion.handleBypassedChange} /> 
              <span className="text-sm text-text-secondary">{currentQuestion.bypassOptionText}</span>
            </label>
          }

          <div className="flex justify-between mt-auto">
            {questionIndex > 1
              ? getArrowButton("left", () => changeQuestion("left"))
              : <div/>}
            {questionIndex <= lastQuestionIndex
              ? getArrowButton("right", () => changeQuestion("right"), isCurrentQuestionAnswerValid)
              : <div/>
            }
          </div>
        </div>
      </div>),
    getDisplayName = () => userInputs.firstName.trim() + (userInputs.middleInitial ? ` ${userInputs.middleInitial}. ` : " ") + userInputs.lastName.trim(),
    getVerifyCardJsx = () => {
      const getTableItem = (label: string, value: string) => (
        <div className="flex justify-between mb-1">
          <div className="w-1/2 ml-8 text-left text-gray-500">{label}</div>
          <div className="w-1/2 mr-8 text-left">{value}</div>
        </div>);

      return <>
        <div className="h-1/2 w-3/4 max-w-3xl">
          <div className={`flex flex-col min-h-[17rem] bg-white p-8 shadow-lg w-full ${cardBorderClasses}`}>
            <div className="pb-3">
              <div className="text-xl text-text-secondary">Is this correct?</div>
              <div className="text-lg text-gray-500">It is important that this information is right! You will not be able to edit this later. </div>
            </div>

            <div className="mt-4 bg-clt-light-gray p-6">
              {getTableItem("Name", getDisplayName())}
              {getTableItem("Birthday", `${dayjs().month(Number(userInputs.birthMonthZeroBased)).format("MMMM")} ${getOrdinal(Number(userInputs.birthDay))}`)}
              {getTableItem("Age", userInputs.age)}
            </div>
            <div className="flex justify-between items-center mt-4">
              {getArrowButton("left", () => setShowVerify(false), undefined, "No, go back")}
              <button
                className="mt-4 h-12 bg-cyan-900 text-white py-2 px-4 rounded-md shadow-sm text-sm font-medium group border border-transparent hover:border-black"
                onClick={() => postProfileInfoAndRegister()}>
                <span>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </>;
    };

  useEffect(() => {
    const fontObserver = new FontFaceObserver("Poppins"),
      fontObserver2 = new FontFaceObserver("Lora"),
      fontObserver3 = new FontFaceObserver("Assistant"),
      checkFont = async () => {
        await fontObserver.load();
        setFontsLoaded(true);
      };

    checkFont();

    // We don't need these immediately.  We are pre-cacheing for the user.
    fontObserver2.load();
    fontObserver3.load();
  }, []);

  useEffect(() => {
    // clear the cookie session
    triggerAuthorizeLogout();
  }, []);

  useEffect(() => {
    const input = inputRefs[questionIndex].current;
    input && input.focus();
  });

  useEffect(() => {
    const img1 = new Image(),
      img2 = new Image();

    img1.src = pretestBackground;
    img1.onload = () => setBackgroundLoaded(true);
    img2.src = welcomeBackground;
  }, []);

  useKeyboardEvent(["Enter", "ArrowRight"], () => {
      if (showVerify) {
        postProfileInfoAndRegister();
        return;
      }
      if (isCurrentQuestionAnswerValid) {
        changeQuestion("right");
      }
    });

  useKeyboardEvent(["ArrowLeft"], () => {
    changeQuestion("left");
  });

  if (postAccessCodeError && ((postAccessCodeErrorObject as FetchBaseQueryError).status === 404)) {
    if (errorMessage === "") setErrorMessage("Invalid access code.");
  } else if (postAccessCodeError || authorizeTakerError || postRegisterError) {
    console.error("Error in postAccessCode, authorizeTaker, or postRegister", postAccessCodeError, authorizeTakerError, postRegisterError);
    if (!showErrorModal) setShowErrorModal(true);
  } else {
    if (errorMessage !== "") setErrorMessage("");
  }

  if (!(fontsLoaded && backgroundLoaded)) {
    return (<div className="w-full h-full overflow-hidden select-none font-exam bg-white">
          <LoadingOverlay color="blue"/>
      </div>);
  }

  return <>
    <div className="w-full h-full overflow-hidden select-none font-exam"
      style={{
        backgroundImage: `url(${pretestBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      {headerInfo?.testTitle
        ? <Header testType={headerInfo.testTitle} />
        : <div className="h-20 opacity-100"/>}
      <div
           className="w-full h-full flex justify-center items-center">
        {showVerify
          ? getVerifyCardJsx()
          : pretestQuestionCardJsx }
      </div>
      { showErrorModal
        ? <Modal heading="Something went wrong!" subheading="Click OK to refresh and try again." modalType="ERROR" setShowModal={setShowErrorModal} onOK={() => { navigate(0); }}/>
        : null
      }
    </div>
  </>;
}
