import { TestTypePill } from "../../../components/TestTypePill";
import type { SampleTestPdfInfo } from "./content";

export default function SampleTest({ testType, linkUrl }: SampleTestPdfInfo) {
  return (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className="flex items-center justify-between group m-2 p-3 rounded-extra-rounded transition hover:bg-clt-white hover:shadow-lg focus:bg-clt-white focus:shadow-lg outline-none focus:ring ring-offset-1"
    >
      <span className="sr-only">{testType} sample PDF</span>
      <div className="flex items-center space-x-2">
        <TestTypePill testType={testType} />
        <h3 className="text-sm font-bold">Sample PDF</h3>
      </div>
      <div className="opacity-0 p-3 rounded-rounded transition group-hover:opacity-100 group-focus-within:opacity-100 bg-clt-light-gray text-clt-collegiate-blue">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.0817 9.03472L11.0817 3.02165C11.0688 2.46987 10.8438 1.94426 10.4536 1.55398C10.0633 1.1637 9.53766 0.938745 8.98588 0.925837L2.9728 0.925836C2.73458 0.92578 2.50609 1.02036 2.33759 1.18877C2.1691 1.35719 2.07441 1.58563 2.07436 1.82386C2.0743 2.06209 2.16888 2.29058 2.33729 2.45907C2.50571 2.62756 2.73416 2.72225 2.97238 2.72231L8.02138 2.72273L0.567182 10.1964C0.398744 10.3648 0.304117 10.5933 0.304117 10.8315C0.304117 11.0697 0.398744 11.2982 0.567182 11.4666C0.73562 11.635 0.964072 11.7297 1.20228 11.7297C1.44049 11.7297 1.66894 11.635 1.83737 11.4666L9.28352 4.00097L9.28522 9.03515C9.28931 9.27061 9.38572 9.49505 9.55369 9.66013C9.72166 9.8252 9.94774 9.9177 10.1832 9.9177C10.4187 9.9177 10.6448 9.8252 10.8128 9.66013C10.9808 9.49506 11.0776 9.27019 11.0817 9.03472Z" fill="currentColor"/>
        </svg>
      </div>
    </a>
  );
}
