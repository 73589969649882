import ResourceCard from "./Resources/ResourceCard";
import { additionalResourceGroups, onlineResourceCards, paperResourceCards, sampleTests } from "./Resources/content";
import additionalInfoBackground from "../../assets/resources_birds.png";
import OnlineTestIcon from "./shared/icon/OnlineTestIcon";
import PaperTestIcon from "./shared/icon/PaperTestIcon";
import AdditionalResource from "./Resources/AdditionalResource";
import SampleTest from "./Resources/SampleTest";

export default function Resources() {
  return (
    <div className="w-full">
      <header className="top-0 sticky z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray">
        <h1 className="text-xl">Test Resources</h1>
      </header>
      <main className="m-8 xl:m-16 inset-x-0">
        <div className="inset-0 space-y-3">
          <div>
            <div className="flex items-center space-x-2">
              <OnlineTestIcon />
              <h3 className="text-text-primary font-semibold">For Online Testers</h3>
            </div>
            <div className="flex flex-wrap mt-1.5">
              {onlineResourceCards.map(info => <ResourceCard key={info.title} {...info} />)}
            </div>
          </div>

          <div>
            <div className="flex items-center space-x-2">
              <PaperTestIcon />
              <h3 className="text-text-primary font-semibold">For Paper Testers</h3>
            </div>
            <div className="flex flex-wrap mt-1.5">
              {paperResourceCards.map(info => <ResourceCard key={info.title} {...info} />)}
            </div>
          </div>

          <div>
            <h3 className="text-text-primary font-semibold">Additional Resources</h3>
            <hr className="h-[1px] mt-2 border-none bg-clt-medium-gray" />
            <div className="grid sm:grid-cols-2 lg:grid-cols-3">
              {additionalResourceGroups.map((group, index) => (
                <div key={index} className="flex-1">
                  {group.resources.map(info => (
                    <AdditionalResource key={info.title} {...info} />
                  ))}
                </div>
              ))}              
            </div>
          </div>

          <div>
            <h3 className="text-text-primary font-semibold">Sample Tests</h3>
            <hr className="h-[1px] mt-2 border-none bg-clt-medium-gray" />
            <div className="grid sm:grid-cols-2 lg:grid-cols-3">
              {sampleTests.map(info => <SampleTest key={info.testType} {...info} />)}
            </div>
          </div>

          <div
            className="inset-x-0 rounded-extra-rounded"
            style={{
              backgroundImage: `url(${additionalInfoBackground})`,
              backgroundSize: "110%",
              backgroundPosition: "-30% 50%",
            }}
          >
            <div className="inset-0 text-center bg-gradient-to-r from-clt-medium-collegiate-blue from-25% to-clt-medium-collegiate-blue/75 text-text-inverted rounded-extra-rounded shadow-md">
              <div className="p-8">
                <h2 className="text-xl font-serif">Can’t find what you are looking for?</h2>
                <div className="flex justify-center space-x-2 mt-4">
                  <a
                    href="https://www.cltexam.com/tests/clt3-8/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-btn whitespace-nowrap text-sm text-text-primary inline-block uppercase rounded-rounded justify-center py-2 px-3 bg-clt-white hover:bg-clt-medium-gray"
                  >
                    View our Website
                  </a>
                  <a
                    href="https://www.cltexam.com/contact/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-btn whitespace-nowrap text-sm inline-block uppercase rounded-rounded justify-center py-2 px-3 bg-clt-collegiate-blue hover:bg-clt-light-collegiate-blue"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
