import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ListItem, Profile } from "../shared/types";
import { RootState } from "../app/store";
import { JSONSchemaType } from "ajv";

export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_CLT2_BASE_URL}/`,
        prepareHeaders(headers, { getState }) {
            const token = (getState() as RootState).token.value;
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSchema: builder.query<{ schema: JSONSchemaType<unknown>, errorMessages: { [k: string]: string } }, void>({
            query: () => "profile/schema",
        }),
        getProfileForId: builder.query<{ profile: Profile }, string>({
            query: (idUser ) => `profile/${idUser}`,
        }),
        getCeebCountries: builder.query<{ countries: string[] }, void>({
            query: () => "profile/ceeb/co",
        }),
        getCeebStates: builder.query<{ states: string[] }, string>({
            query: (co) => `profile/ceeb/${co}/state`,
        }),
        getCeebCities: builder.query<{ cities: string[] }, { co: string, state: string }>({
            query: (qargs: { co: string, state: string }) => `profile/ceeb/${qargs.co}/${qargs.state}`,
        }),
        getCeebSchools: builder.query<{ schools: { schoolName: string, ceebCode: string }[] }, { co: string, state: string, city: string }>({
            query: (qargs: { co: string, state: string, city: string }) => `profile/ceeb/${qargs.co}/${qargs.state}/${qargs.city}`,
        }),
        getCollegeSearch: builder.query<ListItem[], string>({
            query: (queryStr) => `colleges/search?q=${queryStr}`,
            transformResponse(baseQueryReturnValue: Required<ListItem>[]) {
                return baseQueryReturnValue.map((college: Required<ListItem>) => ({ id: college.id, name: college.name }));
            }
        }),
        updateProfile: builder.mutation<Profile, Partial<Profile>  & Pick<Profile, "idUser">>({
            query: ({ idUser, ...patch }) => ({ url: `profile/${idUser}`, method: "PATCH", body: patch }),
            async onQueryStarted({ idUser, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                  profileApi.util.updateQueryData("getProfileForId", String(idUser), (draft) => {
                      Object.assign(draft.profile, patch);
                  })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        })
    }),
});

export const {
    useGetProfileForIdQuery,
    useGetSchemaQuery,
    useGetCeebCountriesQuery,
    useGetCeebStatesQuery,
    useGetCeebCitiesQuery,
    useGetCeebSchoolsQuery,
    useGetCollegeSearchQuery,
    useUpdateProfileMutation
} = profileApi;
