import { classNames } from "../../../../shared/utils";

type DomainCardProps = {
  name: string;
  percentCorrect: number;
  category: "VR" | "QR";
  isTopDomains: boolean;
  className?: string;
}

const bad = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="2" fill="#F04438"/>
    <circle cx="10" cy="10" r="4.5" stroke="white"/>
    <path d="M10 8V10.5453" stroke="white" strokeLinecap="round"/>
    <path d="M10 12V12.0173" stroke="white" strokeLinecap="round"/>
  </svg>,
  good = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="2" fill="#6DAA20"/>
    <path d="M5.45392 10.9682L7.48548 12.9998C7.68074 13.1951 7.99732 13.1951 8.19258 12.9998L14.5461 6.64648" stroke="white" strokeLinecap="round"/>
  </svg>;


export default function DomainCard({ name, percentCorrect, category, isTopDomains, className = "", }: DomainCardProps) {
  const borderColor = category === "VR" ? "border-clt-primary-teal" : "border-clt-primary-orange";
  return (
    <div
      className={classNames(
        `bg-surface-secondary py-10 px-8 rounded-2xl flex flex-wrap justify-between items-center w-full ${className} border-l-8 ${borderColor}`,
        "print:border-y-2 print:border-r-2 print:border-y-surface-secondary print:border-r-surface-secondary",
      )}>
      <div className="font-bold text-xl whitespace-nowrap print:max-w-[60%] print:text-base print:whitespace-normal">{name}</div>
      <div className="flex items-center print:text-sm">Correct:&nbsp;&nbsp;{percentCorrect}% <div className="pl-4 print:pl-2"> {isTopDomains ? good : bad} </div></div>
    </div>
  );
}
