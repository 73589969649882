import React, { useMemo } from "react";
import {
  Bar,
  ComposedChart,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { classNames, KeyOfType } from "../../../../shared/utils";
import type { AdministrationDataPoint } from "../CohortGrowth";

interface SectionScoreBarGraphProps {
  classification: "VR" | "QR";
  source: AdministrationDataPoint[];
}

type DataKey<T> = KeyOfType<AdministrationDataPoint, T>;

const
  CustomTooltip: React.FC<TooltipProps<number, string> & { classification: "VR" | "QR" }> = ({ active, payload, classification }) => {
    if (active && payload && payload.length) {
      return (
        <div className="shadow-md bg-white text-text-primary p-2 border-2 border-clt-medium-gray rounded-rounded">
          <h3 className="text-lg">{payload[0].payload.label}</h3>
          {payload[0].value
            ? <p>
                Avg. {classification}:
                <span className={`font-bold text-lg ${classification === "VR" ? "text-clt-primary-teal" : "text-clt-primary-orange"}`}>
                  &nbsp;{payload[0].value}
                </span>
              </p>
            : <p>Not taken.</p>
          }
        </div>
      );
    }

    return null;
  },
  CustomCursor: React.FC<{
    points?: { x: number, y: number }[];
    height?: number;
    width?: number;
    barColor: string;
    numDataPoints: number;
  }> = ({ points, height, width, barColor, numDataPoints }) => {
    if (!points || !points.length || !height || !width || numDataPoints < 2) return null;
    const
      { x, y } = points[0],
      cursorWidth = Math.min(width / numDataPoints * 1.2, 100);

    return <Rectangle fill={barColor} opacity={.15} x={x - (cursorWidth / 2)} y={y} width={cursorWidth} height={height} />;
  },
  CustomTick: React.FC<{ x?: number, y?: number, payload?: { value: string }, nulls: string[] }> = ({ x, y, payload, nulls }) => {
    if (!x || !y || !payload) return null;
    const { fontStyle, fill } = nulls.includes(payload.value) 
      ? { fontStyle: "italic", fill: "#B5C1CB" }
      : { fontStyle: "normal", fill: "#484D61" };

    return <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fontStyle={fontStyle} fill={fill} style={{ fontFamily: "Assistant" }}>
        {payload.value}
      </text>
    </g>;
  };

export default function SectionScoreBarGraph({ classification, source }: SectionScoreBarGraphProps) {
  const
    show = source.length > 1,
    dataKeys: {
      label: DataKey<string>;
      timeKey: DataKey<number>;
      score: DataKey<number | null>;
    } = {
      label: "label",
      timeKey: "timeKey",
      score: classification.toLowerCase() as "vr" | "qr",
    },
    { label, barColor } = classification === "VR"
      ? { label: "Verbal Reasoning", barColor: "#0AC7E5" }
      : { label: "Quantitative Reasoning", barColor: "#F89C1C" },
    nulls = useMemo(() => source.filter(d => d[dataKeys.score] === null).map(d => d.label), [source]);

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart data={show ? source : []} syncId={classification} margin={{ top: 20, bottom: 10, left: 30, right: 30 }}>
          <XAxis
            dataKey={dataKeys.label}
            tickLine={false}
            tick={<CustomTick nulls={nulls} />}
            stroke="#F1F5F8"
            strokeWidth={2}
          />
          <YAxis
            domain={[140, 300]}
            ticks={[150, 225, 300]}
            tick={{ fill: "#232020", fontFamily: "Assistant" }}
            axisLine={false}
            tickLine={false}
          >
            <Label
              value={label}
              offset={5}
              position="insideLeft"
              angle={-90}
              style={{
                fontFamily: "Assistant",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAnchor: "middle",
              }}
            />
          </YAxis>
          <ReferenceLine y={150} strokeWidth={2} stroke="#F1F5F8" />
          <ReferenceLine y={300} strokeWidth={2} stroke="#F1F5F8" />
          <ReferenceLine y={225} strokeWidth={2} strokeDasharray="3 3" stroke="#D5E1EB">
            <Label
              position="right"
              offset={10}
              angle={90}
              style={{
                textAnchor: "middle",
                fontFamily: "Assistant",
                fontSize: "0.8rem",
              }}
            >
              National Average
            </Label>
          </ReferenceLine>
          <Tooltip
            filterNull={false}
            content={<CustomTooltip classification={classification} />}
            cursor={<CustomCursor barColor={barColor} numDataPoints={source.length} />}
          />
          <Bar
            dataKey={dataKeys.score}
            legendType="none"
            radius={[5, 5, 0, 0]}
            barSize={60}
            fill={barColor}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div
        className={classNames(
          show ? "opacity-0" : "opacity-100",
          "absolute inset-0 pointer-events-none backdrop-blur-sm text-text-primary transition-opacity flex justify-center",
        )}
      >
        <div className="flex flex-col gap-2 my-auto text-center">
          <h3 className="text-xl font-bold">Not enough data selected.</h3>
          <p>Please include data from at least two tests.</p>
        </div>
      </div>
    </div>
  );
}
