import { emptyApi as api } from "../apiCodeGenRoot";
export const addTagTypes = ["StudentApi-Form"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      studentApiPostRegister: build.mutation<
        StudentApiPostRegisterApiResponse,
        StudentApiPostRegisterApiArg
      >({
        query: () => ({ url: `/studentapi/register`, method: "POST" }),
      }),
      studentApiGetForm: build.query<
        StudentApiGetFormApiResponse,
        StudentApiGetFormApiArg
      >({
        query: () => ({ url: `/studentapi/form` }),
        providesTags: ["StudentApi-Form"],
      }),
      studentApiSaveAnswer: build.mutation<
        StudentApiSaveAnswerApiResponse,
        StudentApiSaveAnswerApiArg
      >({
        query: (queryArg) => ({
          url: `/studentapi/registrations/answer`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      studentApiGetSection: build.query<
        StudentApiGetSectionApiResponse,
        StudentApiGetSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/studentapi/registrations/sections/${queryArg.sectionNumber}`,
        }),
      }),
      studentApiStartSection: build.mutation<
        StudentApiStartSectionApiResponse,
        StudentApiStartSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/studentapi/registrations/sections/${queryArg.sectionNumber}/start`,
          method: "POST",
        }),
      }),
      studentApiEndSection: build.mutation<
        StudentApiEndSectionApiResponse,
        StudentApiEndSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/studentapi/registrations/sections/${queryArg.sectionNumber}/end`,
          method: "POST",
        }),
        invalidatesTags: ["StudentApi-Form"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type StudentApiPostRegisterApiResponse = unknown;
export type StudentApiPostRegisterApiArg = void;
export type StudentApiGetFormApiResponse =
  /** status 200 A successful response returning a TestForm */ {
    takingId: number;
    formData: TestForm;
  };
export type StudentApiGetFormApiArg = void;
export type StudentApiSaveAnswerApiResponse = unknown;
export type StudentApiSaveAnswerApiArg = {
  body: {
    takingId: number;
    questionId: number;
    answer: AnswerType;
    sectionNumber: number;
  };
};
export type StudentApiGetSectionApiResponse =
  /** status 200 A successful response returning a Section */ Section;
export type StudentApiGetSectionApiArg = {
  /** Number of the Section to retrieve, e.g. 1, 2, 3, etc. */
  sectionNumber: number;
};
export type StudentApiStartSectionApiResponse = unknown;
export type StudentApiStartSectionApiArg = {
  /** Number of the Section to start, e.g. 1, 2, 3 */
  sectionNumber: number;
};
export type StudentApiEndSectionApiResponse = unknown;
export type StudentApiEndSectionApiArg = {
  /** Number of the Section to end, e.g. 1, 2, 3 */
  sectionNumber: number;
};
export type Answer = {
  answerLetter: string;
  text: string;
};
export type Question = {
  id: number;
  stem: string;
  imageUrl: string;
  answerChoices: Answer[];
  passageId: number;
  seq: number;
  studentAnswer?: string;
};
export type Passage = {
  id: number;
  name: string;
  authorName: string;
  paragraphs: string[];
  introText: string;
};
export type Section = {
  id: number;
  name: string;
  code: string;
  sectionNumber: number;
  progressStatus?: string;
  questionCount?: number;
  questions?: Question[];
  passages?: Passage[];
};
export type TestForm = {
  takingId: number;
  id: number;
  name: string;
  takerFirstName?: string;
  blueprintId: number;
  blueprintName: string;
  blueprintCode: string;
  sections: Section[];
};
export type AnswerType = "A" | "B" | "C" | "D";
export const {
  useStudentApiPostRegisterMutation,
  useStudentApiGetFormQuery,
  useLazyStudentApiGetFormQuery,
  useStudentApiSaveAnswerMutation,
  useStudentApiGetSectionQuery,
  useLazyStudentApiGetSectionQuery,
  useStudentApiStartSectionMutation,
  useStudentApiEndSectionMutation,
} = injectedRtkApi;
