export const boldNumbers = (text: string) => {
  const regex = /\d+/g,
    parts = text.split(regex),
    numbers = text.match(regex) || [];

  return parts.reduce((acc: JSX.Element[], part: string, i: number) => {
    acc.push(<span key={`text-${i}`}>{part}</span>);
    if (i < numbers.length) {
      acc.push(<b key={`number-${i}`}>{numbers[i]}</b>);
    }
    return acc;
  }, []);
};

const replaceDoubleBracketsWithSingle = (input: string): string => input.replace(/\[\[([0-9]+)\]\]/g, "[$1]");

export const parseAndRemoveBrackets = (input: string): {number?: number, cleanedString: string} => {
  const pattern = /^\[\s*(\d+)\s*\]\s*/,
   match = input.match(pattern),
   number = match ? parseInt(match[1], 10) : undefined,
   cleanedString = input.replace(pattern, "");

  // Verbal contract with the Test Dev team:
  // - paragraph numbers are in single brackets like so and get parsed out and styled differently, e.g., [1] becomes a 1 styled to the side of paragraph
  // - double brackets are to remain in the text of the paragraph as a single bracket, e.g., [[1]] -> [1]
  return { number, cleanedString: replaceDoubleBracketsWithSingle(cleanedString) };
};

export const createAcronym = (title: string): string => {
  const words = title.split(" ");
  return words.map(word => {
    if (word.toLowerCase() === "and") {
      return "&";
    }
    return word.charAt(0).toUpperCase();
  }).join("");
};