import React, { useEffect, useState } from "react";
import { AnalyticsScore } from "../../../../services/gen/managerApi";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  flexRender, RowSelectionState,
} from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import Checkbox from "../../../../components/Checkbox";
import { Transition } from "@headlessui/react";
import { ArrowUpRightIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import Tooltipped from "../../../../components/Tooltipped";
import CopyTextButton from "../../../../components/CopyTextButton";

type TakingsScoresProps = {
  scores: AnalyticsScore[]
  testtype_semester: string,
  setter: (scores: AnalyticsScore[]) => void;
  className?: string;
};

  const columns: ColumnDef<AnalyticsScore>[] = [
      {
        id: "select-col",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            disabled={!table.getToggleAllRowsSelectedHandler()}
            onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
    {
      header: "First Name",
      accessorKey: "firstName",
      enableSorting: true,
    },
    {
      header: "Initial",
      accessorKey: "middleInitial",
      enableSorting: false
    },
    {
      header: "Last Name",
      accessorKey: "lastName",
      enableSorting: true,
    },
    {
      header: "VR",
      accessorKey: "vr",
      enableSorting: true,
    },
    {
      header: "QR",
      accessorKey: "qr",
      enableSorting: true,
    },
    {
      header: "Stanine",
      accessorKey: "overallStanine",
      enableSorting: true,
    },
    {
      header: "Lexile",
      accessorKey: "lexileReportable",
      enableSorting: true,
    },
    {
      header: "Lexile Percentile",
      accessorKey: "lexilePercentile",
      enableSorting: true,
    },
    {
      header: "Quantile",
      accessorKey: "quantileReportable",
      enableSorting: true,
    },
    {
      header: "Quantile Percentile",
      accessorKey: "quantilePercentile",
      enableSorting: true,
    },
    {
      header: () => (
          <div className="flex gap-1">
            <span>Shareable Report</span>
            <Tooltipped
              tooltipContent={<span className="font-normal">These links can be shared and viewed <br /> without a CLT account.</span>}
              xPos="LEFT"
              yPos="BOTTOM"
            >
              <InformationCircleIcon className="w-6 h-6 opacity-50 hover:opacity-100" />
            </Tooltipped>
          </div>
      ),
      accessorKey: "publicToken",
      enableSorting: false,
      cell: props => (
        <div className="flex items-center gap-1 ml-8">
          <Tooltipped tooltipContent="View" xPos="LEFT" yPos="TOP">
            <a
              href={`/analytics?t=${props.getValue()}`}
              target="_blank"
              rel="noreferrer"
              className="block p-1.5 rounded-minimal text-text-secondary transition-colors bg-clt-medium-gray/60 hover:bg-clt-medium-gray"
            >
              <ArrowUpRightIcon strokeWidth={2} className="h-4 w-4" />
            </a>
          </Tooltipped>
          <Tooltipped tooltipContent="Copy Link" xPos="LEFT" yPos="TOP">
            <CopyTextButton text={`${window.location.origin}/analytics?t=${props.getValue()}`} />
          </Tooltipped>
        </div>
      ),
    }
  ],
  getDefaultAllSelected = (a: AnalyticsScore[]) => a.reduce((acc: RowSelectionState, _, index) => { acc[index] = true; return acc as RowSelectionState; }, {}),

  TakingsScoresPanel: React.FC<TakingsScoresProps> = ({ scores = [], setter, testtype_semester }) => {
  const data = scores,
    [isOpen, setIsOpen] = useState(false),
    [sorting, setSorting] = useState<SortingState>([]),
    [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    }),
    [rowSelection, setRowSelection] = useState<RowSelectionState>(getDefaultAllSelected(data)),
   handleToggle = () => setIsOpen(!isOpen),
   table = useReactTable({
      columns,
      data,
      state: {
        sorting,
        pagination,
        rowSelection,
      },
      enableRowSelection: true,
      onRowSelectionChange: rowSelecion => setRowSelection(rowSelecion),
      onSortingChange: sorting => setSorting(sorting),
      onPaginationChange: pagination => setPagination(pagination),
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      columnResizeMode: "onChange",
      defaultColumn: {
        size: 10,
        minSize: 10,
        maxSize: 50,
      },
   });

    useEffect(() => {
      const selectedScores = data.filter((_, index) => rowSelection[index]);
        setter(selectedScores);
    }, [rowSelection]);

    useEffect(() => {
      setRowSelection(getDefaultAllSelected(data));
    }, [testtype_semester]);

  return (
    <div className="relative whitespace-nowrap">
      <Transition
        // TODO revisit this, but for now sliding motion makes the screen jump because it temporarily gets longer and so a scroll bar shows up
        // enter="transition ease duration-200 transform"
        // enterFrom="opacity-0 translate-y-20"
        // enterTo="opacity-100 translate-y-0"
        // leave="transition ease duration-200 transform"
        // leaveFrom="opacity-100 -translate-y-0"
        // leaveTo="opacity-0 translate-y-20"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={isOpen}
      >
        <div className="absolute z-10 bottom-[4.5rem] right-0 w-full">
          <div className="h-12 pl-8 border-t-2 border-b flex justify-between items-center w-full bg-surface-primary rounded-t-3xl text-center">
            <p>Test Performance</p>
            <p className="flex-1 mr-32">Analytics above average <b>{Object.keys(rowSelection).length}</b> of <b>{data.length}</b> total scores.</p>
          </div>
          <div className="overflow-x-scroll overflow-y-visible">
            <table className="w-full text-bold">
              <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="h-12 text-text-secondary">
                  {headerGroup.headers.map((header, hgIndex) => (
                    <th key={header.id}
                        colSpan={header.colSpan}
                        className={`text-left font-sans bg-white ${hgIndex === 0 ? "" : ""} border-1 border-gray-200 no-wrap w-[${header.getSize()}px] ${header.column.getCanSort() ? "cursor-pointer" : "cursor-auto"}`}
                        onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}>
                      <div className="flex flex-nowrap items-center select-none">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort()
                        ? (header.column.getIsSorted() ? (header.column.getIsSorted() === "desc" ? <ChevronDownIcon className="h-4 w-4 inline-block"/> : <ChevronUpIcon className="h-4 w-4 inline-block"/>) : <ChevronUpDownIcon className="h-4 w-4 inline-block"/>)
                        : null
                      }
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              </thead>


              <tbody>
              {table.getRowModel().rows.map(((row, index) => (
                <tr key={row.id} className={`h-12 text-text-secondary font-medium ${index % 2 === 0 ? "bg-border-primary" : "bg-surface-primary"}`}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="border-1 border-gray-200">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )))}
              </tbody>

            </table>
          </div>
      </div>
    </Transition>

    <div className="relative p-4 z-10 flex justify-between items-center bg-white">
    <div className={isOpen ? "opacity-100" : "opacity-0"}>Page <b>{table.getState().pagination.pageIndex + 1}</b> of <b>{table.getPageCount()}</b></div>
      <button className={`ml-8 p-2 ${isOpen ? "bg-surface-tertiary text-text-secondary" : "bg-surface-brand-2 text-text-inverted"} rounded`} onClick={handleToggle}>
        { isOpen
          ? <div className="min-w-[7rem]">Hide Students <ChevronDownIcon className="h-4 w-4 inline-block"/></div>
          : <div className="min-w-[7rem]">Show Students <ChevronUpIcon className="h-4 w-4 inline-block"/></div>
        }
      </button>
      <div className={isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className={`mr-4 rounded p-2 bg-surface-brand-2 text-text-inverted w-20 ${table.getCanPreviousPage() ? "cursor-pointer" : "disabled opacity-50 cursor-not-allowed"}`}>
          Previous
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className={`rounded p-2 bg-surface-brand-2 text-text-inverted w-20 ${table.getCanNextPage() ? "cursor-pointer" : "disabled opacity-50 cursor-not-allowed"}`}>
          Next
        </button>
      </div>
    </div>
  </div>
  );
  };

export default TakingsScoresPanel;
