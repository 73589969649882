interface LoadingOverlayProps {
  color?: "white" | "blue";
}

const LoadingOverlay = ({ color = "white" }: LoadingOverlayProps) => {
  const colorClass = color === "white" ? "border-white" : "border-cltblue",
     bgColor = color === "white" ? "bg-black" : "bg-white";

  return (
    <div className={`fixed inset-0 ${bgColor} bg-opacity-25 flex justify-center items-center z-50`}>
      <div className={`animate-spin rounded-full h-10 w-10 border-4 border-t-transparent ${colorClass}`}></div>
    </div>
  );
};

export default LoadingOverlay;
