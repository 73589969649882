import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SpinnerFullScreen from "../components/SpinnerFullScreen";
import { useAuthorizeApiLogoutQuery } from "../services/gen/authorize";

const Logout = () => {
  const
    { logout } = useAuth0(),
    { isSuccess } = useAuthorizeApiLogoutQuery(),
    [queryParams] = useSearchParams(),
    final = queryParams.get("final") !== null;

  useEffect(() => {
    if (isSuccess) {
      const returnTo = final
        ? process.env.REACT_APP_LOGOUT_URL
        : `${process.env.REACT_APP_OLDPHP_BASE_URL}logout?final`;

      logout({ returnTo, client_id: process.env.REACT_APP_AUTH0_CLIENT_ID });
    }
  }, [isSuccess]);

  return isSuccess ? null : <SpinnerFullScreen />;
};

export default Logout;
