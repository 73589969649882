import { useState, useRef, useReducer } from "react";
import { RootState } from "../app/store";
import { useAppSelector } from "../app/hooks";
import {
    useGetProfileForIdQuery,
    useGetCeebCountriesQuery,
    useUpdateProfileMutation,
    useGetCeebCitiesQuery,
    useGetCeebStatesQuery,
    useGetCeebSchoolsQuery,
    useGetCollegeSearchQuery,
    useGetSchemaQuery,
} from "../services/profileApi";
import { ListItem, Profile } from "../shared/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { nationalCodes, usStateCodes } from "../shared/constants";
import SpinnerFullScreen from "../components/SpinnerFullScreen";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import Input, { InputProps } from "../components/Input";
import Toggle, { ToggleProps } from "../components/Toggle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { noop } from "../shared/utils";
import { getManualValidator, getValidator } from "./StudentProfile/fieldValidation";
import ComboBox from "../components/ComboBox";
import { isEqual } from "lodash";
import Autocomplete from "react-google-autocomplete";
import { convertGoogleAddressToFormFields, getAddressAsString } from "./StudentProfile/addressUtil";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

dayjs.extend(utc);

const StudentProfile = function() {
    type ProfileTab = {
        index: number,
        title: string,
        content: JSX.Element
    };

    const notifySave = () => toast("Saving...");
    enum SchoolTypes { "Charter" = "Charter","Homeschool" = "Homeschool", "Public" = "Public",  "Private" = "Private",  "Other"  = "Other" }
    const usaLabel = "United States of America", // this should probably live elsewhere but is only used here atm
     noCeebCode = "000003",

     localUser = useAppSelector((state: RootState) => state.user),
        navigate = useNavigate(),
        { data, isError: getProfileError } = useGetProfileForIdQuery(String(localUser.idUser), { skip: !localUser.idUser }),
        { data: schemaData } = useGetSchemaQuery(),
        profile: Profile | null = data ? data.profile : null,
        [currentTabIndex, setCurrentTabIndex] = useState(0),
        [tabsSeen, setTabsSeen] = useState<{[k: number]: boolean}>({}),
        [showErrorMessages, setShowErrorMessages ] = useState<{[k: string]: boolean}>({}),
        [updateProfile, { isLoading: isUpdatingProfile, isError: updateProfileError }] = useUpdateProfileMutation(),
        [showErrorModal, setShowErrorModal] = useState(false),
        [showCollegeSaveModal, setShowCollegeSaveModal] = useState(false),
        [showNamesSaveModal, setShowNamesSaveModal] = useState(false),
        [showManualAddressInputs, setShowManualAddressInputs] = useState(false),
        [collegeQuery, setCollegeQuery] = useState<string>(""),
        [namesEditable, setNamesEditable] = useState({ firstName: true, lastName: true } ),
        { data: collegeSearchResults } = useGetCollegeSearchQuery(collegeQuery, { skip: collegeQuery.length < 3 }),
        formValues = useRef<{[k: string]: string | ListItem[]}>({ idUser: "unset" }),
        profileHasLoaded = useRef<boolean>(false),

     [ceebCountry, setCeebCountry] = useState<string>(""),
     [ceebState, setCeebState] = useState<string>(""),
     [ceebCity, setCeebCity] = useState<string>(""),
     [isHomeschoolToggle, setIsHomeschoolToggle] = useState<boolean>(),
     [, forceUpdate] = useReducer(x => x + 1, 0);

    // this runs only once after profile has loaded
    if (profile && !profileHasLoaded.current) {
        profileHasLoaded.current = true;

        (profile?.firstName && profile.firstName.length) && setNamesEditable(Object.assign(namesEditable, { firstName: false }));
        (profile?.lastName && profile.lastName.length) && setNamesEditable(Object.assign(namesEditable, { lastName: false }));
    }


    (profile?.ceebCountry && ceebCountry === "") && setCeebCountry(profile.ceebCountry);
    (profile?.ceebState && ceebState === "") && setCeebState(profile.ceebState);
    (profile?.ceebCity && ceebCity === "") && setCeebCity(profile.ceebCity);

    const hasStates: {[k: string]: string} = { [usaLabel]: "State", "Canada": "Province", "Australia": "Territory" },
     doFetchStates = Boolean(ceebCountry && hasStates[ceebCountry]),
      // only three nations in the CEEB table have 'states', for the rest we go straight to cities
      doFetchCities = Boolean(doFetchStates ? ceebState : ceebCountry),
      doFetchSchools = Boolean(doFetchCities && ceebCity),

     { data: ceebCountries } = useGetCeebCountriesQuery( ),
      { data: ceebStates } = useGetCeebStatesQuery(ceebCountry, { skip: !doFetchStates }),
      { data: ceebCities } = useGetCeebCitiesQuery({ co: ceebCountry, state: ceebState || "no_state" }, { skip: !doFetchCities }),
      { data: ceebSchools } = useGetCeebSchoolsQuery({ co: ceebCountry, state: ceebState || "no_state", city: ceebCity }, { skip: !doFetchSchools }),

     convertToApiType = (id: string, value: string | ListItem[]): string | number | boolean | null | ListItem[] => {
        const numbers = ["act", "sat", "psat", "gradYear", "idCollegeMajor", "gpa"],
          booleans = ["christianCatholic", "liberalArts", "collegeShare", /* "autoScoreShare" */],
          nulls = ["schoolSize", "gpa", "manualSchool"];
        let newValue;

        if (numbers.includes(id) && value !== "") {
            newValue = Number(value);
        } else if (booleans.includes(id)) {
            if (value === "true") {
                newValue = true;
            } else if (value === "false") {
                newValue = false;
            } else {
                throw new Error("Booleans in this form must be the string 'true' or the string 'false' before conversion.");
            }
        } else if (nulls.includes(id) && (value === "null" || value === "")) {
            newValue = null;
        }
        else {
            newValue = value;
        }

        return newValue;
    },

    // Some values, when changed, need to change other values.
     valueDependencies: {[k: string]: (value: string | ListItem[]) => void} = {
        ceebCode(value: string | ListItem[]) {
            if (value !== noCeebCode) {
                sendToApi("manualSchool", "");
            }
        }
    },

     sendToApi = (id: string, value: string | ListItem[], showToast = true) => {
        const valueIsChanged = !isEqual(value, formValues.current?.[id]);
        if (!valueIsChanged) {
          return;
        }

        const patchData = { idUser: Number(localUser.idUser), [id]: convertToApiType(id, value) };
        if (!schemaData) {
            // This should not happen since spinner is shown when schema is missing.
            throw new Error("Validation schema is missing.");
        }

        const validateAjv = getValidator(schemaData.schema),
         validateManual = getManualValidator(),
         validate = (p: typeof patchData) => validateAjv(p) && validateManual(p),

         // since we are only ever validating one value at a time, it is safe to use boolean response as 'pass' or 'fail'
         validateResults = validate(patchData),
         shouldShowErrorMsg = !validateResults;

        if (shouldShowErrorMsg !== showErrorMessages[id]) {
            setShowErrorMessages({
                ...showErrorMessages,
                [id]: shouldShowErrorMsg
            });
        }

        if (!shouldShowErrorMsg && valueIsChanged) {
            updateProfile(patchData);
            valueDependencies[id] && valueDependencies[id](value);
            showToast && notifySave();  // TODO how to hide/axe the toast when error occurs?
            formValues.current[id] = value;
        }
    };

    // Create no hooks below this point
    if (!profile) {
        return null;
    }

    if ((getProfileError || updateProfileError) && !showErrorModal)  {
        setShowErrorModal(true);
    }

    const valuesToOptions = (vals: string[]) => Array.isArray(vals) ? vals.map(val => ({ label: val, value: val })) : [];

    // initialize the form values only once, based on api response
    if (formValues.current.idUser === "unset") {

        for (const k of Object.keys(profile)) {
            const val = profile[k];
            if (val === null) {
                formValues.current[k] = "";
            } else if (k === "dob" && typeof val === "string") { // the only date so far
                formValues.current[k] = dayjs(val).utc().format("YYYY-MM-DD");
            } else if (Array.isArray(val)) {
                formValues.current[k] = val;
            } else {
                formValues.current[k] = String(val) ?? "";
            }
        }

        formValues.current.username = localUser.username || "";
        formValues.current.isHomeschool = formValues.current.manualSchool ? "false" : "true";
    }

    const baseProps = (id: string) => ({
        id,
        key: id,
        initialValue: formValues.current[id] as string || "",
        handleSave: sendToApi,
        isRequired: true,
        showError: Boolean(showErrorMessages[id]),
        errorMessage: schemaData?.errorMessages[id] ?? "",
        currentTabSeen: (() => tabsSeen[currentTabIndex])()
    }),

     baseToggleProps = (id: string) => ({
        id,
        key: id,
        initialValue: formValues.current[id] as string || "",
        handleSave: sendToApi,
    }),
      disabledHoverText = "If you need to change your name, date of birth, and/or username, please contact CLT directly at info@cltexam.com.",

     fieldDefs: { [k: number]: { [k: string]: InputProps } } = {
        0: {
            "username": {
                ...baseProps("username"),
                label: "Username",
                isRequired: false,
                isDisabled: true,
                disabledHoverText,
            },
            "firstName": {
                ...baseProps("firstName"),
                label: "First Name",
                isDisabled: !namesEditable.firstName,
                min: 2,
                disabledHoverText,
            },
            "lastName": {
                ...baseProps("lastName"),
                label: "Last Name",
                isDisabled: !namesEditable.lastName,
                min: 2,
                disabledHoverText,
            },
            "dob": {
                ...baseProps("dob"),
                label: "Date of Birth",
                placeholder: "YYYY-MM-DD",
                type: "date",
                min: dayjs().subtract(100, "years").format("YYYY-MM-DD"),
                max: dayjs().subtract(13, "years").format("YYYY-MM-DD"),
                disabledHoverText,
            },
            "phone": {
                ...baseProps("phone"),
                label: "Phone",
                type: "tel",
                placeholder: "555-555-1234",
                maxLength: schemaData?.schema?.phone?.maxLength | 20,
            },
            "emergencyName": {
                ...baseProps("emergencyName"),
                label: "Parent Name",
            },
            "emergencyEmail": {
                ...baseProps("emergencyEmail"),
                label: "Parent Email",
            },
            "emergencyPhone": {
                ...baseProps("emergencyPhone"),
                label: "Parent Phone",
                type: "tel",
                maxLength: schemaData?.schema?.phone?.maxLength | 20,
            }
        },
        1: {
            "country": {
                ...baseProps("country"),
                label: "Country",
                selectOptions: nationalCodes,
            },
            "mail1": {
                ...baseProps("mail1"),
                label: "Street Address Line 1",
            },
            "mail2": {
                ...baseProps("mail2"),
                label: "Street Address Line 2",
                isRequired: false,
            },
            "city": {
                ...baseProps("city"),
                label: "City",
            },
            "state": {
                ...baseProps("state"),
                label: "State",
                selectOptions: usStateCodes,
                isRequired: profile?.country === "US"
            },
            "region_intl": {
                ...baseProps("region_intl"),
                label: "Region / Province",
                isRequired: profile?.country !== "US",
                selectOptions: undefined
            },
            "zip": {
                ...baseProps("zip"),
                label: "Zip / Postal Code",
            },
        },
        2: {

            "hsType": {
                ...baseProps("hsType"),
                label: "Type of School",
                selectOptions: valuesToOptions(Object.keys(SchoolTypes)),
            },
            "ceebCountry": {
                ...baseProps("ceebCountry"),
                label: "School Country",
                handleChange: setCeebCountry,
                isRequired: false,
                handleSave: noop,
                selectOptions: [{
                    label: usaLabel,
                    value: usaLabel
                }].concat(ceebCountries ? valuesToOptions(ceebCountries.countries) : []),
            },
            "ceebState": {
                ...baseProps("ceebState"),
                label: `School ${hasStates[ceebCountry]}`,
                handleChange: setCeebState,
                isDisabled: !(ceebCountry),
                isRequired: false,
                handleSave: noop,
                selectOptions: ceebCountry === usaLabel ? usStateCodes : ceebStates ? valuesToOptions(ceebStates.states) : [],
            },
            "ceebCity": {
                ...baseProps("ceebCity"),
                label: "School City",
                isRequired: false,
                handleChange: setCeebCity,
                isDisabled: !(ceebCountry && (ceebState || !hasStates[ceebCountry])),
                handleSave: noop,
                selectOptions: ceebCities ? valuesToOptions(ceebCities.cities) : [],
            },
            "ceebCode": {
                ...baseProps("ceebCode"),
                label: "School Name",
                isRequired: true,
                isDisabled: !(ceebCountry && ceebCity),
                selectOptions: ceebSchools ? ceebSchools.schools.map(x => ({ label: x.schoolName, value: x.ceebCode }))
                  : formValues.current.ceebCode === noCeebCode ? [{ label: "My school is not listed", value: noCeebCode }] : [],
                disabledHoverText: "To change this value please set School location values above."
            },
            "manualSchool": {
                ...baseProps("manualSchool"),
                label: "Unlisted School Name",
                isRequired: false,
                placeholder: "Carefully enter school name",
            },
            "gradYear": {
                ...baseProps("gradYear"),
                label: "High School Graduating Class"
            },
            "gpa": {
                ...baseProps("gpa"),
                label: "GPA",
                type: "number",
                min: 0.1,
                max: 5,
                step: 0.01,
                isRequired: false
            },
            "sat": {
                ...baseProps("sat"),
                label: "SAT",
                isRequired: false
            },
            "act": {
                ...baseProps("act"),
                label: "ACT",
                isRequired: false
            },
            "psat": {
                ...baseProps("psat"),
                label: "PSAT",
                isRequired: false
            },
        },
        3: {

            "idCollegeMajor": {
                ...baseProps("idCollegeMajor"),
                label: "Intended Major",
                selectOptions: [
                    { label: "Arts and Humanities", value: "1" },
                    { label: "Business", value: "2" },
                    { label: "Health and Medicine", value: "3" },
                    { label: "Science, Math, and Technology", value: "4" },
                    { label: "Social Sciences", value: "5" },
                    { label: "Undecided", value: "6" },
                ]
            },
            "schoolSize": {
                ...baseProps("schoolSize"),
                label: "Preferred School Size",
                isRequired: false,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "500 and under", value: "500 and under" },
                    { label: "500-1000", value: "500-1000" },
                    { label: "1000-5000", value: "1000-5000" },
                    { label: "5000+", value: "5000+" },
                ]
            },
            "financialAid": {
                ...baseProps("financialAid"),
                label: "Financial Aid/Scholarship Dependency",
                isRequired: false,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "Must Have", value: "Must Have" },
                    { label: "Nice to Have", value: "Nice to Have" },
                    { label: "Not Important", value: "Not Important" },
                ]
            },
        },
        4: {
            "gender": {
                ...baseProps("gender"),
                label: "Gender",
                isRequired: true,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" }
                ]
            },
            "ethnicity": {
                ...baseProps("ethnicity"),
                label: "Race / Ethnicity",
                isRequired: true,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "American Indian or Alaska Native", value: "American Indian or Alaska Native" },
                    { label: "Asian", value: "Asian" },
                    { label: "Black or African American", value: "Black or African American" },
                    { label: "Hispanic or Latino", value: "Hispanic or Latino" },
                    { label: "Native Hawaiian or Other Pacific Islander", value: "Native Hawaiian or Other Pacific Islander" },
                    { label: "White", value: "White" },
                    { label: "Other", value: "Other" },
                ]
            },
            "income": {
                ...baseProps("income"),
                label: "Household Income",
                isRequired: true,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "$0 - $25,000", value: "$0 - $25,00" },
                    { label: "$25,000 - $50,000", value: "$25,000 - $50,000" },
                    { label: "$50,000 - $75,000", value: "$50,000 - $75,000" },
                    { label: "$75,000 - $125,000", value: "$75,000 - $125,000" },
                    { label: "$125,000 - $225,000", value: "$125,000 - $225,000" },
                    { label: "More than $225,000", value: "More than $225,000" },
                ]
            },
            "religion": {
                ...baseProps("religion"),
                label: "Religion",
                isRequired: true,
                selectOptions: [
                    { label: "Skip this question", value: "Not Provided" },
                    { label: "Catholic", value: "Catholic" },
                    { label: "Protestant", value: "Protestant" },
                    { label: "Jewish", value: "Jewish" },
                    { label: "Muslim", value: "Muslim" },
                    { label: "Orthodox", value: "Orthodox" },
                    { label: "Other", value: "Other" },
                ]
            },
            "associations": {
                ...baseProps("associations"),
                label: "Groups / Associations",
                isRequired: false,
            }
        }
    },

     toggleDefs: {[k: string]: ToggleProps} = {
        "isHomeschool": {
            ...baseToggleProps("isHomeschool"),
            label: "Homeschool",
            handleChange(newVal: boolean) { setIsHomeschoolToggle(newVal); (newVal && profile?.manualSchool) && sendToApi("manualSchool", ""); },
            handleSave: noop,
            initialValue: isHomeschoolToggle,
        },
        "liberalArts": {
            ...baseToggleProps("liberalArts"),
            label: "I am interested in Liberal Arts",
            initialValue: profile?.liberalArts,
        },
        "christianCatholic": {
            ...baseToggleProps("christianCatholic"),
            label: "I am interested in Christian / Catholic Culture",
            initialValue: profile?.christianCatholic,
        },
        "collegeShare": {
            ...baseToggleProps("collegeShare"),
            label: "Get recruited by CLT Partner Colleges",
            initialValue: (profile?.collegeShare === null || profile?.collegeShare),
        },
        "showManualAddressInputs": {
            ...baseToggleProps("showManualAddressInputs"),
            label: "Address not found",
            handleChange(newVal: boolean) { setShowManualAddressInputs(newVal); },
            handleSave: noop,
            initialValue:  showManualAddressInputs,
            displaySwitch: false
        },
        // May be re-enabled at a later date.
        // "autoScoreShare": {
        //     ...baseToggleProps("autoScoreShare"),
        //     label: "Automatically share your CLT or CLT10 scores with your colleges of interest.",
        //     initialValue: (profile?.autoScoreShare === null || profile?.autoScoreShare),
        // },
    },

     tabFieldsCompleted: {[k: number]: { incompleteRequired: number, totalRequired: number, incompleteNotRequired: number, notRequiredTotal: number }} = Object.keys(fieldDefs).reduce((acc, key) => {
       const requiredForTab = Object.values(fieldDefs[Number(key)]).filter(v => v.isRequired),
         notRequiredForTab = Object.values(fieldDefs[Number(key)]).filter(v => !(v.isRequired)),
         incompleteRequiredForTab = requiredForTab.filter((f: InputProps) => !(profile[f.id] && !showErrorMessages[f.id])),
         incompleteNotRequiredForTab = notRequiredForTab.filter((f: InputProps) => !(profile[f.id] && !showErrorMessages[f.id])),
         counts =  { [key]: { incompleteRequired: incompleteRequiredForTab.length, totalRequired: requiredForTab.length, incompleteNotRequired: incompleteNotRequiredForTab.length, notRequiredTotal: notRequiredForTab.length } };
       return Object.assign({}, acc, counts);
    }, {}),

     requiredFieldsCompletedCounts = Object.keys(tabFieldsCompleted).reduce(
      (acc, k) => ({ incompleteRequired: acc.incompleteRequired + tabFieldsCompleted[Number(k)].incompleteRequired, totalRequired: acc.totalRequired + tabFieldsCompleted[Number(k)].totalRequired }), { incompleteRequired: 0, totalRequired: 0 }),

     tabsComplete = Object.assign({}, ...Object.keys(tabFieldsCompleted).map((k: string) => {
        const key = Number(k),
          // some tabs will have no required fields, for those, we'll set 'true' when the tab has been seen
          val = tabFieldsCompleted[key].totalRequired ?
              tabFieldsCompleted[key].incompleteRequired === 0:
              tabFieldsCompleted[key].incompleteNotRequired !== tabFieldsCompleted[key].notRequiredTotal;  // If at least one of the non-required fields are set on a tab of all non-required fields, show the badge (val = true).

        return { [key]: val };
    })),

     tabs: { [k:number]: ProfileTab; } = {
        0: {
            index: 0,
            title: "Name & Contact Info",
            content: <>
                    <form className="flex flex-row justify-around" autoComplete="gobbledegook">
                        <div className="flex flex-col w-full m-4">
                            <div className="w-full border-b">
                                <div className="uppercase font-serif">Student</div>
                            </div>
                            <Input {...fieldDefs[0].username} />
                            <Input {...fieldDefs[0].firstName} />
                            <Input {...fieldDefs[0].lastName} />
                            <Input {...fieldDefs[0].dob} />
                            <Input {...fieldDefs[0].phone}/>
                        </div>
                        <div className="flex flex-col w-full m-4">
                            <div className="w-full border-b">
                                <div className="uppercase font-serif">Parent</div>
                            </div>
                            <Input {...fieldDefs[0].emergencyName} />
                            <Input {...fieldDefs[0].emergencyEmail} />
                            <Input {...fieldDefs[0].emergencyPhone} />
                        </div>
                    </form>
                </>,
        },
        1: {
            index: 1,
            title: "Address",
            content: <>
                 <form className="flex flex-col ml-4 mt-12" autoComplete="gobbledegook">
                     {!showManualAddressInputs ? <Autocomplete
                       className="border-gray-400 text-md h-10 ml-4 w-96 border pl-2 focus:ring-cltblue focus:border-0"
                       apiKey={`${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`}
                       placeholder="Find your address"
                       defaultValue={getAddressAsString(formValues.current as Profile)}
                       onPlaceSelected={(place) => {
                           const addy = convertGoogleAddressToFormFields(place);
                           sendToApi("mail1", addy.mail1, true);
                           sendToApi("mail2", addy.mail2, false);
                           sendToApi("city", addy.city, false);
                           sendToApi("zip", addy.zip, false);
                           sendToApi("country", addy.country, false);

                           if (addy.country !== "US") {
                               sendToApi("region_intl", addy.state, false);
                           } else {
                               sendToApi("state", addy.state, false);
                           }

                           forceUpdate();
                       }}
                       libraries={["places"]}
                       options={{ types:["address"] }}
                     /> : null}
                     <Toggle {...toggleDefs.showManualAddressInputs }/>

                     { showManualAddressInputs ?
                         <>
                             <Input {...fieldDefs[1].country} />
                             <Input {...fieldDefs[1].mail1} />
                             <Input {...fieldDefs[1].mail2} />
                             <Input {...fieldDefs[1].city} />
                             <Input { ...fieldDefs[1][formValues.current.country === "US" ? "state" : "region_intl"]} />
                             <Input {...fieldDefs[1].zip} />
                         </>
                       : null }

                 </form>
            </>,
        },
        2: {
            index: 2,
            title: "School & Performance",
            content: <>
                <form className="flex flex-row justify-around" autoComplete="off">
                    <div className="flex flex-col w-full m-4">
                        <div className="w-full border-b">
                            <div className="uppercase font-serif">Current School</div>
                        </div>
                        <Input {...fieldDefs[2].hsType} />
                        <Input {...fieldDefs[2].ceebCountry} />
                        {ceebCountry && hasStates[ceebCountry] ? <Input {...fieldDefs[2].ceebState } /> : null}
                        <Input {...fieldDefs[2].ceebCity} />
                        <Input {...fieldDefs[2].ceebCode} />
                        {profile?.ceebCode === noCeebCode ?
                              <>
                                  <Toggle {...toggleDefs.isHomeschool}/>
                                  {!isHomeschoolToggle && <Input {...fieldDefs[2].manualSchool} />}
                              </>
                          : null
                        }
                    </div>
                    <div className="flex flex-col w-full m-4">
                        <div className="w-full border-b ">
                            <div className="uppercase font-serif">Performance </div>
                        </div>
                        <Input {...fieldDefs[2].gradYear} />
                        <Input {...fieldDefs[2].gpa} />
                        <Input {...fieldDefs[2].sat} />
                        <Input {...fieldDefs[2].act} />
                        <Input {...fieldDefs[2].psat} />
                    </div>
                </form>
            </>,
        },
        3: {
            index: 3,
            title: "College Interests",
            content: <>
                <form className="flex flex-row ml-4 mt-8" autoComplete="off">
                    <div className="flex flex-col">
                        <Input {...fieldDefs[3].idCollegeMajor} />
                        <Input {...fieldDefs[3].schoolSize} />
                        <Input {...fieldDefs[3].financialAid} />
                        <Toggle {...toggleDefs.liberalArts} />
                        <Toggle {...toggleDefs.christianCatholic} />
                    </div>
                    <div className="flex flex-col ml-12 mt-4">
                        <ComboBox id="collegesOfInterest" label="Colleges of Interest"
                                  className="w-80"
                                  initialValue={formValues.current.collegesOfInterest as ListItem[]}
                                  placeholder="Search for Colleges"
                                  queryResults={collegeSearchResults ? collegeSearchResults : []}
                                  handleSave={sendToApi}
                                  setQuery={setCollegeQuery} />
                        <span className="text-sm w-72 mt-2 ml-3">Don&#39;t see your desired college of interest here? Reach out to us at college@cltexam.com with the college you would like to add, and let the admissions office at your college of interest know that you would like to share your CLT score.</span>
                        <Toggle {...toggleDefs.collegeShare} />
                        {/* <Toggle {...toggleDefs.autoScoreShare} /> may be re-enabled at a future date! */}
                    </div>
                </form>
                    </>,
        },
        4: {
            index: 4,
            title: "Additional Information",
            content: <>
                <form className="flex flex-row justify-around" autoComplete="off">
                    <div className="flex flex-col w-full m-4">
                        <Input {...fieldDefs[4].gender} />
                        <Input {...fieldDefs[4].ethnicity} />
                        <Input {...fieldDefs[4].income} />
                    </div>
                    <div className="flex flex-col w-full m-4">
                        <Input {...fieldDefs[4].religion} />
                        <Input {...fieldDefs[4].associations} />
                    </div>
                </form>
            </>,
        },
    },

     isFetchingData = isUpdatingProfile || !schemaData || !data,

     setCurrentTabIndexWrapper = (newIndex: number) => {
        setTabsSeen(Object.assign({}, tabsSeen, { [currentTabIndex]: true }));
        setCurrentTabIndex(newIndex);
        setShowErrorMessages({}); // Because values that aren't saved to API are cleared when tab is left, we clear error messages too.
    },

     tabNumbers = Object.keys(tabs),
        lastTab = Number(tabNumbers.at(-1)),
        checkShouldShowSaveModal = () => {
            if (currentTabIndex === 3 && (/* profile.autoScoreShare === null || */ profile.collegeShare === null)) {
                setShowCollegeSaveModal(true);
                return true;
            }

            if (currentTabIndex === 0 && (profile.firstName && namesEditable.firstName) || (profile.lastName && namesEditable.lastName)) {
                setShowNamesSaveModal(true);
                
                return true;
            }

            return false;
        },
        saveOptIns = () => {
          // if (profile?.autoScoreShare === null) {
          //    sendToApi("autoScoreShare", "true");
          // }
          if (profile?.collegeShare === null) {
             sendToApi("collegeShare", "true");
          }
        },
        saveNames= () => {
          // This only gets called upon leaving the first tab and clicking OK on the modal, if the firstname/lastname *has* a value, we can then make it non-editable.
          const updates = { firstName: !profile?.firstName, lastName: !profile?.lastName };

          if (!isEqual(namesEditable, updates)) {
              setNamesEditable(Object.assign({}, namesEditable, updates ));
          }
      },
        nextTab = () => {
            if (checkShouldShowSaveModal()) {
                return;
            }
            if (currentTabIndex !== lastTab) {
                setCurrentTabIndexWrapper(currentTabIndex + 1);
            }
        },
        prevTab = () => {
            if (checkShouldShowSaveModal()) {
                return;
            }
            if (currentTabIndex !== 0) {
                setCurrentTabIndexWrapper(currentTabIndex - 1);
            }
        };

    return <div className="flex flex-col w-full h-full justify-between">
        <div>
            {isFetchingData ? <SpinnerFullScreen/> : null}
            <ToastContainer position="top-center" closeButton={false} autoClose={1200} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable hideProgressBar={true} theme="light"/>
            { showCollegeSaveModal ? <Modal heading="Do you wish to save settings on this tab?" modalType="INFO" showCancel={true} okButtonText="Save" setShowModal={setShowCollegeSaveModal} onOK={() => saveOptIns() }/> : null }
            { showNamesSaveModal ? <Modal heading="Do you wish to save settings on this tab?" modalType="INFO" showCancel={true} okButtonText="Save" setShowModal={setShowNamesSaveModal} onOK={() => saveNames() }/> : null }
            { showErrorModal ? <Modal heading="Something went wrong!" subheading="Profile did not save. Click OK to refresh and try again." modalType="ERROR" setShowModal={setShowErrorModal} onOK={() => { navigate(0); }}/> : null }
            <h1 className="font-serif text-2xl border-b-2 p-4 mt-2 cursor-default">My Profile
                { requiredFieldsCompletedCounts.incompleteRequired === 0
                  ? <span className="text-xs pl-6">Your profile is complete.</span>
                  : <span className="text-xs pl-6">{requiredFieldsCompletedCounts.incompleteRequired} of {requiredFieldsCompletedCounts.totalRequired} required fields remaining</span> }
            </h1>
            <div className="flex border-b-2 px-5 mt-6 cursor-pointer">
                {Object.values(tabs).map((t, k) =>
                    <div
                      onClick={() => checkShouldShowSaveModal() || setCurrentTabIndexWrapper(t.index)}
                      className={`text-stone-700 whitespace-nowrap px-4 py-2 ${ k == currentTabIndex ? "text-yellow-600 bg-amber-100 border-b-2 border-amber-500" : ""}`}
                      key={`profileTab-${k}`}>
                        {t.title}
                        {tabsComplete[t.index] ? <span><CheckCircleIcon className="inline h-7 w-7 pl-2 text-cltblue"/></span> : null}
                    </div>
                )}
            </div>
            <div className="m-4 max-w-6xl">
                {tabs[currentTabIndex].content}
            </div>
        </div>
        <div className="flex h-64">
            <div className={`flex w-full max-w-2xl ${currentTabIndex === 0 ? "justify-end" : "justify-between"} pt-10 pb-10 mx-8`}>
                { currentTabIndex !== 0 ?
                  <button
                    className="bg-transparent hover:bg-amber-500 text-amber-500 font-semibold hover:text-white py-2 px-4 border border-amber-500 hover:border-transparent rounded select-none max-h-12"
                    onClick={prevTab}
                  >
                      Previous
                  </button> : null
                }
                { currentTabIndex !== lastTab ?
                  <button
                    className="bg-transparent hover:bg-amber-500 text-amber-500 font-semibold hover:text-white py-2 px-4 border border-amber-500 hover:border-transparent rounded select-none max-h-12"
                    onClick={nextTab}
                  >
                      Next
                  </button> : null
                }
            </div>
        </div>
    </div>;
};

export default StudentProfile;
