import { useAuth0 } from "@auth0/auth0-react";
import SpinnerFullScreen from "./SpinnerFullScreen";
import { setUserState } from "../slices/userSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { noAuthMock } from "../app/NoAuthMock";
import { setCltApiToken } from "../slices/cltApiTokenSlice";
import { useAuthorizeApiAuthorizeUserMutation } from "../services/gen/authorize";

type VerifyAuthProps = {
  isTest?: boolean,
  child: JSX.Element
}

const VerifyAuth = (props: VerifyAuthProps): JSX.Element => {
  const dispatch = useAppDispatch(),
     doNotUseAuth0 = process.env.NODE_ENV === "development" && process.env.REACT_APP_NO_AUTH === "true",
    {
      getAccessTokenSilently,
      loginWithRedirect,
      user,
      isAuthenticated,
      isLoading
    } = doNotUseAuth0 ? noAuthMock() : useAuth0(),
    storedToken = useAppSelector((state) => state.token.value, (x, y) => x === y),
    storedUser = useAppSelector((state) => state.user, (x, y) => x.username === y.username),
    // TODO this will be set in server side session based on auth0 JWT, get rid of it when possible
    getIdUser = async (username: string, token: string) => {
      const resp = await fetch(`${process.env.REACT_APP_CLT2_BASE_URL}/users/findId/${username}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }),
      respData = await resp.json();

    return respData.idUser;
  },
  [authorizeUser, { isSuccess: authorizeUserSuccess }] = useAuthorizeApiAuthorizeUserMutation();

  useEffect(() => {
    if (storedToken) {
      authorizeUser();
    }
  }, [storedToken]);
  // TODO import `error` from useAuth0 and do error checking

  useEffect(() => {
    const doAsync = async () => {
      let newToken = "";
      if (storedToken === null) {
        newToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "",
        });
        dispatch(setCltApiToken({ value: newToken }));
      }
    };

    if (!isLoading && user) {
      doAsync();
    }
  }, [user]);

  useEffect(() => {
    const doAsync2 = async () => {
      if (!isLoading && storedToken && user) {
        if (typeof user.name !== "string") {
          throw new Error("Invalid username retrieved from auth0.");
        }
        const userEmail = user.email;
        if (!userEmail) {
          throw new Error("email property is not present in auth0 User object");
        }
        const idUser = await getIdUser(userEmail, storedToken);
        dispatch(setUserState({ username: (userEmail) ?? null, idUser, }));
      }
    };

    if (!isLoading && storedToken && storedUser) {
      doAsync2();
    }
  }, [storedToken]);

  if (storedUser && storedUser.username && authorizeUserSuccess) {
    return props.child;
  }

  if (!isAuthenticated && !isLoading) {
    const
      { protocol, host, pathname, search } = window.location,
      baseUrl = `${protocol}//${host}${pathname}${search}`;
    loginWithRedirect({ redirectUri: baseUrl });
  }

  return <SpinnerFullScreen/>;
};

export default VerifyAuth;
