import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../shared/utils";
import { concat, uniqBy } from "lodash";
import { ListItem } from "../shared/types";

export type ComboBoxProps = {
  label: string
  id: string
  className?: string,
  initialValue?: ListItem[]
  placeholder?: string
  handleSave?: (id: string, val: ListItem[]) => void
  setQuery: React.Dispatch<React.SetStateAction<string>>,
  queryResults: ListItem[]
};

export default function ComboBox({ label, id, className, initialValue = [], placeholder, handleSave, setQuery, queryResults, }: ComboBoxProps) {
  const [selectedItems, setSelectedItems] = useState<ListItem[]>(initialValue),
    addSelectedItem = (newItem: ListItem) => {
      const newValue = uniqBy(concat(selectedItems, newItem), (x: ListItem) => x.id);
      setSelectedItems(newValue);
      handleSave && handleSave(id, newValue);
    },
    removeItem = (itemId: number) => {
      const newValue = selectedItems.filter((i: ListItem) => i.id !== itemId);
      setSelectedItems(newValue);
      handleSave && handleSave(id, newValue);
    };
  
  return (<>
    <Combobox className={className} as="div" value={null} onChange={(value: ListItem) => { value !== null && addSelectedItem(value); } }>
        <Combobox.Label className="uppercase text-md">{label}</Combobox.Label>
      {selectedItems.map((i, k) => (<div key={`selectedItems-${k}`} >
        <div className="px-4 py-2 border border-gray-500 m-1 hover:bg-gray-200 text-sm font-medium max-h-10 rounded-full">
          <div className="flex flex-row">
            <div className="flex-1">{i.name}</div>
            <XCircleIcon className="flex-none h-5 w-5" onClick={() => removeItem(i.id)}/>
          </div>
        </div>
        </div>
      ))}
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cltblue sm:text-sm sm:leading-6"
          onChange={(event) => { setQuery(event.target.value);  }}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {queryResults.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {queryResults.map((item, k) => (
              <Combobox.Option
                key={`queryResult-${k}`}
                value={item}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-cltblue text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames("block truncate", selected ? "font-semibold" : "")}>{item.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-cltblue"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {queryResults.length === 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option value={null} className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
              <span>No results.</span>
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
    </>
  );
}