import { Transition } from "@headlessui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dashboardContentBackground from "../../assets/dashboard_fox.png";
import SpinnerFullScreen from "../../components/SpinnerFullScreen";
import { type AccessCode, useManagerApiGetAccessCodesQuery } from "../../services/gen/managerApi";
import { classNames } from "../../shared/utils";
import { dashboardContent, sitemapBoxes } from "./Dashboard/content";

dayjs.extend(utc);

const
  getOverallTestMode = (accessCodes: AccessCode[]) => {
    if (!accessCodes.length) return "NONE";
    if (accessCodes.every(c => c.mode === "ONLINE")) return "ONLINE";
    if (accessCodes.every(c => c.mode === "PAPER")) return "PAPER";
    return "BOTH";
  },
  getInitialTestMode = (overallMode: ReturnType<typeof getOverallTestMode>) => {
    switch (overallMode) {
      case "BOTH":
        return "ONLINE";  // NB. Mode toggle initialized to ONLINE.
      default:
        return overallMode;
    }
  };

export default function Dashboard() {
  const
    { data: { accessCodes } = {}, isLoading } = useManagerApiGetAccessCodesQuery(),
    utcNow = dayjs.utc(),
    currentAndFutureAccessCodes = useMemo(() => accessCodes?.filter(c => dayjs.utc(c.activeEnd).isAfter(utcNow)), [accessCodes]),
    overallTestMode = currentAndFutureAccessCodes ? getOverallTestMode(currentAndFutureAccessCodes) : undefined,
    [testMode, setTestMode] = useState<"ONLINE" | "PAPER" | "NONE" | null>(overallTestMode ? getInitialTestMode(overallTestMode) : null),
    showModeToggle = overallTestMode === "BOTH",
    contentBlocks = useMemo(
      () => testMode ? dashboardContent[testMode].filter(block => !block.hide?.(currentAndFutureAccessCodes)) : [],
      [testMode, currentAndFutureAccessCodes],
    ),
    [contentBlockIndex, setContentBlockIndex] = useState(0);

  useEffect(() => {
    if (overallTestMode && !testMode) {
      setTestMode(getInitialTestMode(overallTestMode));
    }
  }, [overallTestMode]);
  
  useEffect(() => {
    if (contentBlockIndex > contentBlocks.length) {
      setContentBlockIndex(0);
    }
  }, [contentBlocks, contentBlockIndex]);
  
  return (
    <div className="w-full">
      <header className="top-0 sticky z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray">
        <h1 className="text-xl">Dashboard</h1>
      </header>
      <main className="m-8 xl:m-16">

        <div>
          {showModeToggle && (
            <div className="flex w-96 max-w-[90%] bg-clt-collegiate-blue rounded-t-rounded">
              <button
                className={classNames(
                  "flex-1 p-2 mt-1.5 ml-1.5 mr-[0.1875rem] rounded-minimal text-sm font-bold font-btn uppercase transition",
                  testMode === "ONLINE" ? "bg-clt-white text-text-primary" : "text-text-inverted bg-white/10 hover:bg-white/20",
                )}
                onClick={() => setTestMode("ONLINE")}
              >
                Online Instructions
              </button>
              <button
                className={classNames(
                  "flex-1 p-2 mt-1.5 mr-1.5 ml-[0.1875rem] rounded-minimal text-sm font-bold font-btn uppercase transition",
                  testMode === "PAPER" ? "bg-clt-white text-text-primary" : "text-text-inverted bg-white/10 hover:bg-white/20",
                )}
                onClick={() => setTestMode("PAPER")}
              >
                Paper Instructions
              </button>
            </div>
          )}
          <div
            className={classNames(
              "inset-x-0 rounded-b-extra-rounded rounded-tr-extra-rounded",
              !showModeToggle ? "rounded-tl-extra-rounded" : "",
            )}
            style={{
              backgroundImage: `url(${dashboardContentBackground})`,
              backgroundPosition: "-50% 45%",
              backgroundSize: "110%",
            }}
          >
            <div className={classNames(
              "inset-0 bg-gradient-to-r from-clt-collegiate-blue from-25% to-clt-collegiate-blue/75 text-text-inverted shadow-md rounded-b-extra-rounded rounded-tr-extra-rounded",
              !showModeToggle ? "rounded-tl-extra-rounded" : "",
            )}>
              <div className="flex flex-col sm:flex-row p-5 w-full min-h-[23.3rem]">
                <div className="flex flex-col flex-1 space-y-2 justify-center" role="tablist">
                  {isLoading
                    ? <SpinnerFullScreen />
                    : contentBlocks.map((block, index) => {
                      const
                        selected = index === contentBlockIndex,
                        blockClassName = classNames(
                          "flex flex-row items-center space-x-2 p-4 rounded-rounded text-sm font-semibold hover:cursor-pointer transition ease-out shadow-md",
                          selected ? "bg-clt-white clip-chevron -mr-2 text-text-primary scale-[102%]" : "bg-clt-white bg-opacity-10 text-text-inverted hover:scale-[102%]",
                        ),
                        iconClassName = classNames(
                          "p-1.5 rounded-minimal bg-opacity-10",
                          selected ? "text-clt-collegiate-blue bg-clt-collegiate-blue" : "text-clt-white bg-clt-white",
                        );

                      return (
                        <button
                          key={block.title}
                          role="tab"
                          className={selected ? "[filter:url(#round)] outline-none": ""}
                          id={`tab-${index}`}
                          tabIndex={selected ? -1 : undefined}
                          aria-selected={selected}
                          aria-controls={`pabel-${index}`}
                          onClick={() => setContentBlockIndex(index)}
                        >
                          <div className={blockClassName}>
                            <div className={iconClassName}>
                              {block.icon}
                            </div>
                            <span className="text-left">{block.title}</span>
                          </div>
                        </button>
                      );
                    })
                  }
                </div>

                <div className="relative flex-1 grow-[1.5] lg:grow">
                  {contentBlocks.map((item, index) => (
                    <Transition
                      key={item.title}
                      role="tabpanel"
                      className={classNames(
                        "w-5/6 md:w-3/4 space-y-2 top-1/2 left-0 right-0 m-auto sm:-translate-y-1/2",
                        index === contentBlockIndex ? "relative" : "absolute",
                      )}
                      id={`panel-${index}`}
                      aria-labelledby={`tab-${index}`}
                      show={index === contentBlockIndex}
                      enter="transition ease-out transform"
                      enterFrom="-translate-x-10 opacity-0"
                      enterTo="translate-x-0 opacity-100"
                      leave="transition-opacity ease-linear duration-75"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      {item.content}
                    </Transition>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 md:gap-3 mt-3">
          {sitemapBoxes.map(box => (
            <Link to={box.link} key={box.link} className="flex flex-row justify-between p-5 hover:scale-[102%] hover:cursor-pointer transition w-full bg-clt-white rounded-extra-rounded shadow-lg mb-3 lg:mb-0">
              <div className="flex flex-row">
                <div
                  className="relative inline-block w-24 h-24 rounded-rounded aspect-square"
                  style={{
                    backgroundImage: `url(${box.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="absolute rounded-br-rounded rounded-tl-rounded -translate-x-[0.2px] -translate-y-[0.2px] bg-clt-light-gray text-clt-collegiate-blue p-2 w-fit">
                    {box.icon}
                  </div>
                </div>

                <div className="py-2 pl-3">
                  <h2 className="text-md font-bold text-text-primary">{box.title}</h2> 
                  <p className="text-sm text-text-secondary leading-snug">{box.description}</p>
                </div>
              </div>
              <div className="p-3 h-fit rounded-minimal aspect-square text-text-secondary bg-clt-light-gray">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7147 9.32236L12.7147 2.62749C12.7003 2.01313 12.4499 1.42793 12.0153 0.993395C11.5808 0.558864 10.9956 0.308404 10.3812 0.294033L3.68638 0.294032C3.42114 0.29397 3.16674 0.399275 2.97914 0.586783C2.79155 0.774291 2.68612 1.02864 2.68606 1.29388C2.686 1.55912 2.7913 1.81352 2.97881 2.00112C3.16632 2.18871 3.42067 2.29414 3.68591 2.2942L9.30739 2.29467L1.00799 10.6158C0.820453 10.8033 0.715097 11.0576 0.715097 11.3229C0.715096 11.5881 0.820453 11.8424 1.00799 12.03C1.19553 12.2175 1.44988 12.3229 1.7151 12.3229C1.98031 12.3229 2.23467 12.2175 2.4222 12.03L10.7126 3.71785L10.7145 9.32283C10.7191 9.58499 10.8264 9.83488 11.0134 10.0187C11.2005 10.2025 11.4522 10.3054 11.7144 10.3054C11.9766 10.3054 12.2283 10.2025 12.4153 10.0187C12.6023 9.83488 12.7101 9.58452 12.7147 9.32236Z" fill="currentColor"/>
                </svg>
              </div>
            </Link>
          ))}
        </div>
      </main>

      {/* NB. SVG filter definition referenced above to "round" the selected block. */}
      <svg className="absolute hidden" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />    
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
          </filter>
        </defs>
      </svg>

    </div>
  );
}
