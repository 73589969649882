import { useMemo } from "react";
import { type AccessCode } from "../services/gen/managerApi";

type TestType = AccessCode["testType"];

export function TestTypePill({ testType, textSizeClassName }: { testType: TestType, textSizeClassName?: string }) {

  // TODO find a better way to determine text color?
  const textColorClassName = useMemo(
    () => testType === "clt3" ? "text-text-primary" : "text-text-inverted",
    [testType],
  );
  
  return (
    <div className={`bg-${testType} ${textColorClassName} font-btn font-bold ${textSizeClassName ?? "text-xs"} uppercase px-3 py-1.5 rounded-full`}>
      {testType}
    </div>
  );
}
