import type { AccessCode } from "../../../services/gen/managerApi";

export interface ResourceCardInfo {
  title: string;
  linkUrl: string;
  description?: string;
  imageType: "DOCUMENT" | "TABLE" | "COLUMNS";
}

export interface AdditionalResourceInfo {
  title: string;
  linkUrl: string;
  resourceType: "DOCUMENT" | "LINK";
}

export interface AdditionalResourceGroup {
  resources: AdditionalResourceInfo[];
}

export interface SampleTestPdfInfo {
  testType: AccessCode["testType"];
  linkUrl: string;
}

export const onlineResourceCards: ResourceCardInfo[] = [
  {
    title: "Online Test Administration Manual",
    linkUrl: "https://mud-bell-9cc.notion.site/CLT3-8-Online-Test-Administration-Manual-d7a0cb241a8348f4ba672a8c4f4f1e77",
    description: "Your guide to administering the online test.",
    imageType: "DOCUMENT",
  },
  {
    title: "Online Demo Test",
    linkUrl: "https://info.cltexam.com/hubfs/Ops%20and%20CS/Sample%20exams/Online%20Demo%20Test.pdf",
    description: "Instructions for taking the Demo Test.",
    imageType: "DOCUMENT",
  },
  {
    title: "Test Administration Report",
    linkUrl: "https://cltexam.typeform.com/clt3-8survey",
    description: "Fill this out after testing.",
    imageType: "COLUMNS",
  },
  {
    title: "CLT3-6 Online Test Proctor Script",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT3-6%20Online%20Test%20Proctor%20Schedule%20and%20Script.pdf",
    imageType: "DOCUMENT",
  },
  {
    title: "CLT7-8 Online Test Proctor Script",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT7-8%20Online%20Test%20Proctor%20Schedule%20and%20Script.pdf",
    imageType: "DOCUMENT",
  },
];

export const paperResourceCards: ResourceCardInfo[] = [
  {
    title: "Paper Test Administration Manual",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT3-8%20Print%20Test%20Administration%20Manual%20v3.14.pdf",
    description: "Your guide to administering the paper test.",
    imageType: "DOCUMENT",
  },
  {
    title: "Answer Bubble Practice Sheet",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT%20Practice%20Answer%20Sheets.pdf",
    imageType: "TABLE",
  },
  {
    title: "Test Kit Header Sheet",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-6/2024%20CLT3-8%20Test%20Kit%20Header%20Sheet.pdf",
    imageType: "DOCUMENT",
  },
  {
    title: "Test Administration Report",
    linkUrl: "https://cltexam.typeform.com/clt3-8survey",
    description: "Fill this out after testing.",
    imageType: "COLUMNS",
  },
  {
    title: "CLT3-6 Paper Test Proctor Script",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT3-6%20Print%20Test%20Proctor%20Schedule%20and%20Script.pdf",
    imageType: "DOCUMENT",
  },
  {
    title: "CLT7-8 Paper Test Proctor Script",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Administrator%20Materials/CLT7-8%20Print%20Test%20Proctor%20Schedule%20and%20Script.pdf",
    imageType: "DOCUMENT",
  },
];

export const additionalResourceGroups: AdditionalResourceGroup[] = [
  {
    resources: [
      {
        title: "CLT3-8 Analytics Guide",
        linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3-8%20Analytics%20Guide%20-%20FINAL%20.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "CLT3-8 Overview",
        linkUrl: "https://info.cltexam.com/hubfs/Exam%20info/Assessments%20for%20Grades%203-8%20Two-Page%20Overview.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "CLT3-8 Test Comparisons",
        linkUrl: "https://info.cltexam.com/hubfs/CLT3-6/CLT3-8%20Test%20Comparisons.pdf",
        resourceType: "DOCUMENT",
      },
    ]
  },
  {
    resources: [
      {
        title: "CLT3-6 Assessment Content Brief",
        linkUrl: "https://info.cltexam.com/hubfs/Exam%20info/Assessment%20Content%20Brief%20for%20CLT3-6.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "CLT7 Assessment Content Brief",
        linkUrl: "https://info.cltexam.com/hubfs/Exam%20info/Assessment%20Content%20Brief%20for%20CLT7.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "CLT8 Assessment Content Brief",
        linkUrl: "https://info.cltexam.com/hubfs/Exam%20info/Assessment%20Content%20Brief%20for%20CLT%2c%20CLT10%2c%20and%20CLT8.pdf",
        resourceType: "DOCUMENT",
      },
    ]
  },
  {
    resources: [
      {
        title: "Understanding Scoring: Old CLT8 vs. New CLT8",
        linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/clt8_conversion_tables.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "Testing In Progress Sign",
        linkUrl: "https://info.cltexam.com/hubfs/Ops%20and%20CS/Schools%20Team/Uncategorized/Test%20Day%20Signs.pdf",
        resourceType: "DOCUMENT",
      },
      {
        title: "CLT Lexile Book List",
        linkUrl: "https://info.cltexam.com/hubfs/Sales/CLT3-6/Lexile%20Book%20List%20for%20Assessments%20for%20Grades%203-6.pdf",
        resourceType: "DOCUMENT",
      },
    ]
  },
];

export const sampleTests: SampleTestPdfInfo[] = [
  {
    testType:"clt3",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-8/CLT3%20Sample%20Test.pdf",
  },
  {
    testType:"clt4",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-6/CLT4%20Sample%20Testlet.pdf",
  },
  {
    testType:"clt5",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-6/CLT5%20Sample%20Testlet.pdf",
  },
  {
    testType:"clt6",
    linkUrl: "https://info.cltexam.com/hubfs/CLT3-6/CLT6%20Sample%20Testlet.pdf",
  },
  {
    testType:"clt8",
    linkUrl: "https://info.cltexam.com/hubfs/Ops%20and%20CS/CLT8%20Practice%20Exam%20with%20Answer%20Key.pdf",
  },
];
