import { Transition } from "@headlessui/react";
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

interface Props {
  text: string;
  successText?: string;
}

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export default function CopyTextButton({ text, successText = "Copied!" }: Props) {
  const
    [showSuccess, setShowSuccess] = useState(false),
    handleClick = async () => {
      if (showSuccess) return;
      
      await copyToClipboard(text);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 1200);
    };

  return (
    <div className="flex items-center gap-1">
      <button onClick={handleClick} className="p-1.5 rounded-minimal aspect-square text-text-secondary transition-colors bg-clt-medium-gray/60 hover:bg-clt-medium-gray">
        {showSuccess
          ? <ClipboardDocumentCheckIcon strokeWidth={2} className="h-4 w-4" />
          : <ClipboardDocumentIcon strokeWidth={2} className="h-4 w-4" />
        }
      </button>
      <Transition
        as="span"
        className="text-sm text-text-secondary"
        show={showSuccess}
        enter="transition"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transtion duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {successText}
      </Transition>
    </div>
  );
}
