import type { ResourceCardInfo } from "./content";
import { ReactComponent as DocumentSvg } from "./svg/document.svg";
import { ReactComponent as TableDocumentSvg } from "./svg/table.svg";
import { ReactComponent as ColumnsDocumentSvg } from "./svg/columns.svg";
import { useMemo } from "react";

const previewTypeSvgMap: { [P in ResourceCardInfo["imageType"]]: typeof DocumentSvg } = {
  "DOCUMENT": DocumentSvg,
  "TABLE": TableDocumentSvg,
  "COLUMNS": ColumnsDocumentSvg,
};

export default function ResourceCard({ title, linkUrl, description, imageType: previewType }: ResourceCardInfo) {
  const DocumentPreview = useMemo(() => previewTypeSvgMap[previewType], [previewType]);

  return (
    // NB. 2.82rem is the amount added by the "hover to reveal" link buttons. The extra margin is needed
    // to avoid reflow moving content around when the card is expanded.
    <div className="mb-[2.82rem] m-1.5 group transition-[margin-bottom] hover:mb-0 focus-within:mb-0">
      <a href={linkUrl} target="_blank" rel="noreferrer" className="outline-none">
        <div className="bg-clt-white rounded-extra-rounded w-64 p-5 transition group-hover:shadow-lg group-focus-within:shadow-lg group-hover:scale-[102%] group-hover:z-10 group-focus-within:ring ring-offset-1">
          <div className="relative overflow-clip bg-clt-light-gray h-24 mb-2 rounded-rounded transition group-hover:bg-clt-light-collegiate-blue group-focus-within:bg-clt-light-collegiate-blue">
            <DocumentPreview className="absolute bottom-0 left-0 right-0 mx-auto w-full transform translate-y-3 transition group-hover:translate-y-0 group-focus-within:translate-y-0" />
          </div>

          <h2 className="text-text-primary text-lg font-bold leading-tight">{title}</h2>
          {description && <p className="mt-1 text-text-secondary text-sm leading-snug">{description}</p>}

          {/* NB. "grid*" classes here are a method of smoothly transitioning the container height to show/hide the arrow icon. */}
          <div className="grid grid-rows-[0fr] group-hover:grid-rows-[1fr] group-focus-within:grid-rows-[1fr] transition-[grid-template-rows]">
            <div className="overflow-hidden outline-none max-w-fit">
              <div className="bg-clt-light-gray text-text-secondary rounded-minimal p-3 mt-2 max-w-min">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.6219 9.32235L12.6219 2.62748C12.6076 2.01313 12.3571 1.42792 11.9226 0.993387C11.488 0.558856 10.9028 0.308397 10.2885 0.294025L3.5936 0.294025C3.32837 0.293962 3.07397 0.399268 2.88637 0.586776C2.69877 0.774283 2.59335 1.02863 2.59328 1.29387C2.59322 1.55911 2.69853 1.81351 2.88604 2.00111C3.07354 2.18871 3.3279 2.29413 3.59313 2.29419L9.21461 2.29467L0.915216 10.6157C0.72768 10.8033 0.622323 11.0576 0.622323 11.3229C0.622323 11.5881 0.72768 11.8424 0.915217 12.03C1.10275 12.2175 1.35711 12.3229 1.62232 12.3229C1.88754 12.3229 2.14189 12.2175 2.32943 12.03L10.6199 3.71784L10.6218 9.32282C10.6263 9.58499 10.7337 9.83487 10.9207 10.0187C11.1077 10.2025 11.3594 10.3054 11.6216 10.3054C11.8838 10.3054 12.1355 10.2025 12.3225 10.0187C12.5096 9.83488 12.6174 9.58452 12.6219 9.32235Z" fill="currentColor"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
