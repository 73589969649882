export const formulas = {
  clt3: <div>
    <div className="font-sans text-gray-800 ml-6">
      <section className="mb-12">
        <h2 className="text-2xl mb-4">LENGTH</h2>
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-1">English System</h3>
          <ul className="list-none space-y-1">
            <li>1 mile (mi) = 1,760 yards (yd)</li>
            <li>1 yard (yd) = 3 feet (ft)</li>
            <li>1 foot (ft) = 12 inches (in.)</li>
          </ul>
        </div>
        <div>
          <h3 className="font-semibold text-lg mb-1">Metric System</h3>
          <ul className="list-none space-y-1">
            <li>1 kilometer (km) = 1,000 meters (m)</li>
            <li>1 meter (m) = 100 centimeters (cm)</li>
            <li>1 centimeter (cm) = 10 millimeters (mm)</li>
          </ul>
        </div>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl mb-4">WEIGHT AND MASS</h2>
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-1">English System</h3>
          <ul className="list-none space-y-1">
            <li>1 pound (lb) = 16 ounces (oz)</li>
          </ul>
        </div>
        <div>
          <h3 className="font-semibold text-lg mb-1">Metric System</h3>
          <ul className="list-none space-y-1">
            <li>1 kilogram (kg) = 1,000 grams (g)</li>
          </ul>
        </div>
      </section>
      <section>
        <h2 className="text-2xl mb-4">TIME</h2>
        <ul className="list-none space-y-1">
          <li>1 year = 12 months</li>
          <li>1 year = 52 weeks</li>
          <li>1 week = 7 days</li>
          <li>1 day = 24 hours</li>
          <li>1 hour = 60 minutes</li>
          <li>1 minute = 60 seconds</li>
        </ul>
      </section>
    </div>

  </div>,
  clt7: <div>
    <ul className="list-none space-y-8 ml-2">
      <li>
        Area of a circle = {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mi>π</mi>
            <msup>
              <mi>r</mi>
              <mn>2</mn>
            </msup>
          </mrow>
        </math>
        , where {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mi>r</mi>
          </mrow>
        </math>
        {" "}is the radius of the circle
      </li>
      <li>
        Circumference of a circle = {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mn>2</mn>
            <mi>π</mi>
            <mi>r</mi>
          </mrow>
        </math>
        , where {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mi>r</mi>
          </mrow>
        </math>
        {" "}is the radius of the circle
      </li>
      <li>There are 360 degrees in a circle.</li>
      <li>
        There are {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mn>2</mn>
            <mi>π</mi>
          </mrow>
        </math>
        {" "}radians in a circle.
      </li>
      <li>
        Volume of a sphere = {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mfrac>
            <mrow>
              <mn>4</mn>
            </mrow>
            <mrow>
              <mn>3</mn>
            </mrow>
          </mfrac>
          <mo>π</mo>
          <msup>
            <mi>r</mi>
            <mn>3</mn>
          </msup>
        </math>
        , where {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mi>r</mi>
          </mrow>
        </math>
        {" "}is the radius of the sphere
      </li>
      <li>
        Surface area of a sphere = {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mn>4</mn>
          <mo>π</mo>
          <msup>
            <mi>r</mi>
            <mn>2</mn>
          </msup>
        </math>
        , where {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mi>r</mi>
          </mrow>
        </math>
        {" "}is the radius of the sphere
      </li>
      <li>Area of a rectangle = length × width</li>
      <li>
        Area of a triangle = {" "}
        <math xmlns="http://www.w3.org/TR/MathML">
          <mrow>
            <mfrac>
              <mrow>
                <mn> 1</mn>
              </mrow>
              <mrow>
                <mn> 2</mn>
              </mrow>
            </mfrac>
          </mrow>
        </math>
        {" "}(base × height)
      </li>
      <li>The sum of the measures of the interior angles of a triangle is 180°.</li>
      <li>
        Pythagorean theorem (for a right triangle): If <i>a</i>, <i>b</i>, and <i>c</i> are the side lengths of the
        triangle, and <i>c</i> is the hypotenuse,
        then <i>a</i><sup>2</sup> + <i>b</i><sup>2</sup> = <i>c</i><sup>2</sup>.
      </li>
      <li>
        <math xmlns="http://www.w3.org/TR/MathML">
          <mn>30</mn>
          <mo>&deg;</mo>
          <mo>–</mo>
          <mn>60</mn>
          <mo>&deg;</mo>
          <mo>–</mo>
          <mn>90</mn>
          <mo>&deg;</mo>
        </math>
        &nbsp;triangles have side lengths in a ratio of&nbsp;
        <math xmlns="http://www.w3.org/TR/MathML">
          <mn>1</mn>
          <mo>&nbsp;:&nbsp;</mo>
          <msqrt>
            <mn>3</mn>
          </msqrt>
          <mo>&nbsp;:&nbsp;</mo>
          <mn>2</mn>
        </math>
        , corresponding to their opposite angle.
      </li>
      <li>
        <math xmlns="http://www.w3.org/TR/MathML">
          <mn>45</mn>
          <mo>&deg;</mo>
          <mo>–</mo>
          <mn>45</mn>
          <mo>&deg;</mo>
          <mo>–</mo>
          <mn>90</mn>
          <mo>&deg;</mo>
        </math>
        &nbsp;triangles have side lengths in a ratio of&nbsp;
        <math xmlns="http://www.w3.org/TR/MathML">
          <mn>1</mn>
          <mo>&nbsp;:&nbsp;</mo>
          <mn>1</mn>
          <mo>&nbsp;:&nbsp;</mo>
          <msqrt>
            <mn>2</mn>
          </msqrt>
        </math>
        , corresponding to their opposite angle.
      </li>
    </ul>
  </div>
};