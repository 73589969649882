import { Link } from "react-router-dom";
import { classNames } from "../shared/utils";

export default function NavigationToggle({ activeGrade }: { activeGrade: "LOWER" | "UPPER" }) {
  return <div className="flex font-btn text-sm text-center">
    <Link
      to="/manager"
      className={classNames(
        "flex-1 p-2 rounded-l-rounded",
        activeGrade === "LOWER"
          ? "bg-clt-collegiate-blue text-text-inverted"
          : "bg-clt-light-gray text-text-secondary hover:bg-clt-medium-gray",
      )}
    >
      CLT3-8
    </Link>
    <Link
      to="/"
      className={classNames(
        "flex-1 p-2 rounded-r-rounded",
        activeGrade === "UPPER"
          ? "bg-cltblue text-text-inverted"
          : "bg-clt-light-gray text-text-secondary hover:bg-clt-medium-gray",
      )}
    >
      CLT10 & CLT
    </Link>
  </div>;
}
