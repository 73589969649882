import { TestType } from "../services/gen/authorize";

const colors = {
    primary_bg: "bg-sky-900",
    primary_hover: "bg-sky-800",
    primary_ring: "ring-sky-800",
    primary_text: "text-white",
    secondary_bg: "bg-stone-300",
    secondary_hover: "bg-stone-100",
    secondary_text: "text-stone-900",
    secondary_ring: "ring-stone-100",
    text_active: "text-white",
    text_inactive: "text-slate-200",
    errorRed: "bg-red-600",
};

export const testColors: {[k in TestType]: { text: string, bg: string, border: string }} = {
    "clt3": {
        text: "text-clt3",
        bg: "bg-clt3",
        border: "border-clt3",
    },
    "clt4": {
        text: "text-clt4",
        bg: "bg-clt4",
        border: "border-clt4",
    },
    "clt5": {
        text: "text-clt5",
        bg: "bg-clt5",
        border: "border-clt5",
    },
    "clt6": {
        text: "text-clt6",
        bg: "bg-clt6",
        border: "border-clt6",
    },
    "clt7": {
        text: "text-clt7",
        bg: "bg-clt7",
        border: "border-clt7",
    },
    "clt8": {
        text: "text-clt8",
        bg: "bg-clt8",
        border: "border-clt8",
    },
    "clt10": {
        text: "text-clt10",
        bg: "bg-clt10",
        border: "border-clt10",
    },
    "clt": {
        text: "text-clt",
        bg: "bg-clt",
        border: "border-clt",
    },
};

// TODO these aren't just colors... hmm
export const elementClasses = {
    button: `inline-flex items-center justify-center rounded-md border border-transparent ${colors.primary_bg} px-4 py-2 text-sm font-medium text-white shadow-sm hover:${colors.primary_hover} focus:outline-none focus:ring-2 focus:${colors.text_active} focus:ring-offset-2 sm:w-auto`, 
    buttonSecondary: `inline-flex items-center justify-center rounded-md border border-transparent ${colors.secondary_bg} ${colors.secondary_text} px-4 py-2 text-sm font-medium text-white shadow-sm hover:${colors.primary_hover} focus:outline-none focus:ring-2 focus:${colors.text_active} focus:ring-offset-2 sm:w-auto`, 
    input: `shadow-sm focus:${colors.primary_ring} block w-full sm:text-sm border-red-300 rounded-md"`,
};

export default colors;