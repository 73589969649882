import { Profile } from "../../shared/types";


export const convertGoogleAddressToFormFields = (googleAddressObj: google.maps.places.PlaceResult): {
  mail1: string,
  mail2: string,
  city: string,
  state: string,
  zip: string,
  country: string
} => {

  const pickValue = function (addressComponent: string) {
    const c = googleAddressObj?.address_components?.find(function(c) { return c.types.includes(addressComponent); });
    if (!c) {
      return "";
    }

    // google strips 'apt' and 'unit' prefixes on a subpremise that is a number, so we prefix with a # as a catchall
    const numPrefix = (addressComponent === "subpremise" && !isNaN(Number(c.short_name))) ? "#" : "";

    return `${numPrefix}${c.short_name}`;
  },

  // a list of all the possible component types is here: https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
   streetNumber = pickValue("street_number"),
    route = pickValue("route"),
    floor = pickValue("floor"),
    postBox = pickValue("post_box"),
    premise = pickValue("premise"),  // e.g. name of apartment complex or building
    subpremise = pickValue("subpremise"), // e.g. apartment number
    locality = pickValue("locality"),  // usually 'city' but sometimes the following two are reported
    sublocality = pickValue("sublocality"),
    neighborhood = pickValue("neighborhood"),
    state = pickValue("administrative_area_level_1"),  // obviously
    zip = pickValue("postal_code"),
    country = pickValue("country"),

   mail2 = [premise, subpremise, floor, postBox ]
    .filter(function(s) { return s !== "";})
    .join(" "),

   city = [neighborhood, sublocality, locality]
     .filter(s => s !== "")
     .join(" ");

  // TODO handle an invalid address ?
  return {
    mail1: `${streetNumber ? streetNumber + " " : ""}${route}`,
    mail2,
    city,
    state,
    zip,
    country
  };
};

export const getAddressAsString = (formValues: Profile) =>
  `${formValues.mail1 ? formValues.mail1 + " " : ""}${formValues.mail2 ? formValues.mail2 + " " : ""}${formValues.city ? formValues.city + "," : ""} ${formValues.country === "US" ? formValues.state : formValues.region_intl} ${formValues.zip} ${formValues.country}`
  .trim();