import { TestType } from "../../services/gen/authorize";

type HeaderProps = {
  isTest?: boolean
  testType: TestType
}

export function Header({ testType }: HeaderProps) {
    return <header className="flex border-b border-gray-200 h-20 bg-white items-center sticky top-0 z-10 animate-fadeIn text-lg">
      <div className="opacity-0 bg-clt-primary-teal"/>
      <div className="flex-1 flex items-center">
        <div className="flex justify-center items-center h-16 w-20 border-r border-gray-200">
          <svg className={`fill-current text-${testType}`} width="32" height="38"
               viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 37.4401H32V0H0V37.4401ZM12.1881 32.914H10.1603V23.4647C10.1603 23.4647 12.1881 23.6938 12.1881 25.1854V32.914ZM17.2583 32.914H15.2301V23.4647C15.2301 23.4647 17.2583 23.6938 17.2583 25.1854V32.914ZM22.1021 32.914H20.0738V23.4647C20.0738 23.4647 22.1021 23.6938 22.1021 25.1854V32.914ZM25.3383 21.7466L6.92399 21.7463V19.6717H25.3383V21.7466ZM28.6598 17.6332H3.33981V15.5584H11.7197C13.0939 15.5584 14.7945 16.8295 14.7945 16.8295L6.4638 8.30734L7.89768 6.84029L14.9861 14.0915V3.6444H17.0141V14.0915L24.1011 6.84116L25.535 8.30803L17.2055 16.8295C17.2055 16.8295 18.9061 15.5584 20.28 15.5584H28.6598V17.6332Z"
            />
          </svg>
        </div>

        <div className="flex justify-center items-center h-16 w-20 border-r border-gray-200">
          {testType.toUpperCase()}
        </div>
      </div>

      <div className="flex-1 text-center text-text-secondary">Welcome to the {testType.toUpperCase()}</div>

      <div className="flex-1 text-right flex justify-end">
        <div className="flex justify-center items-center h-16 w-16 border-l-0 border-gray-200">
          &nbsp;
        </div>
      </div>
    </header>;
}