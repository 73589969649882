import { useState } from "react";
import Switch from "./Switch";

export type ToggleProps = {
  label: string
  id: string
  initialValue?: boolean
  disabled?: boolean
  handleChange?: (val: boolean) => void
  handleSave?: (id: string, val: string) => void
  displaySwitch?: boolean
};

export default function Toggle({ disabled = false, label, id, initialValue = false, handleChange, handleSave, displaySwitch = true }: ToggleProps) {
  const [value, setValue] = useState<boolean>(initialValue),

   opts = {
    onChange() {
      const newValue = !value;
      setValue(newValue);
      handleChange && handleChange(newValue);
      handleSave && handleSave(id, newValue ? "true" : "false");
    },
    type: "checkbox",
    id,
    checked: value,
    disabled,
  },

   switchOpts = {
    className: "align-middle",
    enabled: value,
    setEnabled(val: boolean) {
      setValue(val);
      handleChange && handleChange(val);
      handleSave && handleSave(id, val ? "true" : "false");
    }
  };

  return <div className="flex flex-col justify-between max-h-full m-4 mb-0">
    <div>
      {displaySwitch ? <Switch {...switchOpts}/> : <input {...opts} /> }
      <label className="pl-3 align-middle" htmlFor={id}>{label}</label>
    </div>
  </div>;
}
