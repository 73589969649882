import { FunctionComponent, useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import welcomeBackground from "../../assets/welcome_background.png";
import { useExamContext } from "../../Exam";
import { useStudentApiStartSectionMutation } from "../../services/gen/studentApi";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/Modal";
import { classNames } from "../../shared/utils";

export const Welcome: FunctionComponent = () => {
  const navigate = useNavigate(),
    { testType, testSections, activeOrNextSection } = useExamContext(),
    [startSection, { isSuccess: sectionStartSuccessful, isError: sectionStartError }] = useStudentApiStartSectionMutation(),
    showWelcomeHeader = activeOrNextSection
      && activeOrNextSection.sectionNumber === 1
      && activeOrNextSection.progressStatus === "UNSTARTED";

  useEffect(() => {
    if (sectionStartSuccessful) {
      navigate("/exam/questions");
    }
  }, [sectionStartSuccessful]);

  if (sectionStartError) {
    return <Modal heading="Something went wrong!" subheading="Click OK to refresh and try again." modalType="ERROR" showCancel={false} onOK={() => { navigate(0); }}/>;
  }

  return <div
    style={{ backgroundImage: `url(${welcomeBackground})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
    className={classNames(
      "w-full bg-surface-brand flex flex-col space-y-0 h-full items-center overflow-y-auto",
      showWelcomeHeader ? "" : "pt-20",
    )}>

    {showWelcomeHeader &&
      <div
        className={classNames(
          "w-11/12 min-w-md p-10 my-6 rounded-extra-rounded text-center font-serif",
          `bg-${testType}/75`,
          testType === "clt3" ? "text-text-primary" : "text-text-inverted",
        )}
      >
        <h1>Welcome to the {testType.toUpperCase()}!</h1>
        <p>You are about to begin the exam.</p>
      </div>
    }

    <div className="w-1/2 p-20 flex max-h-[60rem] min-h-fit flex-col min-w-fit bg-white bg-opacity-10 mb-80 backdrop-blur-sm">
      <p className="relative text-left text-white text-4xl font-serif mb-10">{testType.toUpperCase()}</p>
      {testSections.map((section, index) =>
        <div key={index} className="flex text-white text-lg font-exam mb-3 pt-4 whitespace-nowrap relative border-t-2 border-white border-opacity-10">
          {section.progressStatus === "COMPLETED" ?
            <CheckCircleIcon className="w-6 h-6 text-green-400 mr-2 min-w-[30px]"/> :
            <div className="w-6 h-6 mr-2 min-w-[30px]">&nbsp;</div>}
            <b>Section {index + 1}</b>: {section.name}{` - ${section.questionCount} ${section.questionCount === 1 ? "question" : "questions"}`}
        </div>)
      }

      {activeOrNextSection
        ? <div>
            <p className="text-white text-2xl mt-10 italic">Do not begin the next section until your teacher tells you.</p>

            <p className="text-white text-2xl mt-5 italic">Once you begin this section, you must complete it in one sitting.</p>

            <button
              className={`mt-8 whitespace-nowrap rounded-md bg-cyan-900 text-white px-3.5 py-2.5 text-lg font-semibold shadow-sm hover:bg-${testType} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 hover:bg-clt-medium-collegiate-blue`}
              onClick={
                () => {
                  localStorage.setItem("currentQuestionIndex", "0");
                  startSection({ sectionNumber: activeOrNextSection.sectionNumber });
                }}>

              <div className="flex items-center font-bold uppercase">
                <p>{activeOrNextSection.progressStatus === "STARTED" ? "Continue " : "Begin "} Section {activeOrNextSection.sectionNumber}</p>
                <ArrowRightIcon className="ml-2 w-8 h-8"/>
              </div>
            </button>

            <p className="text-white mt-16 pt-4 border-t-2 border-white border-opacity-10">Or &nbsp;
              <span className="underline hover:cursor-pointer" onClick={() => window.location.pathname = "/exam/begin"}>click here</span>
              &nbsp; if you are done with your testing for today.
              {/* NB: we do _not_ use Link here on purpose. We clear the RTK query cache with a full page reload on return to /exam/begin. */}
            </p>
        </div>
        : <div className="w-full text-center mt-16">
            <p className="text-white text-5xl font-exam mt-16"> 🎉 You finished! 🎉 </p>
            <div className="leading-relaxed text-white text-2xl font-exam mt-16 pb-32">
              <p>Congratulations on completing your exam.</p>
              <p>You may now close this page.</p>
              <p className="text-white">
                {/*Session is cleared when routing to /exam/begin*/}
                <span className="underline hover:cursor-pointer" onClick={() => window.location.pathname = "/exam/begin"}>Exit test</span>
              </p>
            </div>
        </div>
      }
    </div>
  </div>;
};
