import "./index.css";
import { Outlet } from "react-router-dom";
import VerifyAuth from "./components/VerifyAuth";
import Navigation from "./components/Navigation";

interface ManagerAppProps {
  isTest?: boolean;
}

export default function App({ isTest = false }: ManagerAppProps) {
  return (
    <VerifyAuth isTest={isTest} child={
      <div className="flex w-full min-h-full">
        <div className="flex-initial w-52 print:hidden">
          <Navigation />
        </div>
        <div className="flex-1 bg-clt-light-gray print:max-w-screen-sm print:min-w-max">
          <Outlet />
        </div>
      </div>
    } />
  );
}
