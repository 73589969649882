import { emptyApi as api } from "../apiCodeGenRoot";
export const addTagTypes = [] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      authorizeApiAuthorizeUser: build.mutation<
        AuthorizeApiAuthorizeUserApiResponse,
        AuthorizeApiAuthorizeUserApiArg
      >({
        query: () => ({ url: `/authorize/user`, method: "POST" }),
      }),
      authorizeApiAuthorizeTaker: build.mutation<
        AuthorizeApiAuthorizeTakerApiResponse,
        AuthorizeApiAuthorizeTakerApiArg
      >({
        query: (queryArg) => ({
          url: `/authorize/taker`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      authorizeApiLogout: build.query<
        AuthorizeApiLogoutApiResponse,
        AuthorizeApiLogoutApiArg
      >({
        query: () => ({ url: `/authorize/logout` }),
      }),
      authorizeApiPostAccessCode: build.mutation<
        AuthorizeApiPostAccessCodeApiResponse,
        AuthorizeApiPostAccessCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/authorize/accessCode`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type AuthorizeApiAuthorizeUserApiResponse = unknown;
export type AuthorizeApiAuthorizeUserApiArg = void;
export type AuthorizeApiAuthorizeTakerApiResponse = unknown;
export type AuthorizeApiAuthorizeTakerApiArg = {
  body: {
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    age?: number;
    birthMonthZeroBased?: number;
    birthDay?: number;
  };
};
export type AuthorizeApiLogoutApiResponse = unknown;
export type AuthorizeApiLogoutApiArg = void;
export type AuthorizeApiPostAccessCodeApiResponse = /** status 200 Success */ {
  testTitle?: TestType;
};
export type AuthorizeApiPostAccessCodeApiArg = {
  body: {
    accessCode?: string;
  };
};
export type TestType =
  | "clt3"
  | "clt4"
  | "clt5"
  | "clt6"
  | "clt7"
  | "clt8"
  | "clt10"
  | "clt";
export const {
  useAuthorizeApiAuthorizeUserMutation,
  useAuthorizeApiAuthorizeTakerMutation,
  useAuthorizeApiLogoutQuery,
  useLazyAuthorizeApiLogoutQuery,
  useAuthorizeApiPostAccessCodeMutation,
} = injectedRtkApi;
