import "./index.css";
import {
  Outlet, useLocation, useNavigate, useOutletContext
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  type Passage,
  type Question,
  type Section,
  useStudentApiGetFormQuery,
  useStudentApiGetSectionQuery
} from "./services/gen/studentApi";
import { type TestType } from "./services/gen/authorize";
import Modal from "./components/Modal";
import LoadingOverlay from "./components/LoadingOverlay";
import { useKeyboardEvent } from "./KeyboardProvider";

type ExamContextType = {
  passage?: Passage,
  question?: Question,
  testType: TestType,
  testSections: Section[],
  allQuestions: Question[],
  activeOrNextSection?: Section,
  advanceQuestion?: () => void,
  revisitQuestion?: () => void,
  unansweredQuestionCount: number,
  hasFormulas: boolean,
  takingId: number
};

function Exam() {
  const findCurrentSection = (sections: Section[]) => sections.find(section => section.progressStatus !== "COMPLETED"),
    location = useLocation(),
    navigate = useNavigate(),
    {
      data ,
      isSuccess: isSuccessFormDataLoaded,
      isError: isFormDataLoadError
    } = useStudentApiGetFormQuery(),
    takingId = data?.takingId,
    formData = data?.formData,
    activeOrNextSection = findCurrentSection(formData?.sections || []),
    isNewTest = formData?.sections?.every(section => section.progressStatus === "UNSTARTED"),
    allSectionsComplete = formData && formData.sections?.every(section => section.progressStatus === "COMPLETED"),
    currentSectionNumber = activeOrNextSection?.sectionNumber,
    {
      data: sectionData,
      isSuccess: isSuccessSectionDataLoaded,
      isError: isSectionDataLoadError,
      isLoading: isSectionDataLoading,
      isFetching: isSectionDataFetching
    } = useStudentApiGetSectionQuery({ sectionNumber: Number(currentSectionNumber) }, { skip: (!data?.formData || !currentSectionNumber) }),
    [questions, setQuestions] = useState<Question[]>([]),
    [questionIds, setQuestionIds] = useState<number[]>([]),
    [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0),
    [currentQuestionId, setCurrentQuestionId] = useState<number>(),
    [currentQuestion, setCurrentQuestion] = useState<Question>(),
    [answerCount, setAnswerCount] = useState<number>(0),
    isError = isFormDataLoadError || isSectionDataLoadError,
    isLoading = !isSuccessFormDataLoaded || !isSuccessSectionDataLoaded || !data?.formData || !sectionData || !questionIds || !currentQuestionId || !currentQuestion || !questions,
    currentPassage = sectionData?.passages?.find(passage => passage.id === currentQuestion?.passageId),
    [showSpinner, setShowSpinner] = useState(false),
    unansweredQuestionCount = questions.length - answerCount;

  useEffect(() => {
    if (isSuccessSectionDataLoaded && !isSectionDataLoading && !isSectionDataFetching && sectionData?.questions) {
      if (isNewTest) localStorage.setItem("currentQuestionIndex", "0");
      const localStorageQuestionIndex = Number(localStorage.getItem("currentQuestionIndex") || "0"),
      newCurrentQuestionIndex = sectionData.questions[localStorageQuestionIndex] ? localStorageQuestionIndex : 0;
      setCurrentQuestionIndex(newCurrentQuestionIndex);
      setCurrentQuestionId(sectionData.questions[newCurrentQuestionIndex].id);
      setCurrentQuestion(sectionData.questions[newCurrentQuestionIndex]);
    }
  }, [isSuccessSectionDataLoaded, isSectionDataLoading, isSectionDataFetching, sectionData?.id]);

  useEffect(() => {
    if (sectionData?.questions) {
      setQuestions([...sectionData?.questions || []].sort((a, b) => a.seq - b.seq)),
      setQuestionIds(sectionData.questions.map(question => question.id));
      setAnswerCount(sectionData.questions.filter(question => question.studentAnswer).length);
    }
  }, [sectionData?.questions]);  // Pick up changes from the optimistic cache update when a student changes an answer

  useKeyboardEvent(["Enter", "ArrowRight"], () => {
    advanceQuestion();
  });

  useKeyboardEvent(["ArrowLeft"], () => {
    revisitQuestion();
  });

  let showQuestionCount = false,
    heading = "";

  if (location.pathname.match(/\/exam\/welcome/) && formData) {
    showQuestionCount = false;
    heading = `Welcome to the ${formData?.blueprintName.toUpperCase() ?? "CLT"}!`;
  } else if (location.pathname.match(/\/exam\/questions/)) {
    showQuestionCount = true;
    heading = sectionData?.name ? `Section ${currentSectionNumber}: ${sectionData.name}` :"";
  }

  if (isError) {
    return <Modal heading="Something went wrong!"
                  subheading="Click OK to refresh and try again.  Or click 'Start over' to start from the beginning."
                  modalType="ERROR"
                  showCancel={true}
                  cancelButtonText="Start over"
                  onOK={() => { navigate(0); }}
                  onCancel={() => { localStorage.setItem("currentQuestionIndex", "0"); navigate("/exam/begin"); }}
    />;
  }

  if ((isLoading && !allSectionsComplete) || !formData) {
    if (!showSpinner) setShowSpinner(true);
  } else {
    if (showSpinner) setShowSpinner(false);
  }

  const advanceQuestion = () => {
      const nextQuestionIndex = currentQuestionIndex + 1,
        nextQuestionId = questionIds[nextQuestionIndex];

      if (questionIds.find(id => id === nextQuestionId)) {
        localStorage.setItem("currentQuestionIndex", nextQuestionIndex.toString());
        setCurrentQuestionId(nextQuestionId);
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(questions.find(question => question.id === nextQuestionId));
      }
    },

    revisitQuestion = () => {
      const nextQuestionIndex = currentQuestionIndex - 1,
        nextQuestionId = questionIds[nextQuestionIndex];

      if (questionIds.find(id => id === nextQuestionId)) {
        localStorage.setItem("currentQuestionIndex", nextQuestionIndex.toString());
        setCurrentQuestionId(nextQuestionId);
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(questions.find(question => question.id === nextQuestionId));
      }
    },
    testType = formData?.blueprintName as TestType ?? "";

  // TODO: this top section should use the Header component (which needs to support the answer count etc)
  return (
    <>
      <div className="w-full h-full overflow-hidden">
        <header className="flex border-b border-gray-200 h-20 items-center sticky top-0 z-10 bg-white text-lg">
          <div className="flex-1 flex items-center">
            <div className="flex justify-center items-center h-16 w-20 border-r border-gray-200">
              <svg className={`fill-current text-${testType}`} width="32" height="38"
                   viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0 37.4401H32V0H0V37.4401ZM12.1881 32.914H10.1603V23.4647C10.1603 23.4647 12.1881 23.6938 12.1881 25.1854V32.914ZM17.2583 32.914H15.2301V23.4647C15.2301 23.4647 17.2583 23.6938 17.2583 25.1854V32.914ZM22.1021 32.914H20.0738V23.4647C20.0738 23.4647 22.1021 23.6938 22.1021 25.1854V32.914ZM25.3383 21.7466L6.92399 21.7463V19.6717H25.3383V21.7466ZM28.6598 17.6332H3.33981V15.5584H11.7197C13.0939 15.5584 14.7945 16.8295 14.7945 16.8295L6.4638 8.30734L7.89768 6.84029L14.9861 14.0915V3.6444H17.0141V14.0915L24.1011 6.84116L25.535 8.30803L17.2055 16.8295C17.2055 16.8295 18.9061 15.5584 20.28 15.5584H28.6598V17.6332Z"
                />
              </svg>
            </div>

            <div className="flex justify-center items-center h-16 w-20 border-r border-gray-200">
              <p>{testType.toUpperCase()}</p>
            </div>
          </div>

          <div className="flex-1 text-center">{heading}</div>

          <div className="flex-1 text-right flex justify-end">
            {showQuestionCount
              ? <div className="flex-1 bg-white align-right mr-6">
                <dt className="truncate text-sm font-medium text-gray-500">Unanswered questions</dt>
                <dd className="text-sm font-semibold tracking-tight text-gray-900">{unansweredQuestionCount}</dd>
              </div>
              : <div className="mr-4">{formData?.takerFirstName}</div> }
          </div>
        </header>
        <main className="w-full h-full z-0 bg-white overflow-hidden" >
          { (formData && takingId && !showSpinner)
            ? <Outlet
            context={{
              passage: currentPassage,
              question: currentQuestion,
              allQuestions: questions,
              testType,
              testSections: formData?.sections ?? [],
              activeOrNextSection,
              advanceQuestion: currentQuestionIndex < questions.length - 1 ? advanceQuestion : undefined,
              revisitQuestion: currentQuestionIndex > 0 ? revisitQuestion : undefined,
              unansweredQuestionCount,
              hasFormulas: /quantitative/i.test(sectionData?.name || ""),
              takingId
            } satisfies ExamContextType}/>
            : <LoadingOverlay/>
          }
        </main>
      </div>
    </>
  );
}

export default Exam;

export function useExamContext() {
  return useOutletContext<ExamContextType>();
}