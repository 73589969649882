type IntegerScaleProps = {
  label?: string;
  value: number;
  valueSuffix?: string;
  rangeLower: number;
  rangeUpper: number;
  colorClass?: string;
  category: "content" | "reasoning" | "secondary" | "fundamentals";
  scaleType?: "integer" | "percent";
  className?: string;
}

export default function CategoryScale({ value, rangeLower, rangeUpper, label, colorClass = "clt-primary-orange", category, className = "", scaleType = "integer" }: IntegerScaleProps) {

  const numbers = Array.from({ length: rangeUpper }, (_, i) => i + 1),
    icons = {
      content: <svg width="32" height="32" viewBox="0 0 32 32" className={`text-${colorClass}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"/>
        <path d="M23 22H11.6667C11.2246 22 10.8007 21.8244 10.4882 21.5118C10.1756 21.1993 10 20.7754 10 20.3333V9C10 8.73478 9.89464 8.48043 9.70711 8.29289C9.51957 8.10536 9.26522 8 9 8C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9V20.3333C8.00106 21.3055 8.38771 22.2375 9.07511 22.9249C9.76252 23.6123 10.6945 23.9989 11.6667 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23C24 22.7348 23.8946 22.4804 23.7071 22.2929C23.5196 22.1054 23.2652 22 23 22Z"/>
        <path d="M22.9998 10.6508H20.9425C20.8106 10.6508 20.6818 10.6899 20.5722 10.7632C20.4626 10.8364 20.3771 10.9405 20.3267 11.0623C20.2762 11.1841 20.263 11.3181 20.2888 11.4474C20.3145 11.5767 20.3779 11.6955 20.4711 11.7888L20.9598 12.2768L18.5685 14.6668C18.5375 14.6978 18.5007 14.7224 18.4602 14.7392C18.4197 14.756 18.3763 14.7647 18.3325 14.7647C18.2886 14.7647 18.2452 14.756 18.2047 14.7392C18.1642 14.7224 18.1274 14.6978 18.0965 14.6668L17.9831 14.5534C17.5387 14.1289 16.9478 13.892 16.3331 13.892C15.7185 13.892 15.1276 14.1289 14.6831 14.5534L11.9498 17.2868C11.8543 17.379 11.7781 17.4893 11.7257 17.6114C11.6733 17.7334 11.6457 17.8646 11.6446 17.9974C11.6434 18.1301 11.6687 18.2618 11.719 18.3847C11.7693 18.5076 11.8435 18.6193 11.9374 18.7132C12.0313 18.807 12.143 18.8813 12.2659 18.9316C12.3888 18.9819 12.5204 19.0072 12.6532 19.006C12.786 19.0049 12.9172 18.9773 13.0392 18.9249C13.1612 18.8724 13.2716 18.7963 13.3638 18.7008L16.0971 15.9674C16.1281 15.9364 16.1649 15.9118 16.2054 15.8949C16.2459 15.8781 16.2893 15.8695 16.3331 15.8695C16.377 15.8695 16.4204 15.8781 16.4609 15.8949C16.5014 15.9118 16.5382 15.9364 16.5691 15.9674L16.6825 16.0808C17.1271 16.505 17.718 16.7417 18.3325 16.7417C18.947 16.7417 19.5379 16.505 19.9825 16.0808L22.3731 13.6908L22.8611 14.1794C22.9543 14.2729 23.0732 14.3365 23.2026 14.3624C23.332 14.3882 23.4662 14.3751 23.5881 14.3246C23.71 14.2741 23.8143 14.1886 23.8876 14.0788C23.9608 13.9691 23.9999 13.8401 23.9998 13.7081V11.6508C23.9998 11.3855 23.8944 11.1312 23.7069 10.9437C23.5194 10.7561 23.265 10.6508 22.9998 10.6508Z"/>
      </svg>,
      reasoning: <svg width="32" height="32" viewBox="0 0 32 32"  className={`text-${colorClass}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z" />
        <path d="M20.3333 9H11.6667C10.6946 9.00123 9.76267 9.38794 9.07531 10.0753C8.38794 10.7627 8.00123 11.6946 8 12.6667V18.6667C8.00123 19.6387 8.38794 20.5707 9.07531 21.258C9.76267 21.9454 10.6946 22.3321 11.6667 22.3333H12.6253L15.3587 24.6187C15.5386 24.769 15.7655 24.8514 16 24.8514C16.2345 24.8514 16.4614 24.769 16.6413 24.6187L19.3747 22.3333H20.3333C21.3054 22.3321 22.2373 21.9454 22.9247 21.258C23.6121 20.5707 23.9988 19.6387 24 18.6667V12.6667C23.9988 11.6946 23.6121 10.7627 22.9247 10.0753C22.2373 9.38794 21.3054 9.00123 20.3333 9V9ZM22 18.6667C22 19.1087 21.8244 19.5326 21.5118 19.8452C21.1993 20.1577 20.7754 20.3333 20.3333 20.3333H19.0113C18.7768 20.3335 18.5498 20.4161 18.37 20.5667L16 22.548L13.63 20.5667C13.4502 20.4161 13.2232 20.3335 12.9887 20.3333H11.6667C11.2246 20.3333 10.8007 20.1577 10.4882 19.8452C10.1756 19.5326 10 19.1087 10 18.6667V12.6667C10 12.2246 10.1756 11.8007 10.4882 11.4882C10.8007 11.1756 11.2246 11 11.6667 11H20.3333C20.7754 11 21.1993 11.1756 21.5118 11.4882C21.8244 11.8007 22 12.2246 22 12.6667V18.6667Z" />
        <path d="M13 15H15C15.2652 15 15.5196 14.8946 15.7071 14.7071C15.8946 14.5196 16 14.2652 16 14C16 13.7348 15.8946 13.4804 15.7071 13.2929C15.5196 13.1054 15.2652 13 15 13H13C12.7348 13 12.4804 13.1054 12.2929 13.2929C12.1054 13.4804 12 13.7348 12 14C12 14.2652 12.1054 14.5196 12.2929 14.7071C12.4804 14.8946 12.7348 15 13 15Z" />
        <path d="M19 16.3334H13C12.7348 16.3334 12.4804 16.4387 12.2929 16.6263C12.1054 16.8138 12 17.0682 12 17.3334C12 17.5986 12.1054 17.8529 12.2929 18.0405C12.4804 18.228 12.7348 18.3334 13 18.3334H19C19.2652 18.3334 19.5196 18.228 19.7071 18.0405C19.8946 17.8529 20 17.5986 20 17.3334C20 17.0682 19.8946 16.8138 19.7071 16.6263C19.5196 16.4387 19.2652 16.3334 19 16.3334Z" />
      </svg>,
      secondary: <svg width="32" height="32" viewBox="0 0 32 32" className={`text-${colorClass}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z" />
        <path d="M18.9997 8H13.6663C12.5175 8.00141 11.4161 8.45841 10.6038 9.27076C9.79142 10.0831 9.33442 11.1845 9.33301 12.3333V21C9.33389 21.7954 9.65024 22.5579 10.2127 23.1203C10.7751 23.6828 11.5376 23.9991 12.333 24H18.9997C19.9718 23.9988 20.9037 23.6121 21.591 22.9247C22.2784 22.2373 22.6651 21.3054 22.6663 20.3333V11.6667C22.6651 10.6946 22.2784 9.76267 21.591 9.07531C20.9037 8.38794 19.9718 8.00123 18.9997 8V8ZM13.333 10.034V18H12.333C11.9916 18.0033 11.6533 18.0655 11.333 18.184V12.3333C11.3346 11.7737 11.5376 11.2334 11.9048 10.8112C12.2721 10.389 12.779 10.1131 13.333 10.034ZM20.6663 20.3333C20.6663 20.7754 20.4907 21.1993 20.1782 21.5118C19.8656 21.8244 19.4417 22 18.9997 22H12.333C12.0678 22 11.8134 21.8946 11.6259 21.7071C11.4384 21.5196 11.333 21.2652 11.333 21C11.333 20.7348 11.4384 20.4804 11.6259 20.2929C11.8134 20.1054 12.0678 20 12.333 20H20.6663V20.3333ZM15.333 18V10H18.9997C19.4417 10 19.8656 10.1756 20.1782 10.4882C20.4907 10.8007 20.6663 11.2246 20.6663 11.6667V18H15.333Z" />
      </svg>,
      fundamentals: <svg width="32" height="32" viewBox="0 0 32 32" className={`text-${colorClass}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"/>
        <path d="M20.3702 16.5899H17.3402C17.2184 16.5904 17.0976 16.5667 16.9849 16.5204C16.8722 16.474 16.7698 16.4059 16.6835 16.3198C16.5972 16.2338 16.5288 16.1316 16.4821 16.019C16.4354 15.9065 16.4114 15.7858 16.4115 15.6639V12.6219C16.4124 12.2717 16.3328 11.926 16.179 11.6114C16.0251 11.2968 15.8011 11.0217 15.5242 10.8073C15.2596 10.5986 14.9507 10.4534 14.6212 10.3828C14.2917 10.3122 13.9504 10.3181 13.6235 10.3999C12.3511 10.7166 11.1856 11.3654 10.2459 12.28C9.30631 13.1945 8.62626 14.3421 8.27525 15.6055C7.92424 16.8689 7.91485 18.2028 8.24804 19.471C8.58122 20.7392 9.24505 21.8962 10.1717 22.8239C11.0984 23.7516 12.2547 24.4167 13.5225 24.7513C14.7903 25.0859 16.1243 25.078 17.388 24.7284C18.6518 24.3788 19.8001 23.7 20.7157 22.7614C21.6313 21.8228 22.2814 20.658 22.5995 19.3859C22.6821 19.0584 22.6884 18.7163 22.6179 18.3859C22.5474 18.0556 22.402 17.7458 22.1929 17.4806C21.976 17.2041 21.6994 16.9804 21.3837 16.8261C21.0681 16.6719 20.7215 16.5911 20.3702 16.5899ZM20.6589 18.8959C20.4278 19.826 19.9538 20.6779 19.2854 21.3647C18.6169 22.0514 17.7781 22.5483 16.8546 22.8045C15.9312 23.0606 14.9563 23.0669 14.0296 22.8226C13.1029 22.5784 12.2577 22.0923 11.5805 21.4142C10.9034 20.7362 10.4184 19.8903 10.1754 18.9634C9.93236 18.0364 9.93992 17.0614 10.1973 16.1383C10.4547 15.2152 10.9527 14.377 11.6403 13.7095C12.3279 13.042 13.1805 12.5691 14.1109 12.3393C14.1266 12.3353 14.1427 12.3333 14.1589 12.3333C14.2078 12.3351 14.2547 12.3532 14.2922 12.3846C14.3282 12.4129 14.3574 12.4489 14.3777 12.49C14.398 12.531 14.4089 12.5761 14.4095 12.6219V15.6626C14.4094 16.0471 14.4852 16.4279 14.6323 16.7831C14.7795 17.1384 14.9953 17.4612 15.2672 17.733C15.5392 18.0048 15.8621 18.2203 16.2175 18.3672C16.5728 18.5142 16.9537 18.5896 17.3382 18.5893H20.3682C20.4152 18.5896 20.4615 18.6006 20.5036 18.6214C20.5458 18.6421 20.5827 18.6722 20.6115 18.7093C20.6339 18.7342 20.6499 18.7643 20.6582 18.7968C20.6664 18.8293 20.6667 18.8633 20.6589 18.8959Z" />
        <path d="M23.768 13.964C23.4611 12.8356 22.8648 11.807 22.0383 10.9798C21.2117 10.1527 20.1835 9.55572 19.0553 9.248C18.8562 9.19156 18.6466 9.18195 18.4431 9.21991C18.2396 9.25787 18.0476 9.34238 17.8822 9.46683C17.7168 9.59128 17.5823 9.75231 17.4894 9.93731C17.3965 10.1223 17.3477 10.3263 17.3467 10.5333V13.6667C17.3467 14.1971 17.5574 14.7058 17.9325 15.0809C18.3075 15.4559 18.8162 15.6667 19.3467 15.6667H22.4927C22.6985 15.6647 22.901 15.6153 23.0846 15.5223C23.2682 15.4293 23.4279 15.2953 23.5512 15.1306C23.6746 14.9659 23.7583 14.7749 23.7959 14.5726C23.8335 14.3703 23.824 14.162 23.768 13.964Z" />
      </svg>
},
  integerLine = <div className="flex items-center justify-center">
      {numbers.map(num => {
    const barColor = num <= value ? colorClass : "gray-300";

    return <div key={num} className="flex items-center justify-center">
      { num !== rangeLower ? <div className={`w-10 h-2 bg-${barColor} keep-color`}/> : null }

      <div className="relative">
        <div className="absolute -top-3 right-1 text-bold text-xs">{num}</div>
        <div className={`m-1 text-${barColor} keep-color`}>&#x2759;</div>
      </div>
    </div>; })}
    </div>,
    percentLine = <div className="flex items-center w-full px-4">
        <div className={`m-1 text-${colorClass} keep-color`}>&#x2759;</div>
        <div className={`h-2 bg-${colorClass} keep-color`} style={{ width: `${value}%` }}/>
        <div className="relative">
          <div className="absolute -top-3 -right-2 text-bold text-xs">{value}%</div>
          <div className={`m-1 text-${colorClass} keep-color`}>&#x2759;</div>
        </div>
        <div className="h-2 bg-gray-300 keep-color" style={{ width: `${100 - value}%` }}/>
        <div className="m-1 text-gray-300 keep-color">&#x2759;</div>
    </div>;

  return (
    <div className={`bg-surface-secondary m-4 p-4 rounded-2xl flex flex-col justify-between ${className} print:border-2 print:border-surface-secondary`}>
      <div className="flex items-center justify-left mb-8">
        {icons[category]}
        <div className="ml-4 text-lg font-bold text-text-secondary">{label}</div>
      </div>

        {scaleType === "integer" ? integerLine : percentLine}
    </div>
  );
}
