import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { noop } from "../../../shared/utils";

interface Option<T> {
  id: T;
  label: string;
  color?: string;
  disabled: boolean;
}

interface AnalyticsMenuSelectProps<T> {
  options: Option<T>[];
  selectedValue: T;
  setSelectedValue: (value: T) => void;
  labelPrefix: string
}

export default function AnalyticsMenuSelect<T>({ options, setSelectedValue, selectedValue, labelPrefix }: AnalyticsMenuSelectProps<T>) {
  const
    [isOpen, setIsOpen] = useState<boolean>(false),
    menuRef = useRef<HTMLDivElement>(null),
    handleOptionClick = (value: T) => {
      setSelectedValue(value);
      setIsOpen(false);
    };

  useEffect(() => {
    // When menu is open, attach a document listener that will close the menu
    // if the user clicks anywhere outside of this component.
    if (isOpen) {
      const handleDocumentClick = (e: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleDocumentClick);
      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }
  }, [menuRef, isOpen]);

  return (
    <div key={labelPrefix} ref={menuRef} className="relative">
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center w-fit rounded-3xl px-4 py-2 bg-clt-collegiate-blue hover:bg-clt-medium-collegiate-blue text-white font-medium"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)} // Toggle dropdown visibility
        >
          <div className="flex items-center justify-between whitespace-nowrap">
            <div className="mr-2">{labelPrefix}</div>
            <b>{String(selectedValue).toUpperCase()}</b>
          </div>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" />
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none callout-notch"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
          <div className="py-1 w-fit" role="none">
            {options.map((option: Option<T>) => (
              <div key={String(option.id)}
                   className={`flex items-center px-4 py-2 text-sm w-full h-full text-gray-700 ${option.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:bg-gray-100"}`}
                   onClick={() => option.disabled ? noop : handleOptionClick(option.id)}>
                <input id={String(option.id)} name="test-type" type="radio"
                       className={`form-radio h-4 w-4 text-clt-collegiate-blue checked:border-none focus:border-none focus:ring-0 ${option.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                       checked={selectedValue === option.id}
                       disabled={option.disabled}
                       readOnly/>
                <label htmlFor={String(option.id)}
                       className={`flex-1 ml-3 block font-medium truncate ${option.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}>
                  {option.label}
                </label>
                {option.color
                  ? <span
                    className={`flex-shrink-0 inline-block ml-2 w-6 h-1.5 rounded ${option.color} ${option.disabled ? "opacity-50 cursor-not-allowed" : ""}`}></span>
                  : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
