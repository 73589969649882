import { emptyApi as api } from "../apiCodeGenRoot";
export const addTagTypes = [] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      managerApiGetAnalyticsIndividual: build.query<
        ManagerApiGetAnalyticsIndividualApiResponse,
        ManagerApiGetAnalyticsIndividualApiArg
      >({
        query: (queryArg) => ({
          url: `/managerapi/analytics/scores/individual`,
          params: { t: queryArg.t },
        }),
      }),
      managerApiGetAnalyticsScores: build.query<
        ManagerApiGetAnalyticsScoresApiResponse,
        ManagerApiGetAnalyticsScoresApiArg
      >({
        query: (queryArg) => ({
          url: `/managerapi/analytics/scores`,
          params: { u: queryArg.u },
        }),
      }),
      managerApiGetAccessCodes: build.query<
        ManagerApiGetAccessCodesApiResponse,
        ManagerApiGetAccessCodesApiArg
      >({
        query: () => ({ url: `/managerapi/accessCodes` }),
      }),
      managerApiGetAnalyticsAggregates: build.query<
        ManagerApiGetAnalyticsAggregatesApiResponse,
        ManagerApiGetAnalyticsAggregatesApiArg
      >({
        query: (queryArg) => ({
          url: `/managerapi/analytics/aggregates`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type ManagerApiGetAnalyticsIndividualApiResponse =
  /** status 200 A successful response returning an AnalyticsScores */ AnalyticsScores;
export type ManagerApiGetAnalyticsIndividualApiArg = {
  t: string;
};
export type ManagerApiGetAnalyticsScoresApiResponse =
  /** status 200 A successful response returning an AnalyticsScores */ AnalyticsScores;
export type ManagerApiGetAnalyticsScoresApiArg = {
  u?: string;
};
export type ManagerApiGetAccessCodesApiResponse =
  /** status 200 A successful response returning an object with accessCodes */ {
    accessCodes: AccessCode[];
  };
export type ManagerApiGetAccessCodesApiArg = void;
export type ManagerApiGetAnalyticsAggregatesApiResponse =
  /** status 200 A successful response returning an AggregateInfo */ AggregateInfo[];
export type ManagerApiGetAnalyticsAggregatesApiArg = {
  body: AggregateRequestBody[];
};
export type TestType =
  | "clt3"
  | "clt4"
  | "clt5"
  | "clt6"
  | "clt7"
  | "clt8"
  | "clt10"
  | "clt";
export type AnalyticsScore = {
  takingId: number;
  takerId: number;
  testType: TestType;
  semester: string;
  year: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  vr: number;
  qr: number;
  vrContent: number;
  vrReasoning: number;
  vrSecondary: number;
  vrFundamentals: number;
  qrContent: number;
  qrReasoning: number;
  qrSecondary: number;
  qrFundamentals: number;
  lexile: number;
  lexileReportable: string;
  lexilePercentile: number;
  quantile: number;
  quantileReportable: string;
  quantilePercentile: number;
  overallStanine: number;
  cohortYear: number;
  publicToken: string;
};
export type AnalyticsDomainScore = {
  [key: string]: {
    [key: string]: number;
  };
};
export type AnalyticsDomainInfo = {
  domains: {
    [key: string]: {
      name: string;
      classification?: "VR" | "QR";
      subdomainIds: number[];
    };
  };
  subdomains: {
    [key: string]: {
      name: string;
      pdfUrl?: string;
    };
  };
};
export type NationalPercentileMap = {
  "50th": {
    raw: number;
    reportable: string;
  };
  "90th": {
    raw: number;
    reportable: string;
  };
};
export type AnalyticsNationalPercentileScoreInfo = {
  lexile: {
    [key: string]: {
      FALL: NationalPercentileMap;
      SPRING: NationalPercentileMap;
    };
  };
  quantile: {
    [key: string]: {
      FALL: NationalPercentileMap;
      SPRING: NationalPercentileMap;
    };
  };
};
export type AnalyticsScores = {
  scores: AnalyticsScore[];
  domainScores: AnalyticsDomainScore;
  subdomainScores: AnalyticsDomainScore;
  domainInfo: AnalyticsDomainInfo;
  nationalPercentileScores: AnalyticsNationalPercentileScoreInfo;
};
export type AccessCode = {
  testType: TestType;
  accessCode: string;
  registeredCount: number;
  paidCount: number;
  year: number;
  semester: "SPRING" | "FALL";
  mode: "ONLINE" | "PAPER";
  activeStart: string;
  activeEnd: string;
};
export type AggregateInfo = {
  lexileReportable: string;
  quantileReportable: string;
  overallStanine: number;
  lexilePercentile: number;
  quantilePercentile: number;
};
export type AggregateRequestBody = {
  testType: TestType;
  semester: "SPRING" | "FALL";
  meanLexile: number;
  meanQuantile: number;
};
export const {
  useManagerApiGetAnalyticsIndividualQuery,
  useLazyManagerApiGetAnalyticsIndividualQuery,
  useManagerApiGetAnalyticsScoresQuery,
  useLazyManagerApiGetAnalyticsScoresQuery,
  useManagerApiGetAccessCodesQuery,
  useLazyManagerApiGetAccessCodesQuery,
  useManagerApiGetAnalyticsAggregatesQuery,
  useLazyManagerApiGetAnalyticsAggregatesQuery,
} = injectedRtkApi;
