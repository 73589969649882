import { Link, useLocation, useSearchParams } from "react-router-dom";

type NoAnalyticsProps = {
  text?: string;
}

export default function NoAnalytics({ text }: NoAnalyticsProps) {
  const
    location = useLocation(),
    [searchParams] = useSearchParams(),
    overrideIdUser = searchParams.get("u") ?? "",
    analyticsPage = location.pathname.match(/analytics\/(tests|cohort-growth)$/)?.[1],
    getButtonStyle = (page: string, currentPage: string) => page === currentPage
      ? "font-btn uppercase text-sm text-text-inverted shadow-md bg-clt-collegiate-blue py-2 px-3 rounded-rounded"
      : "font-btn uppercase text-sm text-text-secondary bg-clt-medium-gray py-2 px-3 rounded-rounded hover:shadow-md transition-shadow";
  
  return (
    <>
      <div className="w-full">
        <header className="top-0 sticky z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray">
          <h1 className="text-xl">Analytics</h1>
        </header>
        <main className="flex-grow p-4 overflow-auto min-h-screen">
          {analyticsPage && (
            <div className="w-full flex justify-between h-18 border-b-2 border-gray-300 items-center">
              <div className="ml-1 bg-clt-light-gray flex justify-start space-x-2 pb-4">
                <Link
                  to={`/manager/analytics/tests${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                  className={getButtonStyle("tests", analyticsPage)}
                >
                  Tests
                </Link>
                <Link
                  to={`/manager/analytics/cohort-growth${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                  className={getButtonStyle("cohort-growth", analyticsPage)}
                >
                  Cohort Growth
                </Link>
              </div>
            </div>
          )}
          <div className="m-8 xl:m-16">
            <div className="flex space-x-1 inset-x-0">
              {text && (
                <div className="inset-0 flex-1 bg-clt-white rounded-extra-rounded shadow-lg max-w-fit px-4">
                  <div className="flex flex-row p-4">
                    <h2 className="text-lg">{text}</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </>);
}
