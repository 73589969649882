export default function OnlineTestIcon() {
  return (
    <div className="bg-clt-medium-gray p-1 rounded-sm">
      <svg width="12" height="12" viewBox="0 -1 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 1H3C2.44772 1 2 1.44772 2 2V6C2 6.55228 2.44772 7 3 7H9C9.55228 7 10 6.55228 10 6V2C10 1.44772 9.55229 1 9 1ZM3 0C1.89543 0 1 0.895431 1 2V6C1 7.10457 1.89543 8 3 8H9C10.1046 8 11 7.10457 11 6V2C11 0.895431 10.1046 0 9 0H3Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 9C0.223858 9 0 9.22386 0 9.5C0 9.77614 0.223858 10 0.5 10H11.5C11.7761 10 12 9.77614 12 9.5C12 9.22386 11.7761 9 11.5 9H0.5Z" fill="currentColor"/>
      </svg>
    </div>
  );
}
