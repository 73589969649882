import { startCase, toLower } from "lodash";

/** Like `keyof`, but only the keys of T whose property type is P. */
export type KeyOfType<T, P> = keyof {
  [K in keyof T as T[K] extends P ? K : never]: unknown;
};

export const validateOrGenerateError = (e: unknown): Error => {
  if (e instanceof Error) return e;

  if (typeof e === "string") return new Error(e);

  if (e instanceof Object && "message" in e) {
    const error = e as { message: string };
      return new Error(error.message);
  }

  return new Error("Unknown Error Type:  " + JSON.stringify(e));
};

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export const noop = () => null;

export const getOrdinal = (num: number): string => {
  const lastDigit = num % 10,
   lastTwoDigits = num % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return `${num}st`;
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return `${num}nd`;
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return `${num}rd`;
  } else {
    return `${num}th`;
  }
};

export const toTitleCase = (input: string): string => startCase(toLower(input));
