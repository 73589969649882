import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useExamContext } from "../../Exam";
import { AnswerType, useStudentApiSaveAnswerMutation } from "../../services/gen/studentApi";
import { updateCachedStudentAnswer } from "../../services/studentapi.extension";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useStudentApiEndSectionMutation } from "../../services/gen/studentApi";
import { parseAndRemoveBrackets } from "../../shared/jsxUtils";
import { formulas } from "./formulas";
import { debounce } from "lodash";
import { useAppDispatch } from "../../app/hooks";

export const Show: FunctionComponent = () => {
  const {
      passage,
      question,
      advanceQuestion,
      revisitQuestion,
      activeOrNextSection,
      allQuestions,
      unansweredQuestionCount,
      hasFormulas,
      testType,
      takingId } = useExamContext(),
    [showErrorModal, setShowErrorModal] = useState(false),
    [endSection, { isSuccess: sectionEndSuccessful, isError: sectionEndError }] = useStudentApiEndSectionMutation(),
    [isFormulaPanelOpen, setIsFormulaPanelOpen] = useState(false),
    [formulaTextOpacity, setFormulaTextOpacity] = useState("opacity-100"),
    togglePanel = () => {
      setFormulaTextOpacity("opacity-0");
      setIsFormulaPanelOpen(!isFormulaPanelOpen);

      setTimeout(() => {
        setFormulaTextOpacity("opacity-100 animate-fadeIn");
      }, 100);
    };

  useEffect(() => {
    if (sectionEndSuccessful) {
      localStorage.setItem("currentQuestionIndex", "0");
      navigate("/exam/welcome", { replace: true });
    }
  }, [sectionEndSuccessful]);

  if (sectionEndError) {
    setShowErrorModal(true);
  }

  const passageGradient = "bg-gradient-to-l from-amber-50/50 via-stone-100 to-sepia-50/60",
    formulaGradient = "bg-gradient-to-l from-surface-secondary/50 via-surface-secondary to-white/60",
    dispatch = useAppDispatch(),
    [updateAnswer, {
      isError: answerSaveError
    }] = useStudentApiSaveAnswerMutation(),
    [showCompleteSectionModal, setShowCompleteSectionModal] = useState(false),
    navigate = useNavigate(),
    saveAnswerDebounced = useCallback(debounce((
      sectionNumber: number,
      questionId: number,
      answer: AnswerType,
    ) => {
      const body = { takingId, questionId, answer, sectionNumber };
      updateAnswer({ body });
    }, 200), [takingId]),
    handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!activeOrNextSection || !question) {
        return;
      }

      // Apply optimistic update to cache but debounce the actual saving. If saving
      // fails, the user will be prompted to refresh and the data will be valid and
      // in sync.
      const answer = event.target.value as AnswerType;
      dispatch(updateCachedStudentAnswer(activeOrNextSection.sectionNumber, question.id, answer));
      saveAnswerDebounced(activeOrNextSection.sectionNumber, question.id, answer);
    },
    currentAnswers: {
      [k: string]: string
    } = allQuestions.reduce((acc, question) => Object.assign({}, acc, { [question.id]: question.studentAnswer }), {});

  if (answerSaveError && !showErrorModal) {
    setShowErrorModal(true);
  }

  if (!activeOrNextSection) {
    // TODO show spinner?   This probably only happens if someone navigates straight to /exam/questions
    return null;
  }

  const leftNavArrow = revisitQuestion
      ? <div
        className="rounded-minimal border-2 border-yellow-600 group hover:border-yellow-700 border-opacity-50 h-12 w-20 flex justify-center items-center mr-4 flex-shrink-0"
        onClick={revisitQuestion}>
        <ArrowLeftIcon className="h-7 w-7 text-yellow-600 group-hover:text-yellow-700"/>
      </div>
      : <div></div>,

    rightNavArrow = advanceQuestion
      ? <div
        className="rounded-minimal border-2 border-yellow-600 group hover:border-yellow-700 border-opacity-50 h-12 w-20 flex justify-center items-center mr-4 flex-shrink-0"
        onClick={advanceQuestion}>
        <ArrowRightIcon className="h-7 w-7 text-yellow-600 group-hover:text-yellow-700"/>
      </div>
      : <div
        className="rounded-minimal border-2 border-yellow-600 group hover:border-yellow-700 border-opacity-50 h-12 flex justify-center items-center mr-4 p-2 text-lg text-yellow-600 hover:text-yellow-700 whitespace-nowrap"
        onClick={() => setShowCompleteSectionModal(true)}>
        Complete Section
      </div>;

  if (!question) {  // This is an unfortunate requirement because the Welcome screen will not have a Question when the test is complete.
    return null;
  }

  const questionJsx =
      <div className="flex flex-col w-full">
        <div className="mb-6 flex">
          <div><b>{question.seq}</b>.&nbsp;&nbsp;</div>
          <div dangerouslySetInnerHTML={{ __html: question.stem }} style={{ display: "inline-block" }} />
        </div>
        <fieldset id={`fieldset-${question.id}`}>
          {question.answerChoices.map((answerChoice, i) => {
              const id = `${question.id}`,
                currentAnswer = currentAnswers[id] ?? question.studentAnswer ?? undefined;

              return <div className="my-6 mx-12 flex items-center" key={`answerChoice-${i}`}>
                <input
                  type="radio"
                  className="text-black checked:border-none focus:ring-0 focus:ring-offset-0 mr-4"
                  id={`${id}-${i}`}
                  checked={currentAnswer === answerChoice.answerLetter}
                  onChange={handleAnswerChange}
                  value={answerChoice.answerLetter}
                />
                <label htmlFor={`${id}-${i}`}>
                  <span dangerouslySetInnerHTML={{ __html: answerChoice.text }}/>
                </label>
              </div>;
            }
          )
          }
        </fieldset>
      </div>,
    questionOnlyJsx = <div className="w-full h-full flex">
      <div
        className={`h-full ${isFormulaPanelOpen ? "w-1/2" : "w-0"} bg-surface-secondary transition-width duration-300 ease-in-out ${formulaGradient} overflow-y-auto`}
      >
        <div className={`overflow-y-auto ${formulaTextOpacity} mt-20 mb-32 ml-48 flex `}>
          <div className="flex-1 flex-shrink"></div>
          <div className="flex-2 flex-shrink-0 max-w-[30rem] text-xl">
            <h3 className="text-3xl bold mb-12 ml-6 ">Formulas</h3>
            {
              ["clt7", "clt8"].includes(testType)
                ? formulas.clt7
                : formulas.clt3
            }
          </div>
          <div className="flex-1 flex-shrink"></div>
        </div>
      </div>

      <div
        className={`h-full ${isFormulaPanelOpen ? "w-1/2" : "w-full"} max-w-[80rem] max-h-[60rem] flex flex-col mx-auto items-center py-16 overflow-y-auto`}>

        <div className={`${passage ? "w-2/3" : "w-1/2"} grow pb-8`}>{questionJsx}</div>
        <div className={`${passage ? "w-2/3" : "w-1/2"} flex justify-between flex-row pb-24`}>
          {leftNavArrow}
          {rightNavArrow}
        </div>
      </div>
    </div>,
    passageAndQuestionJsx = <>
      <div
        className={`w-1/2 pt-16 px-20 pb-48 border-r border-gray-500 bg-zinc-50 overflow-y-auto ${passageGradient}`}>
        {passage?.paragraphs.map((paragraph: string, i: number) => {
          const parsedParagraph = parseAndRemoveBrackets(paragraph);

          return <div key={`paragraph-${i}`} className="relative">
          { parsedParagraph.number
              ? <span className="absolute -left-8 top-0 text-gray-500 text-med"><b>{parsedParagraph.number}</b></span>
              : null }
            <span dangerouslySetInnerHTML={{ __html: parsedParagraph.cleanedString }} />
          </div>;
          }
        )}
      </div>

      <div className="w-1/2 h-full">
        {questionOnlyJsx}
      </div>
    </>;

  return <div className={`flex text-2xl h-full font-exam w-full select-text min-w-[60rem] ${hasFormulas ? "flex-col" : ""}`}>
    {passage ? passageAndQuestionJsx : questionOnlyJsx}

    {hasFormulas
      ? <button
        className={`fixed bottom-0 left-0 py-2 px-4 rounded-md shadow-sm text-sm font-medium group border border-transparent hover:border-black mb-12 ml-8 uppercase ${
          isFormulaPanelOpen 
            ? "bg-surface-tertiary text-text-secondary" : 
            "bg-cyan-900 text-white"
        }`}
        onClick={() => togglePanel()}
      >
        <div className="flex items-center justify-around font-bold">
          {
            isFormulaPanelOpen
              ? <><ArrowLeftIcon className="w-8 h-8 mr-2"/><p className="mr-4">Hide</p></>
              : <><p>Show Formulas</p><ArrowRightIcon className="w-8 h-8 ml-2"/></>
          }
        </div>
      </button>
      : null}

    {showErrorModal ?
      <Modal heading="Something went wrong!" subheading="Answer did not save. Click OK to refresh and try again."
             modalType="ERROR" setShowModal={setShowErrorModal} onOK={() => {
        navigate(0);
      }}/> : null}
    {showCompleteSectionModal ?
      <Modal heading={`Complete Section ${activeOrNextSection.sectionNumber}`}
             subheading={
               unansweredQuestionCount > 0
                 ? "Some questions have not been answered!  Please go back and answer them.  Once you proceed, you will not be able to return or change your answers."
                 : "You have finished this section. Once you proceed, you will not be able to return or change your answers."
             }
             modalType="WARN"
             setShowModal={setShowCompleteSectionModal}
             okButtonText="Proceed"
             showCancel={true}
             cancelButtonText="Go back"
             customFont="font-exam"
             okButtonClass="bg-cyan-900 text-white"
             onOK={() => {
               endSection({ sectionNumber: activeOrNextSection.sectionNumber });
             }}/>
      : null}
  </div>;
};
