import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";


type HelpModalProps = {
  showHelpModal: boolean;
  setShowHelpModal: (show: boolean) => void;
  title: React.ReactNode;
  content: React.ReactNode;
}

export default function HelpModal({ showHelpModal, setShowHelpModal, title, content }: HelpModalProps) {
  const
    [scrolled, setScrolled] = useState(false),
    contentRef = useRef<HTMLDivElement>(null);

  return <div className="flex items-center justify-center space-x-3">
    <Transition show={showHelpModal} as={Fragment}>
      <Dialog
        className="relative z-[60]"
        onClose={() => setShowHelpModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Backdrop className="fixed z-[60] inset-0 bg-clt-medium-gray/75"/>
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="flex flex-col w-5/12 rounded-extra-rounded bg-clt-white shadow-lg transform max-h-[calc(100vh-2rem)] overflow-hidden">
              <div className={`p-4 flex flex-row items-center justify-between bg-clt-white transition-shadow ${scrolled ? "shadow-md" : ""}`}>
                <div className="text-lg font-bold text-text-primary">
                  {title}
                </div>

                <button
                  className="rounded-full ml-2 text-text-secondary hover:cursor-pointer hover:text-text-primary focus:outline-none"
                  onClick={() => setShowHelpModal(false)}
                >
                  <XCircleIcon className="w-8 h-8"/>
                </button>
              </div>
              <div
                ref={contentRef}
                className="px-4 mb-3 text-xl overflow-y-scroll"
                onScroll={() => contentRef.current && setScrolled(contentRef.current.scrollTop > 8)}
              >
                {content}
              </div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  </div>;
}
