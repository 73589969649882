import {
  type AnalyticsDomainScore,
  type AnalyticsScore,
  type TestType,
  useLazyManagerApiGetAnalyticsAggregatesQuery,
  useManagerApiGetAnalyticsIndividualQuery,
  useManagerApiGetAnalyticsScoresQuery
} from "../../../services/gen/managerApi";
import AnalyticsMenuSelect from "./AnalyticsMenuSelect";
import NoAnalytics from "./NoAnalytics";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { useEffect, useMemo, useState } from "react";
import { mapValues, uniq } from "lodash";
import { TEST_TYPES } from "../../../shared/constants";
import { getOrdinal, toTitleCase } from "../../../shared/utils";
import AnalyticsCard from "./AnalyticsCard";
import TakingsScoresPanel from "./Tests/TakingsScoresPanel";
import RadialProgress from "./Tests/RadialProgress";
import CategoryScale from "./Tests/CategoryScale";
import DomainCard from "./Tests/DomainCard";
import DomainAccordion from "./Tests/DomainAccordion";
import BarGraph from "./Tests/BarGraph";
import { createAcronym } from "../../../shared/jsxUtils";
import { Link, useSearchParams } from "react-router-dom";
import MetametricsMeasureReport from "./Tests/MetametricsMeasureReport";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { TestTypePill } from "../../../components/TestTypePill";

export interface ScoreDisplay {
  // Row 1 Division 1
  vr: number;
  qr: number;
  stanine: number;
  composite: number;

  // Row 1 Division 2
  lexile: number;
  lexilePercentile: number;

  // Row 1 Division 3
  quantile: number;
  quantilePercentile: number;

  // Row 2 Division 1
  vrContent: number;
  vrReasoning: number;
  vrSecondary: number;
  vrFundamentals: number;

  // Row 2 Division 2
  qrContent: number;
  qrReasoning: number;
  qrSecondary: number;
  qrFundamentals: number;

  domains: { [key: string]: number };
  subdomains: { [key: string]: number };

  topDomains: string[];
  bottomDomains: string[];
}

function sortKeysByValues(obj: Record<number, number>): number[] {
  const entries = Object.entries(obj);
  entries.sort((a, b) => b[1] - a[1]);
  return entries.map(entry => parseInt(entry[0]));
}

function calculateAverages(scores: AnalyticsScore[], domainScores: AnalyticsDomainScore, subdomainScores: AnalyticsDomainScore): ScoreDisplay {
  const
    // I need just the domain IDs for the scores selected.  Can I trust if one has it, the rest will have the _same_ ?
    // For now, yes.  Because this app has to launch in a couple days and I have lots more to do.
    domainIds = scores.length ? Object.keys(domainScores[scores[0]["takingId"]]) : [],
    subdomainIds = scores.length ? Object.keys(subdomainScores[scores[0]["takingId"]]) : [],
    domainsInit = domainIds.reduce((obj, domainId) => {
      obj[domainId] = 0;
      return obj;
    }, {} as Record<string, number>),
    subdomainsInit = subdomainIds.reduce((obj, subdomainId) => {
      obj[subdomainId] = 0;
      return obj;
    }, {} as Record<string, number>),

  total = scores.reduce((acc, score) => {
    acc.vr += score.vr;
    acc.qr += score.qr;
    acc.overallStanine += score.overallStanine;
    acc.composite += score.vr + score.qr;
    acc.vrContent += score.vrContent;
    acc.vrReasoning += score.vrReasoning;
    acc.vrSecondary += score.vrSecondary;
    acc.vrFundamentals += score.vrFundamentals;
    acc.qrContent += score.qrContent;
    acc.qrReasoning += score.qrReasoning;
    acc.qrSecondary += score.qrSecondary;
    acc.qrFundamentals += score.qrFundamentals;
    acc.lexile += score.lexile;
    acc.lexilePercentile += score.lexilePercentile;
    acc.quantile += score.quantile;
    acc.quantilePercentile += score.quantilePercentile;

    domainIds.forEach((domainId) => {
      acc.domains[domainId] += Number(domainScores[score["takingId"]][domainId]) || 0;
    });

    subdomainIds.forEach((subdomainId) => {
      acc.subdomains[subdomainId] += Number(subdomainScores[score["takingId"]][subdomainId]) || 0;
    });

    return acc;
  }, { vr: 0, qr: 0, overallStanine: 0, composite: 0,
       vrContent: 0, vrReasoning: 0, vrSecondary: 0, vrFundamentals: 0,
       qrContent: 0, qrReasoning: 0, qrSecondary: 0, qrFundamentals: 0,
       lexile: 0, lexilePercentile: 0, quantile: 0, quantilePercentile: 0,
       domains: domainsInit, subdomains: subdomainsInit
  }),
   length = scores.length || 1,
   domainsAvg = mapValues(total.domains, (n => Math.round(n / length))),
   topDomains = sortKeysByValues(total.domains).slice(0, 3).map(String),
   bottomDomains = sortKeysByValues(total.domains).slice(-3).reverse().map(String);

  return {
    vr: Math.round(total.vr / length),
    qr: Math.round(total.qr / length),
    stanine: Math.round(total.overallStanine / length),
    composite: Math.round(total.composite / length),
    vrContent: Math.round(total.vrContent / length),
    vrReasoning: Math.round(total.vrReasoning / length),
    vrSecondary: Math.round(total.vrSecondary / length),
    vrFundamentals: Math.round(total.vrFundamentals / length),
    qrContent: Math.round(total.qrContent / length),
    qrReasoning: Math.round(total.qrReasoning / length),
    qrSecondary: Math.round(total.qrSecondary / length),
    qrFundamentals: Math.round(total.qrFundamentals / length),
    lexile: Math.round(total.lexile / length),
    lexilePercentile: Math.round(total.lexilePercentile / length),
    quantile: Math.round(total.quantile / length),
    quantilePercentile: Math.round(total.quantilePercentile / length),
    domains: domainsAvg,
    subdomains: mapValues(total.subdomains, (n => Math.round(n / length))),
    topDomains,
    bottomDomains
  };
}

const helpForTestType = (testType: TestType) => ({
  "vr": {
    title: "Verbal Reasoning",
    content: (
      <div className="space-y-2">
        <div>Sections are scored on a scale of 150-300.</div>
        {testType === "clt8" && (
          <div className="text-sm">
            To compare this score to a CLT8 score obtained prior to April 2024, please consult our scoring comparison <Link target="_blank" rel="noreferrer" className="text-clt-primary-teal" to="https://info.cltexam.com/hubfs/CLT3-8/clt8_conversion_tables.pdf">here</Link>.
          </div>
        )}
      </div>
    ),
  },
  "qr": {
    title: "Quantitative Reasoning",
    content: (
      <div className="space-y-2">
        <div>Sections are scored on a scale of 150-300.</div>
        {testType === "clt8" && (
          <div className="text-sm">
            To compare this score to a CLT8 score obtained prior to April 2024, please consult our scoring comparison <Link target="_blank" rel="noreferrer" className="text-clt-primary-teal" to="https://info.cltexam.com/hubfs/CLT3-8/clt8_conversion_tables.pdf">here</Link>.
          </div>
        )}
      </div>
    ),
  },
  "composite_stanine": {
    title: "Composite Stanine",
    content: "Stanines are calculated from a student's Lexile and Quantile measures compared to a national norm. The stanine scale ranges from 1 to 9. Stanines 1-3 are below average, stanines 4-6 are average, and stanines 7-9 are above average."
  },
  "lexile": {
    title: <div>Lexile&#174; Measure</div>,
    content: <div>The Lexile framework evaluates reading ability and text complexity on the same developmental scale. View the CLT book list for each grade and Lexile range <Link target="_blank" rel="noreferrer" className="text-clt-primary-teal" to="https://info.cltexam.com/hubfs/Sales/CLT3-6/Lexile%20Book%20List%20for%20Assessments%20for%20Grades%203-6.pdf">here.</Link>  More information is available at <Link className="text-clt-primary-teal" to="https://lexile.com/" rel="noreferrer">Lexile.com.</Link></div>
  },
  "quantile": {
    title: <div>Quantile&#174; Measure</div>,
    content: <div>The Quantile framework evaluates mathematics ability and the difficulty of specific skills and concepts on the same developmental scale.  More information, including details about which math concepts correspond to which Quantile range, is available at <Link className="text-clt-primary-teal" to="https://quantiles.com/" rel="noreferrer">Quantiles.com.</Link></div>
  },
  "content_etc": {
    title: "Content, Reasoning, Fundamentals, and Secondary",
    content: <div className="flex flex-col space-y-4">
      <div><b>Content:</b> These questions encompass the basic skills and knowledge that students need to master in order to be successful in their education and future careers. They include skills such as reading, writing, mathematics, and oral communication.</div>
      <div><b>Reasoning:</b> These questions are focused on higher-level thinking skills that enable students to analyze, evaluate, and apply information. They include critical thinking, problem-solving, and logical reasoning. Additionally, this skill can measure a student’s potential to learn beyond material already taught and absorbed.</div>
      <div><b>Fundamentals:</b> These questions encompass basic skills that all students should master in order to be successful in the next grade level. They are building blocks for more advanced learning, and include skills within reading, grammar, and mathematics.</div>
      <div><b>Secondary:</b> These questions are more advanced, building upon the foundation of fundamental skills. They are important for success in specific subject areas, but will be addressed again as students move up to next grade level.</div>
      <div>These scores are based on the student&apos;s performance in the respective areas.</div>
    </div>
  },
});

type TestsProps = {
  isSingleReport: boolean;
}

export default function Tests({ isSingleReport = false }: TestsProps) {
  const [searchParams, setSearchParams] = useSearchParams(),
    [getAggregates, { data: [aggregatesData] = [], isLoading: isAggregatesLoading }] = useLazyManagerApiGetAnalyticsAggregatesQuery(),
    token = searchParams.get("t") ?? "",
    overrideIdUser = searchParams.get("u") ?? "",
    { data: groupData, isSuccess: isGroupSuccess, isLoading: isGroupLoading } = useManagerApiGetAnalyticsScoresQuery(overrideIdUser ? { u: overrideIdUser } : {}, { skip: isSingleReport }),
    { data: singleData, isSuccess: isSingleReportSuccess, isLoading: isSingleReportLoading, isError: isSingleReportError } = useManagerApiGetAnalyticsIndividualQuery({ t: token }, { skip: !isSingleReport }),
    scores = isSingleReport ? singleData?.scores ?? [] : groupData?.scores ?? [],
    domainScores = isSingleReport ? singleData?.domainScores ?? {} : groupData?.domainScores ?? {},
    subdomainScores = isSingleReport ? singleData?.subdomainScores ?? {} : groupData?.subdomainScores ?? {},
    domainInfo = isSingleReport ? singleData?.domainInfo ?? { domains: {}, subdomains: {} } : groupData?.domainInfo ?? { domains: {}, subdomains: {} },

    [selectedTestType, setSelectedTestType] = useState<TestType>("" as TestType),
    [selectedSemester, setSelectedSemester] = useState<string>(""),
    [selectedScores, setSelectedScores] = useState<AnalyticsScore[] | null>(null),

    help = useMemo(() => helpForTestType(selectedTestType), [selectedTestType]),

    scoreDeps = useMemo(() => {
      if ((isGroupLoading && !isGroupSuccess) || scores.length === 0) return null;

      const validTestTypes = Array.from(new Set(scores.map(score => score.testType)));

      return {
        defaultTestType: scores[0].testType,
        defaultSemester: scores[0].semester + " " + scores[0].year,
        testTypeSelectOptions: TEST_TYPES.map((testType) => ({
          id: testType,
          label: testType.toUpperCase(),
          color: `bg-${testType}`,
          disabled: !validTestTypes.includes(testType as TestType)
        })),
      };
    }, [scores]);

  useEffect(() => {
    if (overrideIdUser) {
      setSearchParams({ u: overrideIdUser });
    }
  }, []);

  useEffect(() => {
    scoreDeps && setSelectedTestType(scoreDeps.defaultTestType);
    scoreDeps && setSelectedSemester(scoreDeps.defaultSemester);
  }, [scoreDeps]);

  useEffect(() => {
    const validSemestersForSelectedTestType = uniq(scores.filter(score => score.testType === selectedTestType).map(score => score.semester + " " + score.year)),
      isSelectedSemesterValid = validSemestersForSelectedTestType.includes(selectedSemester);

    if (!isSelectedSemesterValid) {
      setSelectedSemester(validSemestersForSelectedTestType[0]);
    }
  }, [selectedTestType]);

  const stateDeps = useMemo(() => {
      if (!selectedTestType || !selectedSemester) return null;

      const selectedSemesterSeason = selectedSemester.split(" ")[0],
        selectedSemesterYear = selectedSemester.split(" ")[1],
        testAdministrations = uniq(scores.filter(score => score.testType === selectedTestType).map(score => score.semester + " " + score.year)),
        filteredScores = scores.filter(score => score.testType === selectedTestType && score.semester === selectedSemesterSeason && String(score.year) === selectedSemesterYear);

      setSelectedScores(filteredScores);

      return {
        filteredScores,
        semesterSelectOptions: testAdministrations.map((adm) => ({
          id: adm,
          label: toTitleCase(adm),
          disabled: false
        })),
      };
    }, [selectedTestType, selectedSemester]),

    displayScores: ScoreDisplay | null = useMemo(() => {
      if (!selectedScores) { return null; }

      return calculateAverages(selectedScores, domainScores, subdomainScores);
    }, [selectedScores]),

   displayAggregates = isSingleReport
    ? {
      "lexileReportable": scores[0]?.lexileReportable || "0",
      "quantileReportable": scores[0]?.quantileReportable || "0",
      "overallStanine": scores[0]?.overallStanine || 0,
      "lexilePercentile": scores[0]?.lexilePercentile || 0,
      "quantilePercentile": scores[0]?.quantilePercentile || 0
     }
     : aggregatesData;

  useEffect(() => {
    !isSingleReport && displayScores && getAggregates({ body: [{
      testType: selectedTestType,
      semester: selectedSemester.split(" ")[0] as "SPRING" | "FALL",
      meanLexile: displayScores.lexile,
      meanQuantile: displayScores.quantile,
    }] });

  }, [displayScores]);

  if ((isGroupSuccess || isSingleReportSuccess) && scores.length === 0) {
    return <NoAnalytics text="Analytics will be available here once your scores have been released!"/>;
  }

  if (isSingleReportError) {
    return <NoAnalytics text="This link has expired or is invalid."/>;
  }

  if ((isGroupLoading || isSingleReportLoading || isAggregatesLoading)) {
    return <LoadingOverlay/>;
  }

  if (!scoreDeps || !stateDeps || !displayScores || !displayAggregates) {
    return <NoAnalytics />;
  }

  return (
    <div className="relative min-h-screen w-full flex flex-col flex-grow-0 min-w-fit bg-surface-secondary print:[zoom:75%]">
        <div>
          <header className="z-20 w-full font-serif p-7 bg-clt-white shadow-md shadow-clt-medium-gray print:pb-0 print:shadow-none">
            <div className="flex items-center justify-between">
              <h1 className="text-xl">
                {isSingleReport
                  ? <div className="ml-4">
                      <div className="mr-8 text-3xl">{scores[0].firstName} {scores[0].lastName}</div>
                      <div>{selectedTestType.toUpperCase()}, {selectedSemester}</div>
                    </div>
                  : "Analytics"}
              </h1>
              {isSingleReport && (
                <button
                  className="text-clt-collegiate-blue p-2 bg-clt-light-gray rounded-minimal hover:bg-clt-medium-gray print:hidden"
                  title="Print Report"
                  onClick={() => window.print()}
                >
                  <PrinterIcon strokeWidth={2} className="w-6 h-6" />
                </button>
              )}
            </div>
          </header>

          <main className={`flex-grow p-4 overflow-auto ${isSingleReport ? "px-24" : ""}`}>

            { isSingleReport ? null :
              <div className="w-full flex justify-between h-18 border-b-2 border-gray-300 items-center">
                <div className="ml-1 bg-clt-light-gray flex justify-start space-x-2 pb-4">
                  <Link
                    to={`/manager/analytics/tests${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                    className="font-btn uppercase text-sm text-text-inverted shadow-md bg-clt-collegiate-blue py-2 px-3 rounded-rounded"
                  >
                    Tests
                  </Link>
                  <Link
                    to={`/manager/analytics/cohort-growth${overrideIdUser ? `?u=${overrideIdUser}` : ""}`}
                    className="font-btn uppercase text-sm text-text-secondary bg-clt-medium-gray py-2 px-3 rounded-rounded hover:shadow-md transition-shadow"
                  >
                    Cohort Growth
                  </Link>
                </div>
                <div className="bg-clt-light-gray flex justify-end space-x-2 z-30 pb-4">
                  <AnalyticsMenuSelect<TestType> options={scoreDeps.testTypeSelectOptions}
                                                 selectedValue={selectedTestType}
                                                 setSelectedValue={setSelectedTestType} labelPrefix="Test Type:"/>
                  <AnalyticsMenuSelect<string> options={stateDeps.semesterSelectOptions} selectedValue={selectedSemester}
                                               setSelectedValue={setSelectedSemester} labelPrefix="Test Date:"/>
                </div>
              </div> }

            { isSingleReport ? null
              : <div className="flex justify-between mx-16 my-8 whitespace-nowrap text-text-secondary">
                  <div className="text-2xl font-serif">Tests</div>
                  <div className="flex items-center gap-4 text-xl font-sans">
                    <span>{toTitleCase(selectedSemester)}</span>
                    <TestTypePill testType={selectedTestType} textSizeClassName="text-base" />
                  </div>
                </div> }

            {/*// ROW 1 with 3 divisions*/}
            <div className="flex justify-evenly items-center sm:grid sm:grid-cols-2 min-[1400px]:grid-cols-3 gap-4 mb-4">
              {/*// ROW 1 First Division*/}
              <div className="flex flex-wrap flex-1 sm:grid sm:grid-cols-4 sm:col-span-2 min-[1400px]:grid-cols-2 min-[1400px]:col-span-1 gap-4">
                <AnalyticsCard className="flex-1" help={help.vr}>
                  <RadialProgress value={displayScores.vr} rangeLower={150} rangeUpper={300}
                                  colorClass="clt-primary-teal" label="Verbal Reasoning"/>
                </AnalyticsCard>
                <AnalyticsCard className="flex-1" help={help.qr}>
                  <RadialProgress value={displayScores.qr} rangeLower={150} rangeUpper={300}
                                  colorClass="clt-primary-orange" label="Quantitative Reasoning"/>
                </AnalyticsCard>

                <AnalyticsCard className="flex-1">
                  <RadialProgress value={displayScores.composite} rangeLower={300} rangeUpper={600}
                                  colorClass="clt-collegiate-blue" label="Composite (VR+QR)"/>
                </AnalyticsCard>
                <AnalyticsCard className="flex-1" help={help.composite_stanine}>
                  <RadialProgress value={displayAggregates.overallStanine} rangeLower={1} rangeUpper={9}
                                  colorClass="clt-collegiate-blue" label="Composite Stanine Score"/>
                </AnalyticsCard>
              </div>

              {/*// ROW 1 Second Division*/}
              <div className="flex justify-between items-center flex-1">
                <AnalyticsCard className="py-10 h-full" help={help.lexile}>
                  <div className="flex flex-col space-y-8">
                    <div>
                      <div
                        className="text-lg font-semibold text-text-primary text-center w-full pb-2 whitespace-nowrap">
                        <i>Lexile</i>&#174; Percentile
                      </div>
                      <RadialProgress value={displayAggregates.lexilePercentile}
                                      displayValue={getOrdinal(displayAggregates.lexilePercentile)}
                                      rangeLower={1} rangeUpper={99} colorClass="clt-primary-teal"/>
                    </div>
                    <MetametricsMeasureReport measure="Lexile" reportable={displayAggregates.lexileReportable} />
                  </div>
                </AnalyticsCard>
              </div>

              {/*// ROW 1 Third Division*/}
              <div className="flex justify-between items-center flex-1">
                <AnalyticsCard className="py-10" help={help.quantile}>
                  <div className="flex flex-col space-y-8">
                    <div>
                      <div
                        className="text-lg font-semibold text-text-primary text-center w-full pb-2 whitespace-nowrap">
                        <i>Quantile</i>&#174; Percentile
                      </div>
                      <RadialProgress value={displayAggregates.quantilePercentile}
                                      displayValue={getOrdinal(displayAggregates.quantilePercentile)}
                                      rangeLower={1} rangeUpper={99} colorClass="clt-primary-orange"/>
                    </div>
                    <MetametricsMeasureReport measure="Quantile" reportable={displayAggregates.quantileReportable} />
                  </div>
                </AnalyticsCard>
              </div>
            </div>

            {/*// ROW 2 with 2 divisions*/}
            <div className="flex justify-between items-center w-full h-full flex-1 space-x-4 mt-4">
              <AnalyticsCard title="Verbal Reasoning" className="flex-1 flex flex-wrap  max-w-fit justify-center" help={help.content_etc}>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Content"
                               value={displayScores.vrContent} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-teal"
                               category="content"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Reasoning"
                               value={displayScores.vrReasoning} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-teal" category="reasoning"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Fundamentals"
                               value={displayScores.vrFundamentals} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-teal" category="fundamentals" scaleType="percent"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Secondary"
                               value={displayScores.vrSecondary} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-teal" category="secondary" scaleType="percent"/>
              </AnalyticsCard>

              <AnalyticsCard title="Quantitative Reasoning" className="flex-1 flex flex-wrap max-w-fit justify-center" help={help.content_etc}>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Content"
                               value={displayScores.qrContent} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-orange" category="content"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Reasoning"
                               value={displayScores.qrReasoning} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-orange" category="reasoning"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Fundamentals"
                               value={displayScores.qrFundamentals} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-orange" category="fundamentals" scaleType="percent"/>
                <CategoryScale className="flex-1 m-2 min-w-[18rem] max-w-[25rem] print:m-1" label="Secondary"
                               value={displayScores.qrSecondary} rangeLower={1} rangeUpper={5}
                               colorClass="clt-primary-orange" category="secondary" scaleType="percent"/>
              </AnalyticsCard>
            </div>
            {/*// ROW 3 with 2 divisions*/}
            <div className="flex items-center w-full h-full space-x-4 mt-4">
              <AnalyticsCard title="Improvement Domains" className="flex-1 flex flex-col w-full justify-center">
                { displayScores.bottomDomains.map(domainId => (
                  <DomainCard key={`domain-${domainId}`} name={domainInfo.domains[domainId].name}
                              percentCorrect={displayScores.domains[domainId]}
                              category={domainInfo.domains[domainId].classification ?? "QR"} isTopDomains={false}
                              className="mt-4"
                  />)) }
              </AnalyticsCard>

              <AnalyticsCard title="Top Domains" className="flex-1 flex flex-col flex-wrap w-full justify-center">
                { displayScores.topDomains.map(domainId => (
                  <DomainCard key={`domain-${domainId}`} name={domainInfo.domains[domainId].name}
                              percentCorrect={displayScores.domains[domainId]}
                              category={domainInfo.domains[domainId].classification ?? "QR"} isTopDomains={true}
                              className="mt-4"
                  />)) }
              </AnalyticsCard>
            </div>


            {/*// ROW 4 with 2 divisions*/}
            <div className="flex w-full h-full space-x-4 mt-4">
              <AnalyticsCard title="Percentage Correct By Domain" className="flex-1 flex flex-col w-full px-0">
                <BarGraph data={Object.keys(displayScores.domains).map(k => ({
                  name: createAcronym(domainInfo.domains[k].name),
                  longName: domainInfo.domains[k].name,
                  percentCorrect: displayScores.domains[k],
                  classification: domainInfo.domains[k].classification ?? "QR"
                }))}/>
              </AnalyticsCard>
              <AnalyticsCard title="Percentage Correct By Domain and Subdomain"
                             className="flex-1 flex flex-col w-full px-0 print:whitespace-normal">
                {
                  Object.keys(displayScores.domains).map((domainId, i) => {

                    const subdomainsIds = domainInfo.domains[domainId].subdomainIds,
                      subdomainInfo = subdomainsIds.map((subdomainId) => ({
                        name: domainInfo.subdomains[subdomainId].name,
                        pdfUrl: domainInfo.subdomains[subdomainId].pdfUrl,
                        percentCorrect: displayScores.subdomains[subdomainId]
                      })),
                      validSubdomainInfo = subdomainInfo.filter(subdomain => subdomain.percentCorrect >= 0 && subdomain.percentCorrect <= 100);

                    return <DomainAccordion key={`domain-${domainId}`} category={domainInfo.domains[domainId].classification ?? "QR"}
                                            name={domainInfo.domains[domainId].name}
                                            percentCorrect={displayScores.domains[domainId]}
                                            subdomains={validSubdomainInfo}
                                            className={i === 0 ? "mt-4" : ""}
                    />;
                  })
                }
                <div className="h-8 min-h-8">&nbsp;</div>
              </AnalyticsCard>
            </div>


            <div className="h-128 print:hidden">&nbsp;</div>
          </main>
        </div>

      { isSingleReport ? null
        : <footer className="fixed ml-52 inset-x-0 bottom-0 bg-white border-t p-4 h-24">
          <TakingsScoresPanel scores={stateDeps.filteredScores} setter={setSelectedScores}
                            testtype_semester={`${selectedTestType}-${selectedSemester}`}/>
          </footer> }
    </div>
  );
}
